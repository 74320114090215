import React from "react";
import { ObjAction, ObjType } from "@shared/types";
import {
	RoomFilter,
	InstallationFilter,
	HeatingFilter,
	LodgingTypesFilter,
	LandPurposeFilter,
	GarageTypeFilter,
	BuildingTypeFilter,
	PricePerMFilter,
	FloorFilter,
	YearFilter,
	OutsideSpaceFilter,
	PricePerAreFilter,
	LandFeaturesFilter,
	GarageFeaturesFilter,
} from "./Filters";

interface P {
	type: ObjType | "" | undefined;
	action: ObjAction | "" | undefined;
}
export const SpecificFilters: React.FC<P> = (p) => {
	switch (p.type) {
		case ObjType.FLAT: {
			switch (p.action) {
				case "":
				case undefined:
				case ObjAction.SELL: {
					return (
						<>
							<RoomFilter />
							<InstallationFilter />
						</>
					);
				}
				case ObjAction.RENT: {
					return (
						<>
							<RoomFilter />
							<HeatingFilter />
						</>
					);
				}
			}
			break;
		}
		// falls through
		case ObjType.HOMESTEAD:
		case ObjType.GARDEN_HOUSE:
		case ObjType.HOUSE: {
			switch (p.action) {
				case "":
				case undefined:
				case ObjAction.SELL: {
					return (
						<>
							{/* <HouseTypeFilter /> */}
							<InstallationFilter />
						</>
					);
				}
				case ObjAction.RENT: {
					return (
						<>
							{/* <HouseTypeFilter /> */}
							<HeatingFilter />
						</>
					);
				}
			}
			break;
		}
		case ObjType.GARAGE: {
			switch (p.action) {
				case ObjAction.RENT:
				case "":
				case undefined:
				case ObjAction.SELL: {
					return (
						<>
							<GarageTypeFilter />
						</>
					);
				}
			}
			break;
		}
		case ObjType.LAND: {
			switch (p.action) {
				case ObjAction.RENT:
				case "":
				case undefined:
				case ObjAction.SELL: {
					return (
						<>
							<LandPurposeFilter />
						</>
					);
				}
			}
			break;
		}
		case ObjType.LODGING: {
			switch (p.action) {
				case ObjAction.RENT:
				case "":
				case undefined:
				case ObjAction.SELL: {
					return (
						<>
							<LodgingTypesFilter />
						</>
					);
				}
			}
			break;
		}
		case "":
		case undefined: {
			return <OutsideSpaceFilter />;
		}
		default: {
			throw new Error(`Invalid ObjType: ${p.type}!`);
		}
	}
};

export const ExtraFilters: React.FC<P> = (p) => {
	switch (p.type) {
		case ObjType.FLAT: {
			switch (p.action) {
				case "":
				case undefined:
				case ObjAction.SELL: {
					return (
						<>
							<FloorFilter />
							<YearFilter />
							<HeatingFilter />
							<BuildingTypeFilter />
							<PricePerMFilter />
						</>
					);
				}
				case ObjAction.RENT: {
					return (
						<>
							<FloorFilter />
							<YearFilter />
							<InstallationFilter />
							<BuildingTypeFilter />
							<PricePerMFilter />
						</>
					);
				}
			}
			break;
		}
		case ObjType.HOMESTEAD:
		case ObjType.GARDEN_HOUSE:
		case ObjType.HOUSE: {
			switch (p.action) {
				case "":
				case undefined:
				case ObjAction.SELL: {
					return (
						<>
							<OutsideSpaceFilter />
							<YearFilter />
							<HeatingFilter />
							<BuildingTypeFilter />
							<PricePerMFilter />
						</>
					);
				}
				case ObjAction.RENT: {
					return (
						<>
							<InstallationFilter />
							<YearFilter />
							<BuildingTypeFilter />
							<PricePerMFilter />
						</>
					);
				}
			}
			break;
		}
		case ObjType.GARAGE: {
			switch (p.action) {
				case ObjAction.RENT:
				case "":
				case undefined:
				case ObjAction.SELL: {
					return (
						<>
							<PricePerMFilter />
							<GarageFeaturesFilter />
						</>
					);
				}
			}
			break;
		}
		case ObjType.LAND: {
			switch (p.action) {
				case ObjAction.RENT:
				case "":
				case undefined:
				case ObjAction.SELL: {
					return (
						<>
							<PricePerAreFilter />
							<LandFeaturesFilter />
						</>
					);
				}
			}
			break;
		}
		case ObjType.LODGING: {
			switch (p.action) {
				case ObjAction.RENT:
				case "":
				case undefined:
				case ObjAction.SELL: {
					return (
						<>
							<FloorFilter />
							<PricePerMFilter />
						</>
					);
				}
			}
			break;
		}
		case "":
		case undefined: {
			return null;
		}
		default: {
			throw new Error(`Invalid ObjType: ${p.type}!`);
		}
	}
};

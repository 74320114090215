import { servicesInfo } from "@shared/constants/re_services";
import React from "react";
import WebLink from "../../common_comps/WebLink";
import List from "./Parts/List";
import ServiceWithCustomSections from "./ServiceWithCustomSections";

const ZemesSklypuFormavimoProjektai: React.FC<{}> = () => {
	return (
		<ServiceWithCustomSections
			title={servicesInfo.ZEMES_SKLYPU_FORMAVIMO.title}
			descr={
				"Kiekvienas sklypo formavimo ir pertvarkymo projektas yra unikalus ir reikalaujantis patirties bei žinių, kaip viską atlikti greitai ir kokybiškai. Kvalifikuoti „Dzūkijos valdų“ specialistai siūlo visas paslaugas ir gali atlikti reikiamus projektus bei užtikrina lankstų požiūrį bei išskirtinį dėmesį kiekvienam projektui."
			}
			sections={[
				{
					title: "Kas yra formavimo ir pertvarkymo projektai?",
					content: (
						<>
							<p className="srvs__p">
								Pagal{" "}
								<a
									className="link"
									target="_blank"
									rel="noreferrer"
									href="http://www.nzt.lt/go.php/lit/Zemes-sklypu-formavimo-ir-pertvarkymo-projektai"
								>
									Nacionalinės žemės tarnybos apibrėžimą
								</a>
								, žemės sklypų formavimo ir pertvarkymo projektas yra žemėtvarkos planavimo dokumentas,
								priskiriamas žemės valdos projektams. Projektu nustatomos žemės sklypų ribos
								projektuojant naujus žemės sklypus, keičiant gretimų sklypų ribas, dalijant arba
								jungiant žemės sklypus.
							</p>
							<p className="srvs__p">
								Projekto teikiami sprendiniai turi remtis kompleksinių ar specialiųjų teritorijų
								planavimo dokumentų sprendiniais ir negali prieštarauti teritorijų planavimo normų ir
								statybos techninių reglamentų reikalavimams.
							</p>
						</>
					),
				},
				{
					title: "Kada reikalingi sklypų formavimo ir pertvarkymo projektai?",
					content: (
						<>
							<p className="srvs__p">
								„Dzūkijos valdos“ per daugiau nei 15 darbo metų atliko ne vieną sklypo formavimo ir
								pertvarkymo projektą. Dažniausiai projekto prireikia siekiant padalinti sklypą ar
								atskirti dalį sklypo iš nuosavybės bei suformuoti jį kaip naują, atskirą sklypą.
								Atliekant tokius veiksmus, reikia suprojektuoti atskirus sklypus, numatyti įvažiavimus,
								jei reikia numatyti servitutus įvažiavimams ar komunikacijoms.
							</p>
							<p className="srvs__p">
								Parengtas projektas suderinamas, registruojamas Registrų centre ir suformuoti objektai
								gauna skirtingus kadastrinius bei unikalius numerius.
							</p>
							<p className="srvs__p">
								Kita situacija, kuomet reikalingi žemės sklypų formavimo ir pertvarkymo projektai,
								dažniausiai yra sklypų sujungimas arba kai prie esamo sklypo prijungiama įsiterpusi
								valstybinė žemė.
							</p>
							<p className="srvs__p">
								Visos situacijos, kuomet reikalingi formavimo ir pertvarkymo projektai:
							</p>
							<List
								elements={[
									"žemės sklypas padalinamas į daugiau sklypų,",
									"sklypas bendros nuosavybės teise priklausantis keliems asmenims, atidalinamas į kelis, ",
									"keli ar daugiau sklypų sujungiami į vieną,",
									"formuojamas žemės sklypas aplink esamus statinius,",
									"formuojamas valstybinės žemės sklypas viešoms erdvėms (parkams, kapinėms, skverams ir pan.) arba esamoms susisiekimo komunikacijoms,",
									"žemės sklypo paskirtis pakeičiama pagal sklypo naudojimo būdą ar paskirtį. ",
								]}
							/>
						</>
					),
				},
				{
					title: "Dokumentai reikalingi sklypo formavimui ar pertvarkymui",
					content: (
						<>
							<p className="srvs__p">
								Prireikus sklypo formavimo ir pertvarkymo projekto, kreipkitės į „Dzūkijos valdų“
								specialistus, kurie pateiks visą reikiamą informaciją nuo ko reikia pradėti, ko imtis ir
								kaip paspartinti visą procesą. Tačiau pradžiai, norint pradėti žemės sklypų formavimo ar
								pertvarkymo projektą, reikia turėti šiuo dokumentus:
							</p>
							<List
								elements={[
									"Žemės sklypo geodezinis planas, jei pertvarkomas jau suformuotas sklypas, ",
									"Nekilnojamojo turto registro išrašas,",
									"Leidimas bei sąlygos formavimo ir pertvarkymo projektui rengti (išduoda savivaldybės administracijos direktorius).",
								]}
							/>
						</>
					),
				},
				{
					title: "Kaip vyksta projekto rengimas?",
					content: (
						<>
							<p className="srvs__p">
								Projekto rengimas vyksta etapais, paeiliui įvykdant užbrėžtus tikslus. Įprastai projektų
								rengimas vyksta tokia tvarka:
							</p>
							<List
								elements={[
									"Pateikiamas prašymas gauti leidimą ir sąlygas žemės sklypo formavimo ir pertvarkymo projektui rengti,",
									"Gavus leidimą projektui, sudaroma projekto rengimo sutartis,",
									"Jei nurodyta projekto sąlygose, daroma žemės sklypo ",
									"Parengiama projekto grafinė ir tekstinė dalis ",
									"Vyksta žemės sklypo formavimo ir pertvarkymo projekto viešinimas, derinimas, tikrinimas bei tvirtinimas",
									"Atliekami kadastriniai matavimai suformuotiems žemės sklypams.",
								]}
							/>
							<p className="srvs__p">
								Procesas nėra lengvas ir greitas, todėl siekiant, jog viskas būtų atlikta laiku ir
								kokybiškai, geriausia kreiptis į „Dzūkijos valdų“ specialistus.
							</p>
						</>
					),
				},
				{
					title: "Sklypo formavimo ir pertvarkymo kaina",
					content: (
						<>
							<p className="srvs__p">
								Žemės sklypų formavimo ir pertvarkymo projektai yra kompleksiniai ir individualūs. Nėra
								identiškų projektų, tad deja, nėra „vienos kainos“. Sklypo formavimo kaina priklauso nuo
								sklypo dydžio ir jame esančių objektų (pastatų, statinių, komunikacijų ir apribojimų).
								Sklypo padalinimo atveju, kaina priklauso nuo to, į kiek dalių dalinamas sklypas.
							</p>
							<p className="srvs__p">
								Konkrečią kainą „Dzūkijos valdų“ konsultantai pateiks pokalbio metu.
								<WebLink url="/kontaktai">Susisiekime</WebLink>
							</p>
						</>
					),
				},
			]}
		/>
	);
};

export default ZemesSklypuFormavimoProjektai;

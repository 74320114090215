import { servicesInfo } from "@shared/constants/re_services";
import React from "react";

const Footer: React.FC<{}> = () => {
	return (
		<footer className="footer">
			<div className="greenline"></div>
			<div className="foot side-padding">
				<div className="foot__row-1">
					<div className="foot__wrap">
						<div className="foot__title">
							<i className="icon-phone" />
							Kontaktai
						</div>
						<div className="foot__box">
							<div className="foot__desc">
								<div>
									Mob. nr: <a href="tel:+37061119038">+370 611 19038</a>
								</div>
								<div>
									Tel. nr: <a href="tel:+37031551525">+370 (315) 51525</a>
								</div>
								<div>
									El. paštas: <a href="mailto:info@dzukuvaldos.lt">info@dzukuvaldos.lt</a>
								</div>
							</div>
						</div>
					</div>
					<div className="foot__wrap">
						<div className="foot__title">
							<i className="icon-map-marker-alt" />
							Adresas
						</div>
						<div className="foot__box">
							<div className="foot__desc">
								<div>Vytauto g. 2</div>
								<div>Alytus</div>
								<div>LT-62151</div>
							</div>
						</div>
					</div>
				</div>
				<div className="foot__row-2">
					<div className="foot__boxes2_wrap">
						<div className="foot__box foot__links-wrapper">
							<div className="foot__desc foot__links">
								{Object.values(servicesInfo)
									.slice(0, 8)
									.map((serviceInfo) => (
										<a key={serviceInfo.url} className="foot__link" href={`/${serviceInfo.url}`}>
											{serviceInfo.title}
										</a>
									))}
							</div>
							<div className="foot__desc foot__links">
								{Object.values(servicesInfo)
									.slice(8, Object.values(servicesInfo).length)
									.map((serviceInfo) => (
										<a key={serviceInfo.url} className="foot__link" href={`/${serviceInfo.url}`}>
											{serviceInfo.title}
										</a>
									))}
								<a className="foot__link" href="/svetaines-struktura">
									Svetainės struktūra
								</a>
							</div>
						</div>
					</div>
				</div>
			</div>
			<div className="remark side-padding">
				<span>© {new Date().getFullYear()} UAB „Dzūkijos valdos“. Visos teisės saugomos</span>
			</div>
		</footer>
	);
};
export default Footer;

import { AllObjPropsC } from "@shared/types/real_estate";

export default function adressLineGen(obj: AllObjPropsC) {
	let address = `Lietuva, ${obj.city}`;
	if (obj.municipality) address += `, ${obj.municipality}`;
	if (obj.eldership) address += `, ${obj.eldership}`;
	if (obj.microRegion) address += `, ${obj.microRegion}`;
	if (obj.street) address += `, ${obj.street}`;
	return address;
}

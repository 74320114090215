import { PickPartial } from "./common";

export enum ObjAction {
	SELL = "SELL",
	RENT = "RENT",
}

export type AllTypes = keyof typeof ObjType;

export enum ObjType {
	FLAT = "FLAT",
	HOUSE = "HOUSE",
	LAND = "LAND",
	GARAGE = "GARAGE",
	LODGING = "LODGING",
	HOMESTEAD = "HOMESTEAD",
	GARDEN_HOUSE = "GARDEN_HOUSE",
}

export enum HouseType {
	HOUSE = "HOUSE",
	PART_OF_HOUSE = "PART_OF_HOUSE",
	BLOCK_HOUSE = "BLOCK_HOUSE",
	OTHER = "OTHER", // nukeliamas namas, projektas ir t.t
}

export enum BuildingType {
	BRICK = "BRICK",
	BLOCK = "BLOCK",
	MONOLITHIC = "MONOLITHIC",
	WOODEN = "WOODEN",
	CARCASS = "CARCASS",
	WOODEN_LOGS = "WOODEN_LOGS",
	OTHER = "OTHER",
}

export enum HeatingType {
	CITY_CENTRAL = "CITY_CENTRAL",
	LOCAL_CENTRAL = "LOCAL_CENTRAL",
	CENTRAL_COLLECTORING = "CENTRAL_COLLECTORING",
	GAS = "GAS",
	ELECTRIC = "ELECTRIC",
	AEROTERMIC = "AEROTERMIC",
	GEOTERMIC = "GEOTERMIC",
	LIQUID_FUEL = "LIQUID_FUEL",
	SOLID_FUEL = "SOLID_FUEL",
	OTHER = "OTHER",
	FURNANCE = "FURNANCE", // krosnis
	NONE = "NONE",
}

export enum WaterType {
	CITY_WATER = "CITY_WATER",
	LOCAL_WATER = "LOCAL_WATER",
	WELL = "WELL",
	SEWER = "SEWER",
}

export enum InstallationType {
	EQUIPPED = "EQUIPPED",
	PARTIAL_EQUIPPED = "PARTIAL_EQUIPPED",
	PARTIAL_DECORATION = "PARTIAL_DECORATION",
	NOT_EQUIPPED = "NOT_EQUIPPED",
	NOT_FINISHED = "NOT_FINISHED",
	FOUNDATION = "FOUNDATION",
	OTHER = "OTHER",
}

export enum BuildingPurpose {
	ADMINISTRATION = "ADMINISTRATION",
	COMMERCIAL = "COMMERCIAL",
	HOTEL = "HOTEL",
	SERVICE = "SERVICE",
	WAREHOUSE = "WAREHOUSE",
	INDUSTRIAL = "INDUSTRIAL",
	FOOD_SERVICES = "FOOD_SERVICES",
	GARDEN_HOUSE = "GARDEN_HOUSE",
	OTHER = "OTHER",
}

export enum LandPurpose {
	HOME = "HOME",
	FLAT = "FLAT",
	LAND_FARM = "LAND_FARM",
	GARDEN = "GARDEN", // Sodai, sodininkų bendrijos
	FOREST_FARM = "FOREST_FARM",
	INDUSTRIAL = "INDUSTRIAL",
	WAREHOUSE = "WAREHOUSE",
	COMMERCIAL = "COMMERCIAL",
	RECREATIONAL = "RECREATIONAL",
	OTHER = "OTHER",
}

export enum GarageType {
	BRICK = "BRICK",
	METAL = "METAL",
	UNDERGROUND = "UNDERGROUND",
	MULTI_FLOOR = "MULTI_FLOOR",
	OTHER = "OTHER",
}

export enum GarageFeatures {
	SECURITY = "SECURITY",
	AUTOMATIC_GATES = "AUTOMATIC_GATES",
	HOLE = "HOLE",
	BASEMENT = "BASEMENT",
	WATER = "WATER",
	HEATING = "HEATING",
}

export enum LandFeatures {
	WATER = "WATER",
	GAS = "GAS",
	ELECTRICITY = "ELECTRICITY",
	SEWER = "SEWER",
	WELL = "WELL",
}

export enum BuildingFeatures {
	GAS = "GAS",
	ASPHALT_ROAD = "ASPHALT_ROAD",
	NEAR_FOREST = "NEAR_FOREST",
	TV = "TV",
	ELECTRICITY = "ELECTRICITY",
	PHONE_LINE = "PHONE_LINE",
	INTERNET = "INTERNET",
}

export interface AllObjProps {
	id: number;
	titleGenned: boolean;
	featured: boolean;
	featured_nr?: number;
	old_id?: number;
	publicId: string;
	action: ObjAction;
	title: string;
	type: ObjType;
	insideSpace?: number;
	outsideSpace?: number;
	price: number;
	municipality?: string; //savivaldybe
	eldership?: string; // seniunija
	imgUrls: string[];
	city?: string /* city or village name */;
	microRegion?: string; //mikrorajonas
	street?: string;
	streetNr?: string;
	description?: string;
	roomCount?: number;
	floor?: number;
	toFloor?: number; // if object ranges to few floors. Example: 3-4/6 (the object is in 3 and 4 floors, and it totally has 6 floors)
	totalFloors?: number;
	buildingYear?: number;
	buildingType: BuildingType[];
	garageType: GarageType[];
	heatingType: HeatingType[];
	waterType: WaterType[];
	installationType: InstallationType[];
	landPurpose: LandPurpose[];
	buildingPurpose: BuildingPurpose[];
	carFitCount?: number;
	garageFeatures: GarageFeatures[];
	landFeatures: LandFeatures[];
	buildingFeatures: BuildingFeatures[];
	security?: string;
	uploadDate: string;
	updateDate: string;
	houseType: HouseType[];
	mapUrl?: string;
	condition?: string;
	additionalLodgings: string;
	enabled: boolean;
	old_views_counter: number;
	views_counter: number;
}

export type AllObjPropsC = Omit<AllObjProps, "id" | "old_id"> & {
	id?: undefined;
	old_id?: undefined;
};

export type EnumProps =
	| "BuildingFeatures"
	| "BuildingType"
	| "GarageType"
	| "InstallationType"
	| "HeatingType"
	| "BuildingPurpose"
	| "LandPurpose"
	| "GarageFeatures"
	| "LandFeatures"
	| "HouseType"
	| "WaterType";

export interface AllObjPropsUpload extends AllObjProps {
	imageFiles: File[];
}

/** All props for all type objects that are used for stats table in single object page */
export type StatProps = Pick<
	AllObjPropsC,
	| "insideSpace"
	| "roomCount"
	| "floor"
	| "totalFloors"
	| "buildingYear"
	| "buildingType"
	| "heatingType"
	| "garageType"
	| "houseType"
	| "waterType"
	| "installationType"
	| "outsideSpace"
	| "landPurpose"
	| "buildingPurpose"
	| "carFitCount"
	| "garageFeatures"
	| "landFeatures"
	| "buildingFeatures"
	| "condition"
	| "additionalLodgings"
	| "security"
>;

export const statProps: (keyof StatProps)[] = [
	"insideSpace",
	"roomCount",
	"floor",
	"totalFloors",
	"buildingYear",
	"buildingType",
	"heatingType",
	"installationType",
	"buildingPurpose",
	"outsideSpace",
	"landPurpose",
	"landPurpose",
	"carFitCount",
	"garageFeatures",
	"landFeatures",
	"buildingFeatures",
	"condition",
	"security",
	"houseType",
	"additionalLodgings",
	"waterType",
];

export type ObjCommon = Pick<
	AllObjProps,
	| "publicId"
	| "title"
	| "type"
	| "price"
	| "municipality"
	| "city"
	| "microRegion"
	| "street"
	| "description"
	| "imgUrls"
	| "action"
	| "uploadDate"
	| "updateDate"
	| "mapUrl"
	| "outsideSpace"
	| "enabled"
	| "streetNr"
	| "eldership"
>;

export type Flat = ObjCommon &
	Pick<
		AllObjProps,
		| "roomCount"
		| "floor"
		| "toFloor"
		| "totalFloors"
		| "buildingYear"
		| "buildingType"
		| "heatingType"
		| "installationType"
		| "insideSpace"
	> &
	PickPartial<AllObjProps, "outsideSpace"> & {
		type: ObjType.FLAT;
	};
export type House = ObjCommon &
	Pick<
		AllObjProps,
		| "roomCount"
		| "floor"
		| "toFloor"
		| "totalFloors"
		| "buildingYear"
		| "buildingType"
		| "heatingType"
		| "installationType"
		| "houseType"
		| "insideSpace"
	> &
	PickPartial<AllObjProps, "outsideSpace"> & {
		type: ObjType.HOUSE;
	};

export type Homestead = ObjCommon &
	Pick<
		AllObjProps,
		| "roomCount"
		| "floor"
		| "toFloor"
		| "totalFloors"
		| "buildingYear"
		| "buildingType"
		| "heatingType"
		| "installationType"
		| "houseType"
		| "insideSpace"
	> &
	PickPartial<AllObjProps, "outsideSpace"> & {
		type: ObjType.HOMESTEAD;
	};
export type GardenHouse = ObjCommon &
	Pick<
		AllObjProps,
		| "roomCount"
		| "floor"
		| "toFloor"
		| "totalFloors"
		| "buildingYear"
		| "buildingType"
		| "heatingType"
		| "installationType"
		| "houseType"
		| "insideSpace"
	> &
	PickPartial<AllObjProps, "outsideSpace"> & {
		type: ObjType.GARDEN_HOUSE;
	};
export type Lodging = ObjCommon &
	Pick<AllObjProps, "floor" | "totalFloors" | "buildingPurpose" | "toFloor" | "insideSpace" | "landPurpose"> &
	PickPartial<AllObjProps, "outsideSpace"> & {
		type: ObjType.LODGING;
	};
export type Land = ObjCommon &
	Pick<AllObjProps, "landPurpose" | "landFeatures" | "outsideSpace" | "landPurpose" | "landFeatures"> &
	PickPartial<AllObjProps, "outsideSpace"> & {
		type: ObjType.LAND;
		outsideSpace: number;
	};
export type Garage = ObjCommon &
	Pick<
		AllObjProps,
		"buildingType" | "carFitCount" | "garageFeatures" | "insideSpace" | "garageType" | "garageFeatures"
	> &
	PickPartial<AllObjProps, "outsideSpace" | "garageFeatures"> & {
		//buildingType: GarageType;
		//features: LandFeatures;
		type: ObjType.GARAGE;
	};

export type AnyObject = Flat | House | Lodging | Land | Garage | Homestead | GardenHouse;

export function isFlat(object: AnyObject): object is Flat {
	return object.type === ObjType.FLAT;
}
export function isHouse(object: AnyObject): object is House {
	return object.type === ObjType.HOUSE;
}
export function isLodging(object: AnyObject): object is Lodging {
	return object.type === ObjType.LODGING;
}
export function isLand(object: AnyObject): object is Land {
	return object.type === ObjType.LAND;
}
export function isGarage(object: AnyObject): object is Garage {
	return object.type === ObjType.GARAGE;
}

export type UrlProps = Pick<AllObjProps, "type" | "city" | "title" | "publicId" | "municipality" | "action"> &
	PickPartial<AllObjProps, "microRegion" | "street">;

export type SearchFilterOpts = PickPartial<
	AllObjProps,
	| "city"
	| "municipality"
	| "street"
	| "buildingType"
	| "installationType"
	| "heatingType"
	| "houseType"
	| "landPurpose"
	| "buildingPurpose"
	| "landFeatures"
	| "garageFeatures"
	| "buildingFeatures"
	| "garageType"
> & {
	action?: ObjAction | "";
	type?: ObjType | "";
	keywords?: string;
	fromPrice?: number;
	toPrice?: number;
	fromMPrice?: number;
	toMPrice?: number;
	fromPricePerAre?: number;
	toPricePerAre?: number;
	fromInsideSpace?: number;
	toInsideSpace?: number;
	fromOutsideSpace?: number;
	toOutsideSpace?: number;
	fromFloor?: number;
	toFloor?: number;
	fromYear?: number;
	toYear?: number;
	fromRoom?: number;
	toRoom?: number;
};

export enum Order {
	BEST_MATCH = "BEST_MATCH",
	CHEAPEST_FIRST = "CHEAPEST_FIRST",
	CHEAPEST_BY_UNIT = "CHEAPEST_BY_UNIT",
	EXPENSIVE_FIRST = "EXPENSIVE_FIRST",
	EXPENSIVE_BY_UNIT = "EXPENSIVE_BY_UNIT",
	NEWEST_FIRST = "NEWEST_FIRST",
	OLDEST_FIRST = "OLDEST_FIRST",
}

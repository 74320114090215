import React from "react";
import { houseInHand, worldInHand } from "@webImgs";
import ObjectsLine from "./ObjectsLine";
import { Link } from "react-router-dom";
import useGStore from "../../logic/store";
import Seperator from "../../common_comps/Seperator";
import _ from "lodash";
import { PageUrl } from "@shared/types/common";
import { observer } from "mobx-react-lite";
import LoadAfter from "@client/common/util/LoadAfter";
import getYearCount from "@shared/util/getYearCount";
import Headline from "../../common_comps/Headline";
import SearchBox from "../../common_comps/SearchBox/SearchBox";
import SvgInlined from "../../common_comps/SvgInlined";
import TopBackground from "../../common_comps/TopBackground";
import Button from "../../common_comps/UI/Button";

const ChooseService: React.FC<{}> = () => {
	return (
		<div className="svc">
			<div className="svc__leaf svc__leaf--top-left svc__leaf--sm" />
			<div className="svc__leaf svc__leaf--top-right svc__leaf--big" />
			<div className="svc__leaf svc__leaf--bottom-left svc__leaf--big" />
			<div className="section" id="nt-choose">
				{/* <div className="img-space">
					<SvgInlined svgText={mapWithPoints} />
				</div> */}
				<div className="info-space">
					<h3 className="light-title">Nekilnojamas turto pardavimas, nuoma, konsultacijos</h3>
					<div className="big-seperated-icon">
						<SvgInlined svgText={houseInHand} />
					</div>
					<div className="description">
						Jei jums reikia parduoti ar ketinate pirkti nekilnojamą turtą, tuomet mūsų NT brokeriai
						pasirengę Jums padėti! Jau daugiau nei {getYearCount()} metų teikiamos NT brokerių paslaugos
						pietų Lietuvoje: Alytuje, Druskininkuose, Marijampolėje, Varėnoje, Prienuose ir Lazdijuose bei
						aplinkiniuose miestuose. Ieškote naujo būsto - pradėkite nuo NT skelbimų portalo – čia rasite
						šimtus Dzūkijos krašto NT skelbimų, tikėtina, jog būtent šiame skelbimų portale rasite tai ko
						ieškote. Norite parduoti – kreipkitės ir brokerių komanda imsis pardavimo nedelsiant! Nesvarbu,
						koks jūsų tikslas, mūsų NT agentai ras geriausią sprendimą, pateiks patarimų ir atliks visus
						darbus už jus. Specialistai yra išanalizavę rinką, lanksčiai reaguoja į pokyčius, todėl gali
						kvalifikuotai patenkinti klientų pageidavimus.
					</div>
					<div className="info-space__btn">
						<Link to={PageUrl.NT_SKELBIMAI}>
							<Button className="hp__side-btn">Mūsų NT Skelbimai</Button>
						</Link>
					</div>
				</div>
			</div>
			<Seperator />
			<div className="section geodesy-choose">
				<div className="info-space info-space--green">
					<h3 className="light-title">
						Matininkų paslaugos: Kadastriniai (geodeziniai) matavimai, topografinės nuotraukos, namų
						pridavimas ir daugiau
					</h3>
					<div className="big-seperated-icon">
						<SvgInlined svgText={worldInHand} />
					</div>
					<div className="description">
						Visos matininkų paslaugos vienoje vietoje. Mūsų darni komanda jau daugiau nei {getYearCount()}{" "}
						metų teikia svarbiausias matininkų paslaugas pietų Lietuvoje. Tarp dažniausiai užsakomų ir
						reikalingiausių paslaugų yra šios: žemės sklypų kadastriniai (geodeziniai) matavimai, statinių
						kadastriniai matavimai (inventorizacija), topografinės nuotraukos (planai), namų pridavimas,
						statybos užbaigimas, energetinio naudingumo sertifikatai, pastatų pririšimo išpildomosios
						nuotraukos, inžinerinių tinklų kontrolinės - geodezinės nuotraukos, detalieji planai, žemės
						sklypo (teritorijų) planavimas ir formavimas (sujungimas, dalinimas), kaimo plėtros žemėtvarkos
						projektai. Mūsų matininkai dirba operatyviai, darbų procesuose taikomos modernios technologijas
						ir novatoriški sprendimai.
					</div>
					<div className="info-space__btn">
						<Link to={PageUrl.NT_PASLAUGOS}>
							<Button className="hp__side-btn">
								NT Matavimai, Sertifikatai, Planai, Projektai ir daugiau
							</Button>
						</Link>
					</div>
				</div>
				{/* <div className="img-space">
					<img src="/assets/img/measurer.png" />
				</div> */}
			</div>
		</div>
	);
};

const Home: React.FC<{}> = observer((p) => {
	const store = useGStore();
	return (
		<div className="hp">
			<TopBackground
				title="NT Agentūra Alytuje. Kadastriniai (geodeziniai) matavimai. NT pardavimas."
				description={`Visos NT ir matininkų paslaugos vienoje vietoje: NT brokeris (pardavimas, nuoma), topografinės nuotraukos, namo pridavimas, kaimo plėtros projektai, teritorijų planavimas, sklypo paskirties keitimas ir dar daugiau paslaugų gyventojams bei verslui.`}
				backgroundImg="/mainPage-head-bg.png"
			/>
			<div className="side-padding">
				<ChooseService />
				<Headline
					hType={"h3"}
					id="start-search-headl"
					text="Nekilnojamo turto paieška"
					iconClass="icon-house"
				/>
				<SearchBox />
			</div>
			<LoadAfter>
				<div className="hp__obj-row-wrap">
					<div className="hp__obj-row">
						<Headline type="none" hType={"h3"} text="Naujausi pasiūlymai" iconClass="icon-house" />
						<ObjectsLine objects={_.orderBy(store.objsArr.all, "uploadDate", "desc")} />
					</div>
					<Seperator mod="horizontal" className="hp__rows-sep" />
					<div className="hp__obj-row">
						<Headline type="none" hType={"h3"} text="Dzūkijos Valdos rekomenduoja" iconClass="icon-house" />
						<ObjectsLine
							objects={_.orderBy(
								store.objsArr.all.filter((o) => o.featured),
								["featured_nr"],
								["asc"]
							)}
						/>
					</div>
				</div>
			</LoadAfter>
		</div>
	);
});

export default Home;

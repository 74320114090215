import { observer, useLocalStore } from "mobx-react-lite";
import React from "react";

const StickyContactBtn: React.FC<{}> = observer(() => {
	const LS = useLocalStore(() => ({
		showMore: false,
	}));

	React.useEffect(() => {
		let chatbox = document.getElementById("fb-customer-chat");
		chatbox!.setAttribute("page_id", "765106306998123");
		chatbox!.setAttribute("attribution", "biz_inbox");
		(window as any).fbAsyncInit = function () {
			(window as any).FB.init({
				xfbml: true,
				version: "v10.0",
			});
		};

		(function (d, s, id) {
			var js,
				fjs = d.getElementsByTagName(s)[0];
			if (d.getElementById(id)) return;
			js = d.createElement(s);
			js.id = id;
			js.src = "https://connect.facebook.net/en_US/sdk/xfbml.customerchat.js";
			fjs!.parentNode!.insertBefore(js, fjs);
		})(document, "script", "facebook-jssdk");
	});

	return (
		<div className="sc">
			<div className="sc__contact-text">Susisiekite</div>
			<div className="sc__btns-wrapper">
				<div className="sc__main-btn" onClick={() => (LS.showMore = !LS.showMore)}>
					<i className="icon-comments sc__main-btn-icon" />
				</div>
				<div className="sc__other-btns" style={{ display: LS.showMore ? "block" : "none" }}>
					<a className="sc__call sc__contact-method" href="tel:+37061119038">
						<i className="icon-phone" />
					</a>
					<div className="sc__facebook sc__contact-method">
						<div id="fb-root"></div>
						<div id="fb-customer-chat" className="fb-customerchat"></div>
					</div>
				</div>
			</div>
		</div>
	);
});

export default StickyContactBtn;

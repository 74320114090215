import React from "react";
import { geodesy4 } from "@webImgs";
import _ from "lodash";
import ServiceBox from "./ServiceBox";
import { servicesInfo } from "@shared/constants/re_services";

interface P {}
const Geodesy: React.FunctionComponent<P> = () => {
	return (
		<div className="geo side-padding">
			<div className="geo__head">
				<div className="geo__head-text">
					<h1 className="geo__head-text-h1">
						Kadastriniai (Geodeziniai) matavimai, Topografinės nuotraukos, Namų pridavimas ir kitos
						matininkų paslaugos
					</h1>
					<span className="geo__head-text-desc">
						Matavimai skirti įvertinti tam tikro objekto vertę, matmenis, paviršių bei kitas
						charakteristikas. Kitais žodžiais tariant, matavimų esmė yra nustatyti nekilnojamo turto ar
						žemės sklypo vertę, naudmenis ir kitus duomenis. Gauti duomenys surašomi kadastrinių matavimų
						byloje.
					</span>
				</div>

				<img
					className="geo__head-img"
					title={
						"Kadastriniai (Geodeziniai) matavimai, Topografinės nuotraukos, Namų pridavimas ir kitos matininkų paslaugos"
					}
					alt="Kadastriniai (Geodeziniai) matavimai, Topografinės nuotraukos, Namų pridavimas ir kitos matininkų paslaugos"
					src={geodesy4}
				/>
			</div>
			<div className="geo__box">
				{_.map(servicesInfo, (val) => (
					<ServiceBox key={val.url} title={val.title} descr={val.descr} url={val.url} />
				))}
			</div>
		</div>
	);
};

export default Geodesy;

import { servicesInfo } from "@shared/constants/re_services";
import React from "react";
import WebLink from "../../common_comps/WebLink";
import List from "./Parts/List";
import ServiceWithCustomSections from "./ServiceWithCustomSections";

const RibozenkliuAtkurimas: React.FC<{}> = () => {
	return (
		<ServiceWithCustomSections
			title={servicesInfo.RIBOZENKLIU_ATKURIMAS.title}
			descr={
				"Iškilus bet kokiems klausimams dėl sklypo ribų, lengviausia patikrinti riboženklius. Tačiau jei jie sunaikinti ar nėra patikimi, reikia juos atkurti. Tuo gali pasirūpinti Dzūkijos Valdos specialistai geodezininkai, kurie atliks visus riboženklių atstatymo bei patikrinimo darbus. "
			}
			sections={[
				{
					title: "Kas yra riboženkliai?",
					content: (
						<>
							<p className="srvs__p">
								Riboženkliai – tai specialūs ženklai, kurie pažymi sklypo ar teritorijos ribas. Jie
								reikalingi tiek gyvenamųjų sklypų ribos, tiek savivaldybės žemės ribas, tiek
								nacionalinių ir regioninių parkų, saugomų objektų ir pan. pažymėjimui.
							</p>
							<p className="srvs__p">
								Riboženklių naudojimas ir pavidalas yra pagal nustatytus standartus, juos statyti gali
								tik kvalifikuoti geodezininkai ir laikantis instrukcijų. Riboženkliais sklypų ribos ir
								kitos vietos sužymimos kadastrinių matavimų metu.
							</p>
							<p className="srvs__p">
								Patys riboženkliai gali būti skirtingų dizainų ir išvaizdos. Dažniausiai naudojami
								metaliniai riboženkliai, kadangi metalas yra ilgaamžis. Rečiau naudojami betoniniai, nes
								juos sudėtingiau įtvirtinti ir jie yra didelio svorio. Taip pat būna plastikiniai, kurie
								dar vadinami laikinais riboženkliais ir gali būti naudojami tik pageidavus užsakovui.
							</p>
						</>
					),
				},
				{
					title: "Kas yra riboženklių atkūrimas?",
					content: (
						<>
							<p className="srvs__p">
								Kiekvienas sklypas, jei buvo atmatuotas, pirktas ar paveldėtas turi turėti riboženklį.
								Kadangi sklypo ribos yra automatiškai nužymimos atliekant kadastrinius matavimus, jei
								praeityje sklypu buvo disponuota, jis turi turėti riboženklius.
							</p>
							<p className="srvs__p">
								Deja, jie gali būti prarasti, jei buvo naudoti laikinieji, plastikiniai riboženkliai,
								jei vyko statybos, dėl neapdairaus elgesio ar riboženklis galėjo būti iškeltas. Tuomet
								norint įsitikinti sklypo ribomis ar kitais svarbiais taškais, reikalingas riboženklių
								atstatymas. Jį atlieka kvalifikuoti geodezininkai pagal pateiktus dokumentus. Jei
								patikimų dokumentų nėra, atliekami kadastriniai matavimai ir nužymimos sklypo ribos.
							</p>
							<p className="srvs__p">
								Svarbu pažymėti, jog riboženkliai turi būti saugomi. Už riboženklių apsaugą atsako žemės
								sklypo savininkas Lietuvos Respublikos teisės aktų nustatyta tvarka, o asmuo ar asmenys,
								sunaikinęs ar sugadinęs riboženklius atsako LR administracinių teisės pažeidimų kodekso
								nustatyta tvarka.
							</p>
						</>
					),
				},
				{
					title: "Kokie dokumentai reikalingi riboženklių atstatymui?",
					content: (
						<>
							<p className="srvs__p">Norint atlikti riboženklių atstatymą reikia šių dokumentų:</p>
							<List elements={["žemės sklypo plano su koordinatėmis,", "žemės sklypo NT išrašo."]} />
							<p className="srvs__p">
								Žemės sklypo riboženklių atkūrimas atliekamas tik tada, kai jau yra atlikti žemės sklypo
								kadastriniai matavimai.
							</p>
						</>
					),
				},
				{
					title: "Kas yra riboženklių patikrinimas?",
					content: (
						<>
							<p className="srvs__p">
								Perkant ar nuomojantis žemės sklypą, kurio ribos yra nužymėtos, svarbu įsitikinti, jog
								jos yra teisingos. Sklypo ribos gali būti piktybiškai pakeistos, klaidingai sužymėtos
								atliekant žymėjimus patiems, o ne patikėjus darbą specialistams. Todėl siekiant
								įsitikinti, jog ribos teisingos, būtinas riboženklių patikrinimas. Tą gali atlikti
								kvalifikuoti Dzūkijos Valdos geodezininkai. Patikrinimui reikalingas žemės sklypo
								planas. Jei nustatoma, jog ribos sužymėtos neteisingai ar riboženkliai pažeisti,
								atliekamas riboženklių atkūrimas.
							</p>
						</>
					),
				},
				{
					title: "Riboženklių atkūrimo kaina",
					content: (
						<>
							<p className="srvs__p">
								Riboženklių atkūrimo kaina nustatoma individualiai nuo esamos situacijos. Kaina
								priklauso nuo trūkstamų riboženklių skaičiaus, sklypo konfigūracijos, reljefo ir kitų su
								sklypu susijusių niuansų. <WebLink url="/kontaktai">Susisiekime</WebLink> ir Dzūkijos
								Valdos konsultantai pateiks tikslesnę informaciją bei kainą jūsų atveju.
							</p>
						</>
					),
				},
			]}
		/>
	);
};

export default RibozenkliuAtkurimas;

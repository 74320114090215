import { observable, action, computed, reaction, toJS } from "mobx";
import { AllObjPropsC, SearchFilterOpts, Order } from "@shared/types";
import _ from "lodash";
import React from "react";
import { testObject1 } from "@shared/mock_data";
import { createBrowserHistory } from "history";
import remOrAddToArr from "@shared/util/remOrAddToArr";
// import * as serviceWorker from "@client/common/util/serviceWorker";
// serviceWorker.register();
import Fuse from "fuse.js";
import sleep from "@shared/util/sleep";
import isVillage from "@shared/util/isVillage";
import buildSearchUrl from "@shared/util/searchUrlUtil";
import { mapForDropdown } from "../common_comps/SearchBox/FilterOptions";

export const history = createBrowserHistory();

const mockObjects = [
	testObject1,
	testObject1,
	testObject1,
	testObject1,
	testObject1,
	testObject1,
	testObject1,
	testObject1,
];

export class Store {
	@computed get objsArr() {
		return {
			all: Object.values(this.objs.all),
		};
	}
	@observable objs: { all: { [publicId: number]: AllObjPropsC }; filtered: AllObjPropsC[] } = {
		all: {},
		filtered: [],
	};
	@observable filterOpts: {
		active: SearchFilterOpts | null;
		current: SearchFilterOpts;
		isExpanded: boolean;
		orderOpt: Order;
	} = {
		isExpanded: false,
		active: null,
		orderOpt: Order.NEWEST_FIRST,
		current: {
			keywords: "",
			action: "",
			type: "",
			street: "",
			city: "",
			municipality: "",
		},
	};

	@action
	async loadObjects() {
		const resp = await fetch("/ajax/objects/all");
		this.objs.all = (await resp.json()).objects;
	}

	constructor() {
		//this.loadObjects();
		this.objs.all = (window as any)._ALL_OBJS;
		//console.dir(this.objs.all);
		delete (window as any)._ALL_OBJS;
		window.onload = async () => {
			const url = window.location.pathname;
			const search = window.location.search;
			await sleep(2000);
			this.pageVisit(url, search);
		};

		history.listen(async (location) => {
			await sleep(2000);
			this.pageVisit(location.pathname, location.search);
		});

		reaction(
			() => this.filterOpts.current.city,
			(city) => {
				this.filterOpts.current.street = undefined;
			}
		);
	}

	async pageVisit(pathname: string, search: string) {
		fetch("/ajax/visit", {
			method: "POST",
			headers: {
				"Content-Type": "application/json",
			},
			body: JSON.stringify({
				pathname,
				search,
			}),
		});
	}

	searchUrlChange() {
		const fOpts = this.filterOpts.active;
		if (!fOpts) return;
		const url = buildSearchUrl(fOpts);
		history.push(url);
	}

	@action
	updateFilterOpts = (newProps: Partial<SearchFilterOpts>) => {
		_.forOwn(newProps, (newPropV, newPropK) => {
			let currFiltProp = this.filterOpts.current[newPropK];
			if (currFiltProp) {
				if (Array.isArray(currFiltProp)) {
					if (!Array.isArray(newPropV)) {
						newPropV = [newPropV];
					}
					newPropV.forEach((nv) => {
						remOrAddToArr(currFiltProp, nv);
					});
				} else {
					this.filterOpts.current[newPropK] = newPropV;
				}
			} else {
				this.filterOpts.current[newPropK] = newPropV;
			}
		});
	};

	@computed
	get citiesMunicips() {
		const PRIORITIZED = ["Alytus", "Alytaus raj."];
		let cities: string[] = [];
		let otherCities: string[] = [];
		let municips: string[] = [];
		let otherMunicips: string[] = [];

		this.objsArr.all.forEach((obj) => {
			if (obj.city) {
				if (obj.city === "Kita") {
					otherCities = ["Kita"];
				} else if (!isVillage(obj.city) && !cities.includes(obj.city)) {
					cities.push(obj.city);
				}
			}

			if (obj.municipality && !municips.includes(obj.municipality) && !otherMunicips.includes(obj.municipality)) {
				if (obj.municipality.includes("r.")) {
					municips.push(obj.municipality);
				} else {
					otherMunicips.push(obj.municipality);
				}
			}
		});

		cities = _.sortBy(cities);
		municips = _.sortBy(municips);
		otherMunicips = _.sortBy(otherMunicips);

		const allMunicips = municips.concat(otherMunicips);
		const allCities = cities.concat(otherCities);
		const all = cities.concat(municips).concat(otherMunicips).concat(otherCities);
		let allWithPrioritized = [] as string[];
		PRIORITIZED.forEach((prioritizedPlace) => {
			if (all.includes(prioritizedPlace)) {
				allWithPrioritized.push(prioritizedPlace);
			}
		});
		allWithPrioritized = _.uniq(allWithPrioritized.concat(all));

		const mappedAll = mapForDropdown(allWithPrioritized);
		return {
			mappedAll,
			allCities,
			allMunicips,
		};
	}

	@action
	filter(init?: boolean) {
		const fOpts = this.filterOpts.current;
		let filtered: AllObjPropsC[] = [];
		const fuse = new Fuse(this.objsArr.all, {
			keys: ["title", "street", "city", "municipality"],
			includeScore: true,
			threshold: 0.6,
		});

		let fuseFiltered;
		if (fOpts.keywords) {
			fuseFiltered = fuse.search(fOpts.keywords);
			fuseFiltered = fuseFiltered.map((res) => res.item);
			this.filterOpts.orderOpt = Order.BEST_MATCH;
		}

		console.log("currentOpts", toJS(fOpts));

		(fuseFiltered || this.objsArr.all).forEach((o: AllObjPropsC) => {
			const mPrice = o.price / (o.insideSpace || 1);
			const arePrice = o.price / (o.outsideSpace || 1);

			//console.log("www");
			if (fOpts.action && fOpts.action !== o.action) return false;
			//console.log("123");

			if (fOpts.type && fOpts.type !== o.type) return false;
			//console.log("222");
			if (fOpts.city && fOpts.city !== o.city) return false;
			//console.log("1");
			if (
				fOpts.buildingType &&
				fOpts.buildingType.length > 0 &&
				!o.buildingType.some((opt) => fOpts.buildingType!.includes(opt))
			)
				return false;
			//console.log("2");

			if (
				fOpts.landFeatures &&
				fOpts.landFeatures.length > 0 &&
				!o.landFeatures.some((opt) => fOpts.landFeatures!.includes(opt))
			)
				return false;
			//console.log("3");

			if (
				fOpts.garageFeatures &&
				fOpts.garageFeatures.length > 0 &&
				!o.garageFeatures.some((opt) => fOpts.garageFeatures!.includes(opt))
			)
				return false;
			//console.log("4");

			if (
				fOpts.landPurpose &&
				fOpts.landPurpose.length > 0 &&
				!o.landPurpose.some((opt) => fOpts.landPurpose!.includes(opt))
			) {
				//console.log("false here");
				return false;
			}
			if (
				fOpts.installationType &&
				fOpts.installationType.length > 0 &&
				!o.installationType.some((opt) => fOpts.installationType!.includes(opt))
			)
				return false;
			if (
				fOpts.houseType &&
				fOpts.houseType.length > 0 &&
				!o.houseType.some((opt) => fOpts.houseType!.includes(opt))
			)
				return false;
			if (
				fOpts.heatingType &&
				fOpts.heatingType.length > 0 &&
				!o.heatingType.some((opt) => fOpts.heatingType!.includes(opt))
			)
				return false;
			//console.log("before all");

			if (fOpts.fromFloor && o.floor && o.floor < fOpts.fromFloor) return false;
			if (fOpts.toFloor && o.floor && o.floor > fOpts.toFloor) return false;
			if (fOpts.fromRoom && o.roomCount && o.roomCount < fOpts.fromRoom) return false;
			if (fOpts.toRoom && o.roomCount && o.roomCount > fOpts.toRoom) return false;
			if (fOpts.fromMPrice && mPrice < fOpts.fromMPrice) return false;
			//console.log("upper half");
			if (fOpts.toMPrice && mPrice > fOpts.toMPrice) return false;
			if (fOpts.fromOutsideSpace && o.outsideSpace && o.outsideSpace < fOpts.fromOutsideSpace) return false;
			if (fOpts.toOutsideSpace && o.outsideSpace && o.outsideSpace > fOpts.toOutsideSpace) return false;
			if (fOpts.fromPrice && o.price < fOpts.fromPrice) return false;
			if (fOpts.toPrice && o.price > fOpts.toPrice) return false;
			//console.log("half");
			if (fOpts.fromPricePerAre && o.outsideSpace && arePrice < fOpts.fromPricePerAre) return false;
			if (fOpts.toPricePerAre && o.outsideSpace && arePrice > fOpts.toPricePerAre) return false;
			if (fOpts.fromInsideSpace && o.insideSpace && o.insideSpace < fOpts.fromInsideSpace) return false;
			if (fOpts.toInsideSpace && o.insideSpace && o.insideSpace > fOpts.toInsideSpace) return false;
			if (fOpts.fromYear && o.buildingYear && o.buildingYear < fOpts.fromYear) return false;
			//console.log("down half");
			if (fOpts.toYear && o.buildingYear && o.buildingYear > fOpts.toYear) return false;
			if (fOpts.municipality && o.municipality !== fOpts.municipality) return false;
			if (fOpts.street && o.street !== fOpts.street) return false;
			//console.log("pushing");
			filtered.push(o);
		});

		console.log("filtered", filtered);
		this.filterOpts.active = { ...this.filterOpts.current };
		this.objs.filtered = filtered;
		if (!init) this.searchUrlChange();
	}
}

const storeContext = React.createContext<Store>(new Store());
export default function useGStore(): Store {
	const store = React.useContext(storeContext);
	return store;
}

import _ from "lodash";
import { ObjType } from "@shared/types/real_estate";

export const typeToTypeWhose: { [k in ObjType]: string } = {
	FLAT: "butu",
	GARAGE: "garazu",
	HOUSE: "namu",
	LAND: "sklypu-zemiu",
	LODGING: "patalpu",
	HOMESTEAD: "sodybu",
	GARDEN_HOUSE: "sodu",
};

export const typeWhoseToType = _.reduce(
	typeToTypeWhose,
	(res, val, key) => {
		const revObj = {};
		revObj[val] = key;
		return {
			...res,
			...revObj,
		};
	},
	{}
) as { [typeWhose: string]: ObjType };

import React from "react";
import { direktorius } from "@webImgs";
import Headline from "../../common_comps/Headline";

const ContactUs: React.FC<{}> = (p) => {
	return (
		<div className="cntp side-padding">
			<div>
				<Headline type={"left"} hType={"h1"} iconClass="icon-phone-square-alt2">
					Norite sužinoti kadastrinių - geodezinių matavimų kainą? <br />
					Norite pirkti, parduoti, nuomuoti Nekilnojamą Turtą? <br />
					Reikia konsultacijos? <br />
					Susisiekite!
				</Headline>
			</div>
			<div className="cntp__row">
				<div className="cntp__box">
					<div className="cntp__person-img-wrap">
						<img
							alt="Įmonės direktorius. Norite sužinoti kadastrinių - geodezinių matavimų ar kitų paslaugų kainą? Norite pirkti, parduoti, nuomuoti Nekilnojamą Turtą? Susisiekite!"
							title="Įmonės direktorius. Norite sužinoti kadastrinių - geodezinių matavimų ar kitų paslaugų kainą? Norite pirkti, parduoti, nuomuoti Nekilnojamą Turtą? Susisiekite!"
							className="cntp__box-img"
							src={direktorius}
						/>
					</div>
					<div className="cntp__info">
						<span className="cntp__info-row cntp__info-row-h1">Direktorius</span>
						<span className="cntp__info-row cntp__info-row-h2">Audrys Tulaba</span>
						<div className="cntp__info-row ">
							<i className="icon-icon cntp__icon" />
							<a href="tel:+37061119038">+370 6 111 9038</a>
						</div>
						<div className="cntp__info-row ">
							<i className="icon-phone cntp__icon" />
							<a href="tel:+37031551525">+370 (315) 51525</a>
						</div>
						<div className="cntp__info-row">
							<i className="icon-email cntp__icon" />
							<a href="mailto:info@dzukuvaldos.lt">info@dzukuvaldos.lt</a>
						</div>
						<div className="cntp__info-row">
							<i className="icon-maps-and-flags cntp__icon" />
							<a target="_blank" rel="noopener noreferrer" href="https://goo.gl/maps/7HdJkM7nKUkdnRrv7">
								Vytauto g. 2, Alytus
							</a>
						</div>
					</div>
				</div>
				<div className="cntp__box">
					{/* <div className="cntp__contact-form">
						<Headline type={"left"} hType={"h3"} text="Susisiekite su mumis!" iconClass="icon-comment" />
						<div className="cntp__form">
							<div className="cntp__form-wraper" id="contactForm">
								<div className="cntp__form-wraper-contactbox">
									<div className="cntp__form-wraper-column">
										<input
											className="cntp__form-control"
											id="name"
											type="text"
											name="name"
											required
											data-error="Prašome įrašyti Jūsų vardą ir pavardę"
											placeholder="Vardas ir Pavardė *"
										/>

										<input
											className="cntp__form-control"
											id="telnr"
											type="text"
											name="telnr"
											required
											data-error="Prašome įrašyti Jūsų telefono numerį."
											placeholder="Tel. Numeris *"
										/>

										<input
											className="cntp__form-control"
											id="email"
											type="email"
											name="email"
											required
											data-error="Please enter your Email"
											placeholder="El. paštas *"
										/>
									</div>

									<div className="cntp__form-msg">
										<textarea
											className="cntp__form-control cntp__textarea"
											// rows="3"
											id="message"
											name="message"
											required
											data-error="Prašome įrašyti žinutę"
											placeholder="Jūsų žinutė *"
										></textarea>
									</div>
								</div>

								<div className="form-submit mt-5">
									<button className="act_btn" type="submit" id="form-submit">
										<i className="material-icons mdi mdi-message-outline"></i> Siųsti
									</button>
									<div id="msgSubmit" className="h3 text-center hidden"></div>
									<div className="clearfix"></div>
								</div>
							</div>
						</div>
					</div> */}
				</div>
			</div>
			<div className="cntp__row">
				<div className="cntp__box">
					<div className="cntp__map-head">
						<i className="cntp__icon-map icon-loc_mark" />
						<span className="cntp__loc-head">Alytaus filialas</span>
					</div>
					<div className="cntp__wraper">
						{/* <div className="cntp__wraper-2">
							<i className="cntp__icon-map icon-maps-and-flags" />
							<span>Alytus, Vytauto g. 2</span>
						</div> */}
						<iframe
							src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2322.987099647654!2d24.044719235377194!3d54.39254285689491!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x46e0b145b67f5601%3A0xa7fdd9a7341c8dbb!2sDz%C5%ABkijos%20valdos%20NT%20%2F%20Geodezija!5e0!3m2!1slt!2slt!4v1586082602851!5m2!1slt!2slt"
							width="600"
							height="450"
							className="cntp__iframe"
							aria-hidden="false"
						/>
					</div>
				</div>
				<div className="cntp__box">
					<div className="cntp__map-head">
						<i className="cntp__icon-map icon-loc_mark" />
						<span className="cntp__loc-head">Varėnos filialas</span>
					</div>
					<div className="cntp__wraper">
						{/* <div className="cntp__wraper-2">
							<i className="cntp__icon-map icon-maps-and-flags" />
							<span>Varėna, Vasario 16-os g. 5</span>
							
						</div> */}
						<iframe
							src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2333.239847148765!2d24.569268551605475!3d54.21124238007203!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x46de052e2c9ff4b5%3A0xb81e9fda82de5f0c!2sVasario%2016-osios%20g.%205%2C%20Var%C4%97na%2065188!5e0!3m2!1sen!2slt!4v1588490384242!5m2!1sen!2slt"
							width="600"
							height="450"
							className="cntp__iframe"
							aria-hidden="false"
						/>
					</div>
				</div>
			</div>
		</div>
	);
};

export default ContactUs;

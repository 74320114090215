import { servicesInfo } from "@shared/constants/re_services";
import getYearCount from "@shared/util/getYearCount";
import React from "react";
import WebLink from "../../common_comps/WebLink";
import List from "./Parts/List";
import ServiceWithCustomSections from "./ServiceWithCustomSections";

const SklypuRibuNuzymejimasVietoveje: React.FC<{}> = () => {
	return (
		<ServiceWithCustomSections
			title={servicesInfo.SKLYPU_RIBU_NUZYMEJIMAS_VIETOVEJE.title}
			descr={`Žemės sklypų ribų nužymėjimas, riboženklių atstatymas bei kitos matavimo užduotys  – darbas, kurį geriausia patikėti Dzūkijos Valdos specialistams. Tai darbas reikalaujantis profesinių žinių ir ilgametės patirties, kurios per ${getYearCount()} darbo metų sukaupė Dzūkijos Valdos kvalifikuoti matininkai - geodezininkai.`}
			sections={[
				{
					title: "Kas yra sklypų ribų nužymėjimas?",
					content: (
						<>
							<p className="srvs__p">
								Sklypo ribų nužymėjimas – tai projektinių sprendinių perkėlimas į realią vietovę, kuomet
								sklype fiziškai sužymimi svarbūs taškai: sklypo ribos, pastatų ribos, kitų objektų ribos
								ir vietos (pvz., komunikacijos).
							</p>
							<p className="srvs__p">
								Kad būtų paprasčiau suprasti, paimkime pavyzdį. Statote namą naujai suformuotame
								gyvenamųjų namų kvartale – kaip jūsų statybininkai žinos, kurioje vietoje reikia lieti
								pamatus, jei aplinkui kiek akys mato yra lygus laukas? Būtent norint ne tik „pataikyti“
								namą pastatyti savo sklype, bet ir laikytis statybos projekte numatytų sprendimų,
								būtinas vietovės nužymėjimas, pagal jį vykdomi įvairūs darbai, o pabaigus patikrinama ar
								nėra nukrypimų. O jų būti ir negali, nes jei pavyzdžiui, neatsižvelgėte į nužymėjimą ar
								apskritai tvorą tvėrėte „iš akies“ prasidės nesutarimai dėl pasisavintos kaimynų žemės.
							</p>
							<p className="srvs__p">
								Žemės sklypo ribų nužymėjimas paprastai atliekamas darant kadastrinius matavimus (jie
								būtini disponuojant nekilnojamu turtu). Tačiau bėgant metams, vykstant statyboms ar
								įvykus nenumatytiems įvykiams, riboženkliai gali būti sunaikinti. Tuomet pagal sklypo
								planą, žemėtvarkos projektą ir teritorijų planavimo dokumentus, geodezininkai atlieka
								riboženklių atstatymą.
							</p>
							<p className="srvs__p">
								Žemės sklypo ribų nužymėjimai atliekami centimetrų tikslumu, inžinerinių statinių
								nužymėjimai - milimetrų tikslumu.
							</p>
						</>
					),
				},
				{
					title: "Kada atliekamas arba reikalingas sklypo ribų nužymėjimas?",
					content: (
						<>
							<p className="srvs__p">
								Sklypo ribų nužymėjimas yra privalomai atliekamas kadastrinių matavimų metu. Jei
								nužymėjimas buvo sunaikintas, tačiau kadastrinių matavimų byla yra, atliekamas
								riboženklių atstatymas.
							</p>
							<p className="srvs__p">
								Sklypo ribų nužymėjimas kartu su statinio pamatų ašių, polių nužymėjimu, yra būtinas
								prieš pradedant vykdyti pastatų, statinių arba inžinerinių tinklų statybą. Dažniausiai
								nužymėjimai vyksta atliekant šiuos darbus:
							</p>
							<List
								elements={[
									"statant civilinius ir pramoninius objektus, įvairius statinius, pastatus,",
									"tiesiant inžinerines vidaus ir lauko komunikacijas,",
									"tiesiant tiltus,",
									"liejant pamatus,",
									"statant tvoras,",
									"keičiant sklypo reljefą.",
								]}
							/>
						</>
					),
				},
				{
					title: "Kokių dokumentų reikia sklypo ribų nužymėjimui?",
					content: (
						<>
							<p className="srvs__p">
								Nužymėjimui vietovėje atlikti, sklypo savininkas geodezininkui turi pateikti
								įregistruotą VĮ Registrų centre žemės sklypo kadastrinių matavimų planą su koordinatėmis
								valstybinėje LKS 94 koordinačių sistemoje. Taip pat reikalinga žemės sklypo teisinė
								registracija (nekilnojamojo turto registro išrašo-pažymėjimo kopija) bei techninis
								projektas statybų metu.
							</p>
						</>
					),
				},
				{
					title: "Sklypų ribų nužymėjimo kainos",
					content: (
						<>
							<p className="srvs__p">
								Sklypo ribų nužymėjimo kaina priklauso nuo to ar reikia atlikti kadastrinius matavimus
								su nužymėjimu ar atstatyti nužymėjimą iš dokumentų. Taip pat, žinoma, nuo riboženklių
								skaičiaus, sklypo dydžio, sklypo konfigūracijos (posūkio taškų), reljefo sudėtingumo ir
								pan.
							</p>
							<p className="srvs__p">
								<WebLink url="/kontaktai">Susisiekime</WebLink> ir Dzūkijos Valdos konsultantai pateiks
								tikslesnę informaciją bei kainą jūsų atveju.
							</p>
						</>
					),
				},
			]}
		/>
	);
};

export default SklypuRibuNuzymejimasVietoveje;

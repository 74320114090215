import _ from "lodash";
import { ObjType, AllObjProps } from "@shared/types/real_estate";

export const typeToTransType: { [k in ObjType]: string } = {
	FLAT: "butai",
	GARAGE: "garazai",
	HOUSE: "namai",
	LAND: "sklypai-zemes",
	LODGING: "patalpos",
	HOMESTEAD: "sodybos",
	GARDEN_HOUSE: "sodai",
};

export const transTypeToType = _.reduce(
	typeToTransType,
	(res, val, key) => {
		const revObj = {};
		revObj[val] = key;
		return {
			...res,
			...revObj,
		};
	},
	{}
) as { [transType: string]: ObjType };

export default function transTypeGen(type: AllObjProps["type"]) {
	const transType = typeToTransType[type];
	if (!type) throw new Error(`Invalid type whose ${type}!`);
	return transType;
}

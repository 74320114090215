import React from "react";
import { Route, Switch, RouteComponentProps, Redirect } from "react-router-dom";
import { urlToTitle, PageUrl, Service } from "@shared/types";
import useGStore from "../logic/store";
import { getIdFromUrl } from "@shared/util/urlUtil";
import MetaWrapper from "@client/common/util/MetaWrapper";
import prepDescr from "@shared/util/prepDescr";
import SiteMap from "../pages/SiteMap/SiteMap";
import Home from "../pages/Home/Home";
import Search from "../pages/Search/Search";
import Prices from "../pages/Prices/Prices";
import ContactUs from "../pages/Contacts/Contacts";
import AboutUs from "../pages/AboutUs/AboutUs";
import Services from "../pages/Services/Services";
import Obj from "../pages/Object/Object";
import { urlToService, servicesInfo } from "@shared/constants/re_services";
import KadastriniaiMatavimai from "../pages/Service/KadastriniaiMatavimai";
import Inventorizacija from "../pages/Service/Inventorizacija";
import EnergetinioNaudingumoSertifikatai from "../pages/Service/EnergetinioNaudingumoSertifikatai";
import PastatuPridavimas from "../pages/Service/PastatuPridavimas";
import ZemesSklypoPaskirtiesKeitimas from "../pages/Service/ZemesSklypoPaskirtiesKeitimas";
import TeritorijuPlanavimas from "../pages/Service/TeritorijuPlanavimas";
import StatinioPamatuAsiuNuzymejimas from "../pages/Service/StatinioPamatuAsiuNuzymejimas";
import SklypuRibuNuzymejimasVietoveje from "../pages/Service/SklypuRibuNuzymejimasVietoveje";
import RibozenkliuAtkurimas from "../pages/Service/RibozenkliuAtkurimas";
import PlanaiTeismui from "../pages/Service/PlanasTeismui";
import NotarinisPlanas from "../pages/Service/NotarinisPlanas";
import TopografineNuotrauka from "../pages/Service/TopografineNuotrauka";
import KaimoPletrosProjektai from "../pages/Service/KaimoPletrosProjektai";
import ZemesSklypuFormavimoProjektai from "../pages/Service/ZemesSklypuFormavimoProjektai";
import PozeminiaiTinklai from "../pages/Service/PozeminiaiTinklai";

const PagesRoutes: React.FC = () => {
	const GS = useGStore();
	return (
		<Switch>
			<Route exact path="/">
				<MetaWrapper title={urlToTitle.HOME.title} descr={urlToTitle.HOME.description}>
					<Home />
				</MetaWrapper>
			</Route>
			<Route path={`${PageUrl.NT_SKELBIMAI}`}>
				<MetaWrapper title={urlToTitle.NT_SKELBIMAI.title} descr={urlToTitle.NT_SKELBIMAI.description}>
					<Search />
				</MetaWrapper>
			</Route>
			<Route
				path={`${PageUrl.NT_TURTAS}/:objUrl`}
				component={(p: RouteComponentProps<{ objUrl: string }>) => {
					const objUrl = p.match.params.objUrl;
					const id = getIdFromUrl(objUrl);
					const obj = GS.objs.all[id];
					if (!id || !obj) {
						return <Redirect to="/" />;
					}
					return (
						<MetaWrapper
							title={obj.title}
							descr={prepDescr(obj.description) || urlToTitle.NT_TURTAS.description}
						>
							<Obj obj={obj} />;
						</MetaWrapper>
					);
				}}
			/>
			<Route exact path={`${PageUrl.NT_PASLAUGOS}`}>
				<MetaWrapper title={urlToTitle.NT_PASLAUGOS.title} descr={urlToTitle.NT_PASLAUGOS.description}>
					<Services />
				</MetaWrapper>
			</Route>
			<Route exact path={`${PageUrl.KAINOS}`}>
				<MetaWrapper title={urlToTitle.KAINOS.title} descr={urlToTitle.KAINOS.description}>
					<Prices />
				</MetaWrapper>
			</Route>
			<Route exact path={`${PageUrl.KONTAKTAI}`}>
				<MetaWrapper title={urlToTitle.KONTAKTAI.title} descr={urlToTitle.KONTAKTAI.description}>
					<ContactUs />
				</MetaWrapper>
			</Route>
			<Route exact path={`${PageUrl.APIE_MUS}`}>
				<MetaWrapper title={urlToTitle.APIE_MUS.title} descr={urlToTitle.APIE_MUS.description}>
					<AboutUs />
				</MetaWrapper>
			</Route>
			<Route exact path={PageUrl.SVETAINES_STRUKTURA}>
				<MetaWrapper title={urlToTitle.SVETAINES_STRUKTURA.title} descr={urlToTitle.HOME.description}>
					<SiteMap />
				</MetaWrapper>
			</Route>
			<Route
				path={`/:serviceUrl`}
				component={(p: RouteComponentProps<{ serviceUrl: string }>) => {
					const serviceUrl = p.match.params.serviceUrl;
					const service = urlToService[serviceUrl];

					if (!service) {
						return null;
					}

					//eslint-disable-next-line
					const ServiceToPage = {
						[Service.GEODEZINIAI_MATAVIMAI]: <KadastriniaiMatavimai />,
						[Service.PASTATU_INVENTORIZACIJA]: <Inventorizacija />,
						[Service.ENERGETINIO_NAUDINGUMO_SERTIFIKATAI]: <EnergetinioNaudingumoSertifikatai />,
						[Service.PASTATU_PRIDAVIMAS]: <PastatuPridavimas />,
						[Service.ZEMES_SKLYPO_PASKIRTIES_KEITIMAS]: <ZemesSklypoPaskirtiesKeitimas />,
						[Service.TERITORIJU_PLANAVIMAS]: <TeritorijuPlanavimas />,
						[Service.STATINIO_PAMATU_ASIU_NUZYMEJIMAS]: <StatinioPamatuAsiuNuzymejimas />,
						[Service.SKLYPU_RIBU_NUZYMEJIMAS_VIETOVEJE]: <SklypuRibuNuzymejimasVietoveje />,
						[Service.RIBOZENKLIU_ATKURIMAS]: <RibozenkliuAtkurimas />,
						[Service.PLANAI_TEISMUI]: <PlanaiTeismui />,
						[Service.NOTARINIS_PLANAS]: <NotarinisPlanas />,
						[Service.TOPOGRAFINIAI_MATAVIMAI]: <TopografineNuotrauka />,
						[Service.KAIMO_PLETROS]: <KaimoPletrosProjektai />,
						[Service.ZEMES_SKLYPU_FORMAVIMO]: <ZemesSklypuFormavimoProjektai />,
						[Service.POZEMINIAI_TINKLAI]: <PozeminiaiTinklai />,
					};

					const { title, metaDescr } = servicesInfo[service];
					return (
						<MetaWrapper title={title} descr={metaDescr}>
							{ServiceToPage[service]}
						</MetaWrapper>
					);
				}}
			/>
		</Switch>
	);
};
export default PagesRoutes;

import { PageUrl } from "@shared/types";
import React from "react";
import Headline from "../../common_comps/Headline";
import WebLink from "../../common_comps/WebLink";
import List from "../Service/Parts/List";

const AboutUs: React.FC<{}> = (p) => {
	return (
		<div className="about side-padding">
			<Headline type={"left"} hType={"h1"} text="Apie mus" />
			<div className="about__cont">
				<div className="about__text-wrap--left">
					<div className="about__text">
						Daugiau nei 15 metų patirties, tūkstančiai hektarų išmatuotos žemės ir platus spektras teikiamų
						paslaugų. Tai tik skaičiai ir teiginiai, kuriuos gali pasakyti kiekvienas. Tačiau užtenka
						paklausinėti Alytuje ir aplink dėl kadastrinių matavimų – garantuotai išgirsite „Dzūkijos valdų“
						pavadinimą. Būtent žmonių pasitikėjimas, rekomendacijos iš lūpų į lūpas suteikia didžiausią
						stimulą nenuleisti pačių , užsikeltos paslaugų kokybės ir darbo greičio kartelės, mokytis,
						žengti koja kojon su naujausiomis technologijomis ir siūlyti kuo platesnį paslaugų spektrą. Mums
						malonu, kuomet galime nuimti sunkią naštą nuo kliento pečių ir viską atlikti nuo A iki Z.
						Jaučiame klientų pasitikėjimą, nes esame gerai išanalizavę rinką, lanksčiai reaguojame į jos
						pokyčius, todėl galime kvalifikuotai patenkinti klientų pageidavimus. Mums svarbu darbus atlikti
						kokybiškai ir laiku, patenkinti užsakovų lūkesčius bei klientų poreikius.
						<h2 className="about__h2">Kodėl rinktis mus?</h2>
						<p className="about__p">
							Daugiausiai dirbame Pietų Lietuvoje kadangi žinome Alytaus apylinkių niuansus, vietoves, čia
							dirbančius ir gyvenančius žmones ir tos žinios dažnai padeda lengviau rasti bendrą kalbą,
							geriau atlikti savo darbą.
						</p>
						<p className="about__p">
							<b>Esame vieninga komanda.</b> Nors klientai dažnai bendrauja su vienu ar dviem
							specialistais, tačiau priklausomai nuo paslaugos, prie jūsų užsakymo gali prisiliesti ir
							visa komanda. Darnus darbas ir supratimas vienas kito iš pusės žodžio leidžia dirbti
							sparčiau ir efektyviau. Glaudus bendradarbiavimas tarp administracijos ir darbuotojų
							užtikrina atliktų darbų kokybę ir spartą.
						</p>
						<p className="about__p">
							<b>Teikiame platų spektrą paslaugų.</b> Kadastriniai žemės ir pastatų matavimai yra paslaugų
							ašis, tačiau šalia jų yra ir begalė kitų, kurias teikiame – pradedant energetiniu
							sertifikavimu, baigiant formavimo-pertvarkymo ir kaimo plėtros projektais. Plačiau apie
							teikiamas paslaugas, skiltyje <WebLink url={PageUrl.NT_PASLAUGOS}>NT Paslaugos</WebLink>
						</p>
						<p className="about__p">
							<b>Nestovime vietoje.</b> 15 metų tai ne vien darbas, tai nuolatinis mokymasis ir
							tobulėjimas. Kiekvienas atliktas darbas kažko išmoko. Kiekviena konferencija, kvalifikacijos
							kėlimo kursai, bendradarbiavimas su kitų sričių specialistais suteikia neįkainojamų žinių,
							kurias mielai panaudojame paslaugų tobulinimui.
						</p>
						<p className="about__p">
							<b>Šiuolaikinės technologijos.</b> Be technologijų negali išsiversti nė viena sritis.
							Kadastriniai matavimai atliekami aukščiausios klasės prietaisais, kurie pasižymi itin
							dideliu tikslumu ir patogumu. Taip pat ir programinė įranga bei kompiuterinės sistemos
							leidžia užtikrinti efektyvų darbą visose paslaugų teikimo etapuose.
						</p>
						<h2 className="about__h2">Viskas vienose rankose</h2>
						<p className="about__p">
							Mūsų patirtis ir klientų atsiliepimai rodo, jog žmonėms daug patogiau, jog viską atlieka
							vieni specialistai, nuo pradžios iki pabaigos. Tuomet įpusėjus kokiam nors darbui, nėra
							rodoma pirštu, jog kažkas prieš tai nepasirūpino dokumentais, pavėlavo ką nors pateikti ar
							nepaminėjo tam tikrų niuansų.
						</p>
						<p className="about__p">
							Būtent dėl to, teikiame kompleksines paslaugas, kuomet viską jūsų prašymu atlieka mūsų
							komanda – nuo dokumentų surinkimo, matavimų atlikimo, planų bei bylų parengimo iki turto
							registravimo ir pan. Esant specifinėms situacijoms, ko negalime atlikti patys, pasitelkiame
							patikimus kolegas.
						</p>
						<p className="about__p">Teikiamos paslaugos:</p>
						<List
							elements={[
								"Žemės sklypų kadastriniai (geodeziniai) matavimai",
								"Namų (pastatų) pridavimas statybų inspekcijai ir statybos užbaigimas.",
								"Topografinių nuotraukų (planų) rengimas",
								"Pastatų kadastriniai matavimai (inventorizacija)",
								"Kaimo plėtros žemėtvarkos projektai ūkininko sodybos vietai parinkti",
								"Energinio (energetinio) naudingumo sertifikatai",
								"Požeminių tinklų ir komunikacijų geodezinės - kontrolinės nuotraukos",
								"Žemės sklypų formavimo ir pertvarkymo projektai",
								"Žemės sklypo paskirties keitimas",
								"Teritorijų planavimas (detalieji planai)",
								"Statinio pamatų ašių, polių nužymėjimas",
								"Sklypų ribų nužymėjimas vietovėje",
								"Riboženklių atkūrimas",
								"Planai teismui",
								"Naudojimosi žemės sklypu tvarkos nustatymo planai (notariniai planas)",
							]}
						/>
						<h2 className="about__h2">Didžiausia vertybė – komanda</h2>
						<p className="about__p">
							Jaunatviškas, kūrybingas ir atsakingas kolektyvas profesionaliai ir kvalifikuotai atstovauja
							klientams, garantuoja jų konfidencialumą. Visus darbuotojus jungia tokie patys
							įsipareigojimai ir stipraus ryšio su kompanija jausmas, kurio dėka jie tampa bendrovės
							sėkmės kūrėjais.
						</p>
						<p className="about__p">
							Patikėtas užduotis stengiamės atlikti operatyviai, profesionaliai, taikydami modernias
							technologijas ir novatoriškus sprendimus kiekviename darbų etape. Didžiausia įmonės vertybė
							- darni specialistų komanda. Mes siekiame, kad bendrovę ir klientus sietų ilgalaikė
							partnerystė, o tai mus įpareigoja ir toliau plėsti savo veiklos geografines ribas bei
							gerinti siūlomų paslaugų kokybę. Mūsų tikslas suteikti klientui aukščiausio lygio paslaugas.
						</p>
					</div>
				</div>
			</div>
		</div>
	);
};

export default AboutUs;

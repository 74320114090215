import React from "react";
import { AllObjPropsC, AllTypes, Flat } from "@shared/types/real_estate";
import * as _ from "lodash";
import urlGen from "@shared/util/urlUtil";
import { observer, useLocalStore } from "mobx-react-lite";
import { Link } from "react-router-dom";
import { transLandPurpose, transInstallationType, transBuildingPurpose } from "@shared/util/translate";
import { enumsTranslate } from "@client/common/util/valueTranslate";

interface Dot {
	active: boolean;
}

interface ObjP {
	obj: AllObjPropsC;
}

const Obj: React.FC<ObjP> = observer((p) => {
	const { obj } = p;
	var sepPrice = obj.price.toLocaleString("fr-FR");
	const LS = useLocalStore(() => ({
		activeImg: 0,
		arrowClick(e: React.MouseEvent) {
			e.preventDefault();
		},
		prevImg(e: React.MouseEvent) {
			this.arrowClick(e);
			this.activeImg = this.activeImg - 1 < 0 ? obj.imgUrls.length - 1 : this.activeImg - 1;
		},
		nextImg(e: React.MouseEvent) {
			this.arrowClick(e);
			this.activeImg = this.activeImg + 1 > obj.imgUrls.length - 1 ? 0 : this.activeImg + 1;
		},
		dotsGen() {
			if (!obj.imgUrls || obj.imgUrls.length === 0) return [];
			const dots: Dot[] = [];
			const maxDots = obj.imgUrls.length > 5 ? 5 : obj.imgUrls.length;

			for (let i = 0; i < maxDots; i++) {
				let isActive;
				if (maxDots === 5) {
					isActive =
						(this.activeImg < 3 && i === this.activeImg) || (this.activeImg >= 3 && i === this.activeImg)
							? true
							: false;
				} else {
					isActive = this.activeImg === i ? true : false;
				}

				dots.push({
					active: isActive,
				});
			}
			return dots;
		},
		featuresGen(object: AllObjPropsC) {
			//totalFloor is omitted, as it always goes together with floor.
			const impFeatures: { [type in AllTypes]: (keyof AllObjPropsC)[] } = {
				FLAT: ["roomCount", "insideSpace", "floor"],
				HOUSE: ["insideSpace", "outsideSpace", "installationType"],
				HOMESTEAD: ["insideSpace", "outsideSpace", "installationType"],
				GARDEN_HOUSE: ["insideSpace", "outsideSpace", "installationType"],
				LODGING: ["insideSpace", "floor"],
				GARAGE: ["insideSpace"],
				LAND: ["outsideSpace", "landPurpose"],
			};

			const featInfo: {
				[k in keyof Partial<AllObjPropsC>]: { iconClass?: string; words: string; translate?: object };
			} = {
				roomCount: {
					iconClass: "icon-bed1",
					words: "Kamb.",
				},
				insideSpace: {
					iconClass: "icon-chart-area1",
					words: "Patalpų plotas, m²: ",
				},
				outsideSpace: {
					iconClass: "icon-border-style-alt1",
					words: "Sklypo plotas, a.: ",
				},
				floor: {
					iconClass: "icon-building1",
					words: "Aukštas:",
				},
				installationType: {
					iconClass: "icon-tag",
					words: "",
					translate: transInstallationType,
				},
				landPurpose: {
					iconClass: "icon-tag",
					words: "Paskirtis: ",
					translate: transLandPurpose,
				},
				buildingPurpose: {
					iconClass: "icon-tag",
					words: "Paskirtis: ",
					translate: transBuildingPurpose,
				},
			};

			const featList = impFeatures[object.type].map((feature) => {
				const isFloorFeat = "floor" in object && feature === "floor";
				const fInfo = featInfo[feature];
				const vals = object[feature];
				let val: string | number | undefined | Array<any>;
				if (!fInfo || !vals || (Array.isArray(vals) && vals.length === 0)) return null;

				if (isFloorFeat) {
					if (object.floor) {
						val = `${(object as Flat).floor}/${(object as Flat).totalFloors}`;
					} else {
						val = (object as Flat).totalFloors;
					}
				} else if (fInfo.translate) {
					const translatedVals = Array.isArray(vals)
						? (vals as Array<any>).map((v) => enumsTranslate(feature, v)).join(", ")
						: enumsTranslate(feature, vals as string);
					val = translatedVals;
				} else {
					val = object[feature] as string;
				}
				return (
					<div key={feature as string}>
						<i className={`obj__i ${fInfo.iconClass}`} />

						<span> {fInfo.words}</span>
						<span>{val}</span>
					</div>
				);
			});

			return featList;
		},
	}));
	const activeImgUrl = obj.imgUrls ? obj.imgUrls[LS.activeImg] : "";

	return (
		<Link to={urlGen(obj)} className={`obj`}>
			<div className="obj__img-wrap" style={{ backgroundImage: `url(${activeImgUrl})` }}>
				<div className="obj__price-box">
					<span className="obj__price">{sepPrice} €</span>
				</div>
				{obj.imgUrls.length > 1 && (
					<>
						<button type="button" onClick={LS.prevImg} className="obj__img-btn obj__img-btn--left">
							<i className="obj__img-arrow icon-chevron-double-left" />
						</button>
						<button type="button" onClick={LS.nextImg} className="obj__img-btn obj__img-btn--right">
							<i className="obj__img-arrow icon-chevron-double-right" />
						</button>

						<div className="obj__img-dots-wrap">
							{LS.dotsGen().map((dot, index) => (
								<div
									key={index}
									className={`obj__img-dot ${dot.active ? "obj__img-dot--active" : void 0}`}
								/>
							))}
						</div>
					</>
				)}
			</div>
			<div className="obj__descr">
				<div className="obj__title">{obj.title}</div>
				<div className="obj__addr">
					{obj.street && (
						<>
							<i className="obj__i icon-map-marker-alt" />
							{obj.street}
						</>
					)}
				</div>
				<div className="obj__features">{LS.featuresGen(obj)}</div>
				{/* <div className="obj__bot-text">{getUploadedBefore(obj.updateDate)}</div> */}
			</div>
		</Link>
	);
});
export default Obj;

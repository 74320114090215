import { servicesInfo } from "@shared/constants/re_services";
import React from "react";
import WebLink from "../../common_comps/WebLink";
import List from "./Parts/List";
import ServiceWithCustomSections from "./ServiceWithCustomSections";

const StatinioPamatuAsiuNuzymejimas: React.FC<{}> = () => {
	return (
		<ServiceWithCustomSections
			title={servicesInfo.STATINIO_PAMATU_ASIU_NUZYMEJIMAS.title}
			descr={
				"Pirmas žingsnis namo statyboje yra statinio pamatų nužymėjimas sklype. Be šio veiksmo statybininkai nežinos kur lieti pamatus. Dzūkijos Valdos kvalifikuoti geodezininkai atlieka statinio pamatų, ašių, polių nužymėjimus ir centimetrų tikslumu nurodys, kur pagal projektą turi vykti statybos."
			}
			sections={[
				{
					title: "Kodėl reikalingas pamatų nužymėjimas?",
					content: (
						<>
							<p className="srvs__p">
								Nesvarbu kokia būtų pastato paskirtis ar dydis, svarbiausia užduotis statybų metu yra
								nenukrypti nuo projekto. Būtent tam reikalingas pastato pamatų ir ašių nužymėjimas. Tai
								pirmas rimtas darbas prieš pradedant darbuotis prie pamatų.
							</p>
							<p className="srvs__p">
								Nužymėjimo metu pagal pateiktus projektinius brėžinius sklype nužymima pastatų bei
								inžinerinių tinklų statybos vieta ir ašys. Matavimų paklaida ne didesnė nei 1 cm.
							</p>
							<p className="srvs__p">
								Pamatų ir pastato ašių sužymėjimas padeda išvengti bet kokių problemų dėl neatitikimo su
								projektu. Dažnai pasitaikanti problema, jei nenužymimi pamatai, yra pastato pastatymas
								per arti kaimynų sklypo ribos. Esama netgi kuriozinių (deja, iš tikro nejuokingų ir
								brangiai kainuojančių) situacijų, kuomet naujai formuojamame gyvenamų namų rajone,
								nenužymėjus sklypo ribų, o vėliau ir pamatų, namas pradėtas statyti ant svetimo sklypo
								dalies.
							</p>
							<p className="srvs__p">
								Priežastys, kodėl turėtumėte kviesti patyrusius geodezininkus atlikti pamatų ir pastato
								ašių nužymėjimo darbus prieš statybas:
							</p>
							<List
								elements={[
									"Padeda statybininkams matyti, kur lieti pamatus, statyti konstrukcijas,",
									"Padeda išvengti neatitikimų su projektu,",
									"Leidžia išvengti nukrypimų ir priartėjimo prie kaimynų sklypo ribos labiau nei leidžia projektas,",
									"Geodezininkai naudoja specialią techniką, užtikrinančią minimalią paklaidą,",
									"Tik atitinkančius projektą statinius galima priduoti statybų inspekcijai.",
								]}
							/>
						</>
					),
				},
				{
					title: "Kokių dokumentų reikia nužymėjimo darbams?",
					content: (
						<>
							<p className="srvs__p">
								Pastatų pamatų nužymėjimui reikalingas techninis projektas ar brėžinys, pagal juose
								pateiktas koordinates vietovėje nužymimos pamatų montavimo vietos ir ašys.
							</p>
							<p className="srvs__p">Reikalingi dokumentai:</p>
							<List
								elements={[
									"žemės sklypo planas,",
									"sklypo teisinė registracija,",
									"statomo pastato projektas.",
								]}
							/>
						</>
					),
				},
				{
					title: "Statinio pamatų ašių, polių nužymėjimo kaina",
					content: (
						<>
							<p className="srvs__p">
								Riboženklių skaičius, pastato dydis, pamatų konfigūracija (posūkio taškai) ir kiti
								niuansai lemia nužymėjimo kainą. Kiekvienu atveju skaičiuojama individualiai.
							</p>
							<p className="srvs__p">
								Konkrečią kainą Dzūkijos Valdos konsultantai pateiks pokalbio metu.
								<WebLink url="/kontaktai">Susisiekime!</WebLink>
							</p>
						</>
					),
				},
			]}
		/>
	);
};

export default StatinioPamatuAsiuNuzymejimas;

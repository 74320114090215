import { servicesInfo } from "@shared/constants/re_services";
import React from "react";
import WebLink from "../../common_comps/WebLink";
import List from "./Parts/List";
import ServiceWithCustomSections from "./ServiceWithCustomSections";

const TeritorijuPlanavimas: React.FC<{}> = () => {
	return (
		<ServiceWithCustomSections
			title={servicesInfo.TERITORIJU_PLANAVIMAS.title}
			descr={
				"Teritorijų planavimas – nuolat vykstantis ir niekada nesibaigiantis procesas. Tam reikalingi detalieji planai, kuriais pasirūpins kvalifikuota Dzūkijos Valdos komanda. Detaliųjų planų sudarymas vyksta atliekant kadastrinius ir geodezinius matavimus, nagrinėjant jau esamus teritorijų detaliuosius planus. "
			}
			sections={[
				{
					title: "Kas yra detalusis planas?",
					content: (
						<>
							<p className="srvs__p">
								Detalusis planas - tai kompleksinis teritorijų planavimo dokumentas, kuriame užfiksuotos
								tiek urbanizuotos, tiek neurbanizuotos teritorijos. Detalusis planas yra teritorijų
								planavimo dokumentas. Tai reiškia, jog jame rodoma ne esama situacija, o planuojama.
								Kitaip tariant reali situacija yra matoma toponuotraukose, tuo tarpu detaliajame plane –
								norima situacija.
							</p>
							<p className="srvs__p">
								Detalusis planas daro įtaką kaip gali būti tvarkomas sklypas ir kas jame gali vykti:
								kokia veikla, kokia paskirtis. Jei detaliajame plane nenumatyta galimybė konkrečioje
								vietoje statyti pastatus, ten ir negalės būti statomi, remiantis detaliuoju planu nebus
								išduodami statybų leidimai.
							</p>
							<p className="srvs__p">
								Detalieji planai rengiami savivaldybės lygmeniu miestams, miesteliams, kvartalams, taip
								pat gali būti rengiami vietovės lygmeniu. Norint pakoreguoti ar pakeisti patvirtinto
								detaliojo plano sprendinius, turi būti rengiamas sklypo ir (arba) teritorijos detalaus
								plano koregavimas arba detalaus plano keitimas. Juose gali būti numatoma didinti
								užstatymą, keisti paskirtį ar naudojimo būdą, perdalinti sklypus ir iš naujo nustatyti
								jų naudojimo reglamentus. Inicijuoti detaliojo plano pakeitimus gali tiek fiziniai ir
								juridiniai asmenys.
							</p>
						</>
					),
				},
				{
					title: "Kokia detaliųjų planų paskirtis?",
					content: (
						<>
							<p className="srvs__p">
								Teritorijų planavimas ir detaliųjų planų rengimas yra aktualus tiek privačių teritorijų,
								tiek ir valstybės žemės sklypų tvarkymui.
							</p>
							<p className="srvs__p">Teritorijų detalieji planai:</p>
							<List
								elements={[
									"leidžia nustatyti naudojimo reglamentus užstatytoms ir numatomoms užstatyti teritorijoms,  ",
									"nurodo optimalų planuojamos teritorijos inžinerinių komunikacinių koridorių tinklą,",
									"numato teritorijas socialinei infrastruktūrai,",
									"nurodo specialiąsias žemės naudojimo sąlygas ir paskirtį,",
									"numato priemones gamtos ir nekilnojamajam kultūros paveldui išsaugoti ir naudoti,",
									"numato teritorijas želdynų plėtrai, priemones jiems atkurti, esamų apsaugai ir naudojimui,",
									"suformuoja optimalią urbanistinę struktūrą.",
								]}
							/>
						</>
					),
				},
				{
					title: "Teritorijų planavimo paslaugos fiziniams ir juridiniams asmenims",
					content: (
						<>
							<p className="srvs__p">
								Vadovaujantis kompleksinio teritorijų planavimo dokumentų rengimo taisyklėmis ir kitais
								teisės aktais, Dzūkijos valdos specialistai atlieka detaliųjų planų rengimą, jų keitimą
								ir koregavimą. Išskiriamos kelios paslaugos, kurios susijusios su teritorijų planavimo
								dokumentais.
							</p>
							<p className="srvs__p">
								<List
									elements={[
										"Žemės sklypų formavimo ir pertvarkymo projektai. Tai žemės sklypų ribų nustatymas projektuojant naujus žemės sklypus, keičiant gretimų sklypų ribas, dalijant arba jungiant žemės sklypus. Naujų gyvenamųjų namų rajonų planavimas bei formavimas.",
										"Amalgamacija. Tai bendros žemės sklypo ribos pakeitimas, kai žemės sklypo dalis atidalijama ir prijungiama prie kito sklypo neformuojant atskirų atidalijamų žemės sklypų.",
										"Kaimo plėtros žemėtvarkos projektai. Tai vietovės lygmens specialiojo planavimo dokumentai, kuriuos reikia parengti siekiant suplanuoti žemės naudmenų sudėties pakeitimą, suplanuoti su žemės naudojimu susijusią veiklą bei suformuoti veiklos subjektų žemėvaldas.",
									]}
								/>
							</p>
						</>
					),
				},
				{
					title: "Teritorijų planavimo ir detaliųjų planų kainos",
					content: (
						<>
							<p className="srvs__p">
								Detaliųjų planų ir teritorijų planavimo kainos būti nuo kelių šimtų iki poros tūkstančių
								eurų, priklausomai nuo to kokių detaliojo plano koregavimų reikia jūsų atveju.
								Pavyzdžiui, ar tai koregavimas keičiant esminius sprendinius, ar jų nekeičiant.
							</p>
							<p className="srvs__p">
								Tikslią detaliųjų planų rengimo paslaugos kainą Dzūkijos Valdos konsultantai pateiks
								pokalbio metu. <WebLink url="/kontaktai">Susisiekime!</WebLink>
							</p>
						</>
					),
				},
			]}
		/>
	);
};

export default TeritorijuPlanavimas;

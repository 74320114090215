import { PageUrl } from "@shared/types/common";
import React from "react";
import { Link } from "react-router-dom";
import Button from "../../common_comps/UI/Button";

interface P {
	title: string;
	descr: string;
	sections: { title: string; content: string | JSX.Element }[];
}
const ServiceWithCustomSections: React.FC<P> = (p) => {
	return (
		<div className="srvs side-padding">
			<div className="srvs__head">
				<i className="geotop__icon icon-geodesy" />
				<h1>{p.title}</h1>
				<p>{p.descr}</p>
			</div>
			<div className="srvs__cont">
				{p.sections.map((section) => (
					<div key={section.title} className="srvs__about">
						<h2 className="srvs__h2">{section.title}</h2>
						<div className="srvs__line" />
						<div className="srvs__about-text">{section.content}</div>
					</div>
				))}
				<div className="srvs__about">
					<Link to={PageUrl.NT_PASLAUGOS}>
						<Button>Grįžti atgal</Button>
					</Link>
				</div>
			</div>
		</div>
	);
};
export default ServiceWithCustomSections;

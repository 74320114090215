import "@web/css/main.scss";

import React from "react";
import ReactDOM from "react-dom";

import * as serviceWorker from "@client/common/util/serviceWorker";
import WebContainer from "./common_comps/WebContainer";
require("cookieconsent");

ReactDOM.render(<WebContainer />, document.getElementById("body"));
serviceWorker.unregister();

(window as any).cookieconsent.initialise({
	container: document.getElementById("cookieconsent"),
	palette: {
		popup: { background: "#50c45b" },
		button: { background: "#e0e0e0" },
	},
	revokable: false,
	theme: "edgeless",
	content: {
		policy: "Privatumo Politika",
		dismiss: "Sutinku",
		link: "",
		showLink: false,
		message:
			"Dėl geresnės tinklapio paslaugų kokybės naudojame slapukus (angl. cookies). Naršydami toliau, patvirtinate, kad sutinkate su slapukais.",
	},
});

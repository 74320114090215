import React from "react";
import { Link } from "react-router-dom";

const WebLink: React.FC<{ url: string }> = (p) => {
	return (
		<Link className="link" to={p.url}>
			{p.children}
		</Link>
	);
};

export default WebLink;

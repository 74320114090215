import allTranslations from "@shared/util/translate";
import { AllObjPropsC } from "@shared/types";
import { capFirst } from "@shared/util/capFirst";

export function enumsTranslate(prop: keyof AllObjPropsC, val: string | number) {
	const cProp = capFirst(prop) as string;
	if (!allTranslations[cProp]) {
		return val;
	}

	const translatedVal = allTranslations[cProp][val];
	if (!translatedVal) {
		console.error(`Couldnt translate prop ${prop} val: ${val}`);
		return val;
	}
	return translatedVal;
}

import $ from "jquery";

export default function scrollToElement($el: JQuery<HTMLElement>) {
	const headerSize = $(".header").height();
	const extraTopSpace = 30;

	$("body, html").animate({
		scrollTop: $el.offset()!.top - headerSize! - extraTopSpace
	});
}

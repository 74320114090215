import React from "react";
import { Helmet } from "react-helmet";

interface P {
	title: string;
	descr?: string;
}
const MetaWrapper: React.FC<P> = (p) => {
	return (
		<>
			<Helmet>
				<title>{p.title}</title>
				{p.descr && <meta name="description" content={p.descr} />}
			</Helmet>
			{p.children}
		</>
	);
};

export default MetaWrapper;

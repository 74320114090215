import { ObjType, BuildingType, HeatingType, InstallationType, Flat, ObjAction } from "./types/real_estate";

export const testObject = {
	publicId: "GABA",
	title: "Objektas",
	imgUrls: ["http://www.dzukuvaldos.lt/images/uploaded_images/WU1556029538W5cbf20621736b.jpg"],
	street: "Kaimo gatvyte 2",
	type: ObjType.FLAT,
	description: "wuw",
	insideSpace: 10,
	price: 100,
	municipality: "Alytaus m. sav",
	city: "Alytus",
	roomCount: 2,
	floor: 3,
	totalFloors: 5,
	buildingYear: 2005,
	action: ObjAction.SELL,
	buildingType: [BuildingType.BLOCK],
	heatingType: [HeatingType.CENTRAL_COLLECTORING],
	installationType: [InstallationType.EQUIPPED],
	imageFiles: [],
	uploadDate: new Date().toISOString(),
	updateDate: new Date().toISOString(),
	outsideSpace: undefined,
	enabled: true,
	mapUrl:
		"https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2305.9961165586637!2d25.215184916015318!3d54.69209598075889!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x46dd93c07d669e03%3A0x9cc26bf99334e94a!2sSausio%2013-osios%20g.%207%2C%20Vilnius%2004344!5e0!3m2!1sen!2slt!4v1586520864733!5m2!1sen!2slt",
} as Flat;

export const testObject1 = {
	title: "Objektas",
	publicId: "BABA",
	imgUrls: [
		"http://www.dzukuvaldos.lt/images/uploaded_images/WA1576049413W5df09b059efb2.jpg",
		"http://www.dzukuvaldos.lt/images/uploaded_images/WA1576049413W5df09b059efb2.jpg",
	],
	street: "Dzuku gatve 9999",
	type: ObjType.FLAT,
	description: "wuw",
	insideSpace: 10,
	price: 100,
	municipality: "Alytaus m. sav",
	city: "Vilnius",
	roomCount: 5,
	floor: 1,
	totalFloors: 3,
	buildingYear: 2010,
	action: ObjAction.RENT,
	buildingType: [BuildingType.BLOCK],
	heatingType: [HeatingType.CENTRAL_COLLECTORING],
	installationType: [InstallationType.EQUIPPED],
	imageFiles: [],
	uploadDate: new Date().toISOString(),
	updateDate: new Date().toISOString(),
	enabled: true,
	mapUrl:
		"https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2305.9961165586637!2d25.215184916015318!3d54.69209598075889!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x46dd93c07d669e03%3A0x9cc26bf99334e94a!2sSausio%2013-osios%20g.%207%2C%20Vilnius%2004344!5e0!3m2!1sen!2slt!4v1586520864733!5m2!1sen!2slt",
} as Flat;

import { servicesInfo } from "@shared/constants/re_services";
import React from "react";
import WebLink from "../../common_comps/WebLink";
import List from "./Parts/List";
import ServiceWithCustomSections from "./ServiceWithCustomSections";

const TopografineNuotrauka: React.FC<{}> = () => {
	return (
		<ServiceWithCustomSections
			title={servicesInfo.TOPOGRAFINIAI_MATAVIMAI.title}
			descr={
				"Topografinės nuotraukos ir jų rengimas yra kasdieninis „Dzūkijos Valdų“ specialistų darbas, kurį visada atliekame maksimaliai kokybiškai, greitai ir už prieinamą kainą. Jau 15 metų dirbame visoje Lietuvoje bei padedame klientams sprendžiant klausimus dėl topografinių nuotraukų -  surenkame archyvinę medžiagą, atliekame matavimus, pateikiame dokumentus ir gauname patvirtinimus ir suderinimus reikiamose institucijose.  "
			}
			sections={[
				{
					title: "Kas yra topografinės nuotraukos (planai)?",
					content: (
						<>
							<p className="srvs__p">
								Topografinės nuotraukos dar vadinamos toponuotraukos, geodezinės nuotraukos,
								topografiniai planai. Topografinė nuotrauka - tai brėžinys (planas), kuriame pavaizduota
								faktinė antžeminė ir požeminė situacija matuojamame žemės sklypo plote. Toponuotraukose
								užfiksuojamas žemės reljefas, statiniai (gyvenamieji ir negyvenamieji), medžiai,
								šuliniai ir antžeminės bei požeminės komunikacijos.
							</p>
							<p className="srvs__p">
								Topografines nuotraukas sudaro specialistas turintis galiojantį geodezininko
								kvalifikacijos pažymėjimą.
							</p>
						</>
					),
				},
				{
					title: "Kam reikalingos topografinės nuotraukos?",
					content: (
						<>
							<p className="srvs__p">
								Bet kokie projektavimo darbai prasideda nuo topografinės nuotraukos. Statinių ir
								pastatų, požeminių bei antžeminių komunikacijų projektavimas, detalieji planai, sklypo
								formavimo ir pertvarkymo projektas gali būti atliekamas ant topografinės nuotraukos,
								kuri padaryta ne seniau nei prieš vienerius metus.
							</p>
							<p className="srvs__p">
								Turint toponuotrauką, privaloma ją suderinti su visomis inžinerinius tinklus
								eksploatuojančiomis organizacijomis bei su savivaldybe ir tuomet galima pradėti
								projektavimo darbus.
							</p>
							<p className="srvs__p">
								Projektuotojas turėdamas topografinę nuotrauką su tiksliais duomenimis apie požeminių ir
								antžeminių objektų padėtį gali parinkti tinkamiausius projektinius sprendinius namų
								statyboms, inžinerinėms komunikacijoms ar sklypų projektavimui. Tiksli informacija
								užfiksuota topografinėje nuotraukoje leidžia minimalizuoti galimą neigiamą poveikį
								gamtai ir sklypo teritorijoje esančiai infrastruktūrai.
							</p>
							<p className="srvs__p">Topografinė nuotrauka yra reikalinga:</p>
							<List
								elements={[
									"Prieš naujo namo statybą ar esamo rekonstrukciją,",
									"Projektuojant naujus inžinerinius tinklus ar rekonstruojant esamus,",
									"Prieš rengiant kaimo plėtros žemėtvarkos projektą",
									"Prieš rengiant formavimo ir pertvarkymo projektą",
									"Prieš rengiant detalujį planą",
								]}
							/>
						</>
					),
				},
				{
					title: "Dokumentai reikalingi darant topografinę nuotrauką",
					content: (
						<>
							<p className="srvs__p">
								Geodezininkui atliekančiam topografinius matavimus bus reikalingi šie dokumentai:
							</p>
							<List elements={["Žemės sklypo planas,", "Nuosavybės dokumentai, "]} />
							<p className="srvs__p">
								Priklausomai nuo konkrečios sklypo situacijos, gali prireikti ir kitų dokumentų.
								„Dzūkijos Valdų“ specialistai informuos apie konkrečius dokumentus ir veiksmus, kurių
								reikės imtis, norint juos gauti.
							</p>
						</>
					),
				},
				{
					title: "Kiek laiko galioja topografinė nuotrauka?",
					content: (
						<>
							<p className="srvs__p">
								Topografinė nuotrauka priklausomai nuo situacijos ir naudojimo tikslo galioja nuo 1 iki
								3 metų. Topografinių planų galiojimo laiką reglamentuoja Statybos techninis reglamentas.
							</p>
							<p className="srvs__p">
								Tačiau nepaisant galiojimo laiko, naują topografinę nuotrauką reikia parengti jei sklype
								esama pakitimų: pastatyti nauji pastatai, privestos inžinerinės komunikacijos, atsirado
								ar buvo panaikinti kiti objektai sklypo teritorijoje. Bet kokie pokyčiai gali turėti
								reikšmingos įtakos projektavimo darbams, todėl būtina nauja toponuotrauka.
							</p>
						</>
					),
				},
				{
					title: "Topografinės nuotraukos kaina",
					content: (
						<>
							<p className="srvs__p">
								Topografinės (geodezinės) nuotraukos kaina prasideda nuo poros šimto eurų ir priklauso
								nuo kelių niuansų susijusių su sklypu, kuriame atliekami matavimai:
							</p>
							<List
								elements={[
									"Matuojamos teritorijos dydis,",
									"Sklypo užstatymo tankumas.",
									"Esamų komunikacijų kiekis.",
									"Išskirtiniais atvejais kainą gali įtakoti papildomi (specifiniai) projektuotojų ar užsakovų reikalavimai.",
								]}
							/>
							<p className="srvs__p">
								Kiekvienu atveju kaina nustatoma individualiai pagal matuojamą sklypą ir užstatymą.
								„Dzūkijos Valdų“ konsultantai pateiks tikslesnę informaciją apie kainas pokalbio arba
								susitikimo metu. <WebLink url="/kontaktai">Susisiekime!</WebLink>
							</p>
						</>
					),
				},
			]}
		/>
	);
};

export default TopografineNuotrauka;

export enum Service {
	GEODEZINIAI_MATAVIMAI = "GEODEZINIAI_MATAVIMAI",
	ENERGETINIO_NAUDINGUMO_SERTIFIKATAI = "ENERGETINIO_NAUDINGUMO_SERTIFIKATAI",
	PASTATU_INVENTORIZACIJA = "PASTATU_INVENTORIZACIJA",
	PASTATU_PRIDAVIMAS = "PASTATU_PRIDAVIMAS",
	POZEMINIAI_TINKLAI = "POZEMINIAI_TINKLAI",
	ZEMES_SKLYPU_FORMAVIMO = "ZEMES_SKLYPU_FORMAVIMO",
	TOPOGRAFINIAI_MATAVIMAI = "TOPOGRAFINIAI_MATAVIMAI",
	KAIMO_PLETROS = "KAIMO_PLETROS",
	ZEMES_SKLYPO_PASKIRTIES_KEITIMAS = "ZEMES_SKLYPO_PASKIRTIES_KEITIMAS",
	TERITORIJU_PLANAVIMAS = "TERITORIJU_PLANAVIMAS",
	STATINIO_PAMATU_ASIU_NUZYMEJIMAS = "STATINIO_PAMATU_ASIU_NUZYMEJIMAS",
	SKLYPU_RIBU_NUZYMEJIMAS_VIETOVEJE = "SKLYPU_RIBU_NUZYMEJIMAS_VIETOVEJE",
	RIBOZENKLIU_ATKURIMAS = "RIBOZENKLIU_ATKURIMAS",
	PLANAI_TEISMUI = "PLANAI_TEISMUI",
	NOTARINIS_PLANAS = "NOTARINIS_PLANAS",
}

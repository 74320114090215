import { Service } from "@shared/types/re_services";
import _ from "lodash";

// to be seo optimized
/* this service type represents also the name of the URL */
export const serviceUrls: { [k in Service]: string } = {
	GEODEZINIAI_MATAVIMAI: "zemes-sklypu-kadastriniai-geodeziniai-matavimai",
	PASTATU_INVENTORIZACIJA: "pastatu-kadastriniai-matavimai-inventorizacija",
	PASTATU_PRIDAVIMAS: "namo-pridavimas-statybu-inspekcijai",
	POZEMINIAI_TINKLAI: "pozeminiu-tinklu-komunikaciju-geodezines-kontrolines-nuotraukos",
	ZEMES_SKLYPU_FORMAVIMO: "zemes-sklypu-formavimo-pertvarkymo-projektai",
	TOPOGRAFINIAI_MATAVIMAI: "topografine-nuotrauka",
	KAIMO_PLETROS: "kaimo-pletros-zemetvarkos-projektas",
	ENERGETINIO_NAUDINGUMO_SERTIFIKATAI: "energinio-naudingumo-sertifikatas",
	ZEMES_SKLYPO_PASKIRTIES_KEITIMAS: "zemes-sklypo-paskirties-keitimas",
	TERITORIJU_PLANAVIMAS: "teritoriju-planavimas",
	STATINIO_PAMATU_ASIU_NUZYMEJIMAS: "statinio-pamatu-asiu-poliu-nuzymejimas",
	SKLYPU_RIBU_NUZYMEJIMAS_VIETOVEJE: "sklypu-ribu-nuzymejimas-vietoveje",
	RIBOZENKLIU_ATKURIMAS: "ribozenkliu-atkurimas",
	PLANAI_TEISMUI: "planas-teismui",
	NOTARINIS_PLANAS: "notarinis-planas",
};

export const urlToService = _.reduce(
	serviceUrls,
	(res, val, key) => {
		const revObj = {};
		revObj[val] = key;
		return {
			...res,
			...revObj,
		};
	},
	{}
) as { [url: string]: Service };

/** same title is used for metaTitle and in page title */
export const servicesInfo: { [k in Service]: { title: string; descr: string; metaDescr: string; url: string } } = {
	GEODEZINIAI_MATAVIMAI: {
		title: "Žemės sklypų kadastriniai (geodeziniai) matavimai",
		metaDescr:
			"Kadastriniai matavimai, Geodeziniai matavimai žemės sklypams. Geodeziniai (kadastriniai) matavimų kaina.",
		descr: "Planuojate pirkti, perrašyti ar parduoti sklypą, statyti namą ar sklypą aptverti tvora, tačiau nedaug ką išmanote apie geodeziją?",
		url: serviceUrls.GEODEZINIAI_MATAVIMAI,
	},
	PASTATU_PRIDAVIMAS: {
		title: "Namų (pastatų) pridavimas statybų inspekcijai ir statybos užbaigimas.",
		metaDescr: "Namo (pastato) pridavimas. Statybos užbaigimas. Namo (pastato) pridavimo kaina.",
		descr: "Namo pridavimas, statybos 100% užbaigimas",
		url: serviceUrls.PASTATU_PRIDAVIMAS,
	},
	TOPOGRAFINIAI_MATAVIMAI: {
		title: "Topografinė nuotrauka (planas)",
		metaDescr:
			"Topografinės, geodezinės nuotraukos, topo nuotraukos, žemės sklypo planai. Topografinė nuotrauka kaina.",
		descr: "Norint pradėti projektavimo darbus.",
		url: serviceUrls.TOPOGRAFINIAI_MATAVIMAI,
	},
	PASTATU_INVENTORIZACIJA: {
		title: "Pastatų kadastriniai matavimai (inventorizacija)",
		metaDescr:
			"Statinių kadastriniai matavimai, Inventorizacija. Kadastrinių matavimų bylos. Inventorizacija kaina. Matininkai.",
		descr: "Statinių kadastriniai matavimai nustato objekto dydį, fizinę būklę, turto vertę, buvimo vietą ir kitus objekto rodiklius",
		url: serviceUrls.PASTATU_INVENTORIZACIJA,
	},
	KAIMO_PLETROS: {
		title: "Kaimo plėtros žemėtvarkos projektai ūkininko sodybos vietai parinkti",
		metaDescr:
			"Kaimo plėtros žemėtvarkos projektų rengimas ūkininko sodybos vietai, miško įveisimui, rekreacinės paskirties pastatų vietai, pagalbinio ūkio pastatų, kaina",
		descr: "Specialojo planavimo dokumentai, kurių reikia norint atlikti žemės naudmenų planavimą, sudėties pakeitimą ar kitokią su žemės naudojimu susijusią veiklą.",
		url: serviceUrls.KAIMO_PLETROS,
	},
	ENERGETINIO_NAUDINGUMO_SERTIFIKATAI: {
		title: "Energinio (energetinio) naudingumo sertifikatai",
		metaDescr: "Pastatų (namų) energinio naudingumo klasės nustatymas. Energetinio naudingumo sertifikato kaina.",
		descr: "Pastatų (namų) energinio naudingumo klasės nustatymas",
		url: serviceUrls.ENERGETINIO_NAUDINGUMO_SERTIFIKATAI,
	},
	POZEMINIAI_TINKLAI: {
		title: "Požeminių tinklų ir komunikacijų geodezinės - kontrolinės nuotraukos",
		metaDescr: "Kontrolinės nuotraukos. Komunikacijų geodezinės nuotraukos. Požeminių tinklų nuotraukos.",
		descr: "Požeminių tinklų ir komunikacijų matavimai valstybinėje koordinačių sistemoje.",
		url: serviceUrls.POZEMINIAI_TINKLAI,
	},
	ZEMES_SKLYPU_FORMAVIMO: {
		title: "Žemės sklypų formavimo ir pertvarkymo projektai",
		metaDescr:
			"Žemės sklypo paskirties keitimas, Žemės formavimo pertvarkymo projektai, Žemėtvarkos projektų formavimas, rengimas, Žemėtvarkos projektai, Žemės sklypų padalijimas, sujungimas, kaina",
		descr: "Žemės sklypų sujungimas, dalinimas.",
		url: serviceUrls.ZEMES_SKLYPU_FORMAVIMO,
	},
	ZEMES_SKLYPO_PASKIRTIES_KEITIMAS: {
		title: "Žemės sklypo paskirties keitimas",
		metaDescr: "Pagrindinės žemės naudojimo paskirties keitimas",
		descr: "Pagrindinės žemės naudojimo paskirties keitimas",
		url: serviceUrls.ZEMES_SKLYPO_PASKIRTIES_KEITIMAS,
	},
	TERITORIJU_PLANAVIMAS: {
		title: "Teritorijų planavimas (detalieji planai)",
		metaDescr: "Teritorijų planavimas (detalieji planai)",
		descr: "Teritorijų planavimas (detalieji planai)",
		url: serviceUrls.TERITORIJU_PLANAVIMAS,
	},
	STATINIO_PAMATU_ASIU_NUZYMEJIMAS: {
		title: "Statinio pamatų ašių, polių nužymėjimas",
		metaDescr: "Statinio pamatų ašių, polių nužymėjimas",
		descr: "Statinio pamatų ašių, polių nužymėjimas",
		url: serviceUrls.STATINIO_PAMATU_ASIU_NUZYMEJIMAS,
	},
	SKLYPU_RIBU_NUZYMEJIMAS_VIETOVEJE: {
		title: "Sklypų ribų nužymėjimas vietovėje",
		metaDescr: "Sklypų ribų nužymėjimas vietovėje",
		descr: "Sklypų ribų nužymėjimas vietovėje",
		url: serviceUrls.SKLYPU_RIBU_NUZYMEJIMAS_VIETOVEJE,
	},
	RIBOZENKLIU_ATKURIMAS: {
		title: "Riboženklių atkūrimas",
		metaDescr: "Riboženklių atkūrimas",
		descr: "",
		url: serviceUrls.RIBOZENKLIU_ATKURIMAS,
	},
	PLANAI_TEISMUI: {
		title: "Planas teismui",
		metaDescr: "Planas teismui",
		descr: "",
		url: serviceUrls.PLANAI_TEISMUI,
	},
	NOTARINIS_PLANAS: {
		title: "Naudojimosi žemės sklypu tvarkos nustatymo planas (notarinis planas)",
		metaDescr: "Notarinis planas",
		descr: "",
		url: serviceUrls.NOTARINIS_PLANAS,
	},
};

import { AllObjPropsC } from "@shared/types";

export default function perPriceString(obj: AllObjPropsC) {
	const insideSpace = !!obj.insideSpace;
	const space = insideSpace ? obj["insideSpace"] : obj["outsideSpace"];
	if (!space) return;
	const perSpaceUnit = insideSpace ? "m²" : "a";
	let price = obj.price / space;
	price = price < 100 ? +price.toFixed(2) : Math.floor(price);
	const perPriceString = `${price} € / ${perSpaceUnit}`;
	return perPriceString;
}

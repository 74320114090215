import React from "react";
import MaskedInput, { MaskedInputProps } from "react-text-mask";

interface InpP extends MaskedInputProps {
	iconClass?: string;
	className?: string;
}
const Input: React.FC<InpP> = (p) => {
	const { iconClass, className, ...inpAttr } = p;
	const classes = ["srchbox__inp"];
	if (p.className) classes.push(p.className);
	const content = (
		<>
			<i className={iconClass} />
			{inpAttr.mask !== undefined ? (
				<MaskedInput
					className={classes.join(" ")}
					{...inpAttr}
					id={inpAttr.id || inpAttr.placeholder}
					aria-label={inpAttr.placeholder}
				/>
			) : (
				<input
					className={classes.join(" ")}
					{...inpAttr}
					id={inpAttr.placeholder}
					aria-label={inpAttr.placeholder}
				/>
			)}
		</>
	);
	const wrapper = inpAttr.id ? content : <label htmlFor={inpAttr.placeholder as string}>{content}</label>;

	return (
		<div className="srchbox__inp-box">
			<div className="srchbox__inp-wrap">{wrapper}</div>
		</div>
	);
};

export default Input;

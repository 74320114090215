import getYearCount from "@shared/util/getYearCount";
import React from "react";

interface P {
	title: string;
	description: string;
	backgroundImg: string;
}

const TopBackground: React.FC<P> = (p) => {
	return (
		<div id="top-carousel">
			{/* <div className="top-bg" /> */}
			<div className="cover-img"></div>
			<div className="text-wrapper">
				<span className="up-text">{getYearCount()}m. patirtis: kokybė ir platus paslaugų spektras.</span>
				<h1>{p.title}</h1>
				<h2>{p.description}</h2>
			</div>
		</div>
	);
};
export default TopBackground;

import { servicesInfo } from "@shared/constants/re_services";
import getYearCount from "@shared/util/getYearCount";
import React from "react";
import WebLink from "../../common_comps/WebLink";
import List from "./Parts/List";
import ServiceWithCustomSections from "./ServiceWithCustomSections";

const PlanasTeismui: React.FC<{}> = () => {
	return (
		<ServiceWithCustomSections
			title={servicesInfo.PLANAI_TEISMUI.title}
			descr={
				"Jei kyla konfliktas su bendraturčiais dėl valdomos sklypo ribos ar kyla nuosavybės ribos su kaimynais neaiškumai, sprendžiami teismuose, patyrę Dzūkijos Valdos matininkai kruopščiai išmatuos jūsų žemės sklypo ar kito turto ribas ir pateiks tikslius ir nešališkus atsakymus teismui, pagrįstus dokumentais bei brėžiniais."
			}
			sections={[
				{
					title: "Kas yra planas teismui?",
					content: (
						<>
							<p className="srvs__p">
								Kuomet nesutarimai dėl žemės ribų, pasidalinimo, servitutų ar kitų su žemės sklypu
								susijusių niuansų, pasiekia teismą, reikalingi ne žodžiai, o dokumentai.
							</p>
							<p className="srvs__p">
								Planas teismui arba kitaip naudojimosi žemės sklypu tvarkos nustatymo planas – tai
								sudarytas žemės sklypo ar statinių jame planas, kuriame išsprendžiamas racionalus turto
								dalių tvarkymo ir naudojimo reglamentas. Toks brėžinys ir planas pateikiamas teismui ir
								teismo sprendimu yra patvirtinamas.
							</p>
							<p className="srvs__p">
								Teismui reikalinga nešališka ir tiksli informacija, nepaliekanti vietos
								interpretacijoms. Todėl kreipiantis į teismą reikia pateikti aktualų planą, dabartinę
								sklypo ar statinių sklype bei patekimo prie jų situaciją brėžiniuose ir juos lydinčiuose
								dokumentuose.
							</p>
							<p className="srvs__p">
								Planus teismui gali paruošti tik kvalifikaciją turintys geodezininkai.
							</p>
						</>
					),
				},
				{
					title: "Kokių dokumentų reikia paruošti planą teismui?",
					content: (
						<>
							<p className="srvs__p">
								Ruošiantis teismui greičiausiai reikės aktualaus, dabartinę situaciją atspindinčio
								sklypo plano, tad bus reikalingi matavimai. O norint parengti naudojimosi žemės sklypu
								tvarkos nustatymo planą, reikės ir šių dokumentų:
							</p>
							<List
								elements={[
									"Ruošiantis teismui greičiausiai reikės aktualaus, dabartinę situaciją atspindinčio sklypo plano, tad bus reikalingi matavimai. O norint parengti naudojimosi žemės sklypu tvarkos nustatymo planą, reikės ir šių dokumentų:",
									"Jei sklype yra statinių, tuomet statinių išdėstymo planas – schema.",
									"NT registro išrašo",
								]}
							/>
						</>
					),
				},
				{
					title: "Kuo planas teismui skiriasi nuo notarinio plano?",
					content: (
						<>
							<p className="srvs__p">
								Naudojimosi žemės sklypu tvarkos nustatymo planai, dar kitaip planai teismui arba
								notariniai planai, yra reikalingi siekiant nustatyti sklypų ribas, nurodyti sklypo dalis
								bendraturčiams, nustatyti servitutus ir pan.
							</p>
							<p className="srvs__p">
								Tiek notariniai planai, tiek planai teismui rengiami iš principo panašiai, tačiau
								kadangi teikiami skirtingoms institucijoms (notarui arba teismui), jie adresuojami ir
								vadinami skirtingai.
							</p>
							<p className="srvs__p">
								Žinoma, skiriasi ir pati situacija. Teismo keliu paprastai sprendžiami ginčai, kurių
								nepavyksta spręsti taikiu keliu. O į notarus kreipiamasi tik kai visos suinteresuotos
								šalys sutaria dėl sklypo naudojimosi tvarkos ir plano ir nori tik jį patvirtinti.
							</p>
						</>
					),
				},
				{
					title: "Plano teismui kaina",
					content: (
						<>
							<p className="srvs__p">
								Dzūkijos Valdos specialistai per {getYearCount()} darbo metų parengė ne vieną planą
								teismui, todėl žinome visą proceso tvarką ir teismų specifiką, todėl galime išvaduoti
								jus iš dokumentų rinkimo maratono bei parengti nešališką ir dokumentais bei faktais
								paremtą planą.
							</p>
							<p className="srvs__p">
								<WebLink url="/kontaktai">Susisiekime</WebLink> ir aptarsime visas svarbias detales!
							</p>
						</>
					),
				},
			]}
		/>
	);
};

export default PlanasTeismui;

import React from "react";

type HType = "h1" | "h2" | "h3";
interface HeadingP {
	type: HType;
	green?: boolean;
	lessMargin?: boolean;
}
export const Heading: React.FC<HeadingP> = (p) => {
	const classes: string[] = ["headline__text"];
	if (p.green) classes.push("text-g");
	if (p.lessMargin) classes.push("headline--lm");

	if (p.type === "h1") {
		return <h1 className={classes.join(" ")}>{p.children}</h1>;
	} else if (p.type === "h2") {
		return <h2 className={classes.join(" ")}>{p.children}</h2>;
	} else if (p.type === "h3") {
		return <h3 className={classes.join(" ")}>{p.children}</h3>;
	} else {
		throw new Error("Invalid heading type;");
	}
};

interface P {
	text?: string;
	id?: string;
	type?: "center" | "left" | "none";
	iconClass?: string;
	hType: HType;
	className?: string;
}
const Headline: React.FC<P> = (p) => {
	const classes: string[] = [];
	if (p.className) classes.push(p.className);

	return (
		<div id={p.id || ""} className={`headline headline--${p.type || "center"} ${classes.join(", ")}`}>
			{p.iconClass && <i className={`${p.iconClass} headline__green-icon`} />}
			<Heading type={p.hType}>{p.text || p.children}</Heading>
		</div>
	);
};

export default Headline;

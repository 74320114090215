const miestai = [
	"Kaunas",
	"Klaipėda",
	"Šiauliai",
	"Panevėžys",
	"Alytus",
	"Marijampolė",
	"Mažeikiai",
	"Jonava",
	"Utena",
	"Kėdainiai",
	"Tauragė",
	"Telšiai",
	"Ukmergė",
	"Visaginas",
	"Plungė",
	"Kretinga",
	"Gargždai",
	"Šilutė",
	"Radviliškis",
	"Palanga",
	"Biržai",
	"Rokiškis",
	"Kuršėnai",
	"Druskininkai",
	"Elektrėnai",
	"Garliava",
	"Jurbarkas",
	"Raseiniai",
	"Vilkaviškis",
	"Lentvaris",
	"Joniškis",
	"Grigiškės",
	"Anykščiai",
	"Kelmė",
	"Varėna",
	"Prienai",
	"Kaišiadorys",
	"Naujoji Akmenė",
	"Pasvalys",
	"Kupiškis",
	"Zarasai",
	"Skuodas",
	"Kazlų Rūda",
	"Širvintos",
	"Molėtai",
	"Šalčininkai",
	"Šakiai",
	"Švenčionėliai",
	"Pabradė",
	"Kybartai",
	"Ignalina",
	"Šilalė",
	"Nemenčinė",
	"Pakruojis",
	"Švenčionys",
	"Trakai",
	"Vievis",
	"Kalvarija",
	"Lazdijai",
	"Rietavas",
	"Žiežmariai",
	"Eišiškės",
	"Ariogala",
	"Neringa",
	"Šeduva",
	"Birštonas",
	"Venta",
	"Akmenė",
	"Tytuvėnai",
	"Rūdiškės",
	"Vilkija",
	"Pagėgiai",
	"Viekšniai",
	"Žagarė",
	"Ežerėlis",
	"Skaudvilė",
	"Gelgaudiškis",
	"Kudirkos Naumiestis",
	"Simnas",
	"Salantai",
	"Linkuva",
	"Ramygala",
	"Priekulė",
	"Veisiejai",
	"Daugai",
	"Joniškėlis",
	"Jieznas",
	"Obeliai",
	"Varniai",
	"Virbalis",
	"Seda",
	"Vabalninkas",
	"Subačius",
	"Baltoji Vokė",
	"Pandėlys",
	"Dūkštas",
	"Užventis",
	"Dusetos",
	"Kavarskas",
	"Smalininkai",
	"Troškūnai",
	"Panemunė",
];

function wordProc(word) {
	let ending, toCut;
	const last2L = word.slice(-2);
	const lastL = word.slice(-1);

	if (last2L === "us") {
		ending = "je"; // alytus:alytuje, vilnius:vilniuje
		toCut = 1;
	} else if (last2L === "ai") {
		ending = "uose"; // daugai:dauguose,
		toCut = 2;
	} else if (last2L === "as") {
		//simnas:simne
		toCut = 2;
		ending = "e";
	} else if (last2L === "is") {
		toCut = 2;
		ending = "yje";
	} else if (lastL === "ė") {
		//alovė:alovėje
		toCut = 0;
		ending = "je";
	} else if (lastL === "a") {
		// varena:varenoje, dzukija:dzukijoje
		toCut = 1;
		ending = "oje";
	} else if (lastL === "s") {
		toCut = 0;
		//domantonys:domantonyse, miroslavas:miroslave
		ending = "e";
	} else if (lastL === "i") {
		toCut = 1;
		ending = "joje";
	} else {
		//console.error("was not translated: " + word);
		return word;
	}

	const newS = word.substring(0, word.length - toCut) + ending;
	return newS;
}

export default function wordsEnding(s: string) {
	const words = s.split(" ");
	const newWords = words.map(wordProc);
	const newS = newWords.join(" ");
	return newS;
}

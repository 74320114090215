import React from "react";
import { Router, withRouter, RouteComponentProps } from "react-router-dom";
import { hot } from "react-hot-loader/root";
import { history } from "@web/src/logic/store";
import ScrollToTop from "@client/common/util/ScrollToTop";
import StickyContactBtn from "./StickyContactBtn";
import Header from "./Header";
import Footer from "./Footer";
import PagesRoutes from "./PagesRoutes";

const WebContainer: React.FC<{}> = (p) => {
	return (
		<Router history={history}>
			<ContentWithRoutes />
			<ScrollToTop />
		</Router>
	);
};

const WebContent: React.FC<RouteComponentProps> = (p) => {
	const extraClasses: string[] = [];
	const isMainPage = p.location.pathname === "/";

	if (isMainPage) extraClasses.push("page_main");

	return (
		<div className={`content_wrapper ${extraClasses.join(", ")}`}>
			<Header mod={isMainPage ? undefined : "green"} />
			<div className="page_content">
				<PagesRoutes />
			</div>
			<StickyContactBtn />
			<Footer />
		</div>
	);
};
const ContentWithRoutes = withRouter(WebContent);

export default hot(WebContainer);

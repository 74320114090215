import React, { HTMLAttributes } from "react";

interface OptionGroupP {
	title: string;
	labelFor?: string;
	attrs?: HTMLAttributes<HTMLDivElement>;
}
const OptionGroup: React.FC<OptionGroupP> = (p) => {
	return (
		<div {...p.attrs} className="srchbox__opt-group">
			<div className="srchbox__opt-group-title">{p.title}</div>
			<div className="srchbox__opt-group-content">{p.children}</div>
		</div>
	);
};
export default OptionGroup;

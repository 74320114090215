import _ from "lodash";
import {
	transObjType,
	transObjAction,
	transInstallationType,
	transHeatingType,
	transBuildingType,
	transHouseType,
	transLandPurpose,
	transGarageType,
	transLandFeatures,
	transGarageFeatures,
	transBuildingPurpose,
} from "@shared/util/translate";
import { DropdownOptions } from "../UI/Dropdown";

export function mapForDropdown<T extends string>(
	obj: Record<T, string> | Array<any>,
	rewrites?: object
): DropdownOptions<T> {
	let mapped: {} | DropdownOptions<T> = {};
	if (Array.isArray(obj)) {
		//implement icons and rewrites if needed.
		obj.forEach((v) => {
			mapped[v] = {
				title: v,
				value: v,
			};
		});
	} else {
		mapped = _.mapValues(obj, (title, key) => {
			let finalTitle = title;
			let iconClass: string | undefined;

			const rewrite = rewrites && rewrites[key];
			if (rewrite) {
				if (rewrite.iconClass) {
					finalTitle = rewrite.title;
					iconClass = rewrite.iconClass;
				} else {
					finalTitle = rewrite.title;
				}
			}

			return {
				title: finalTitle,
				value: key,
				iconClass,
			};
		});
	}
	return mapped as DropdownOptions<T>;
}

export const reTypes = mapForDropdown(transObjType);

export const actionTypes = mapForDropdown(transObjAction);

export const installationTypes = mapForDropdown(transInstallationType);

export const heatingTypes = mapForDropdown(transHeatingType);

export const buildingTypes = mapForDropdown(transBuildingType);

export const houseTypes = mapForDropdown(transHouseType);

export const buildingPurposeTypes = mapForDropdown(transBuildingPurpose);

export const landPurposeTypes = mapForDropdown(transLandPurpose);

export const garageTypes = mapForDropdown(transGarageType);

export const garageFeatures = mapForDropdown(transGarageFeatures);

export const landFeatureTypes = mapForDropdown(transLandFeatures);

import getYearCount from "@shared/util/getYearCount";
import _ from "lodash";

//export type Specific<T extends string> = { "~brand": T } & string;
export type RecursivePartial<T> = { [P in keyof T]?: RecursivePartial<T[P]> };
export type Nullable<T> = { [P in keyof T]: T[P] | null };

export type PickPartial<T, K extends keyof T> = {
	[P in K]?: T[P];
};
export type PickPartialArrayify<T, K extends keyof T> = {
	[P in K]?: T[P][];
};

export enum Page {
	HOME = "HOME",
	NT_SKELBIMAI = "NT_SKELBIMAI",
	NT_TURTAS = "NT_TURTAS",
	KAINOS = "KAINOS",
	KONTAKTAI = "KONTAKTAI",
	APIE_MUS = "APIE_MUS",
	NT_PASLAUGOS = "NT_PASLAUGOS",
	SVETAINES_STRUKTURA = "SVETAINES_STRUKTURA",
}

export const PageUrl: { [k in Page]: string } = {
	HOME: "/",
	NT_SKELBIMAI: "/nekilnojamo-turto-skelbimai",
	NT_PASLAUGOS: "/matavimai-planai-projektai-sertifikatai",
	KAINOS: "/kainos",
	APIE_MUS: "/apie-mus",
	KONTAKTAI: "/kontaktai",
	NT_TURTAS: "/nekilnojamas-turtas",
	SVETAINES_STRUKTURA: "/svetaines-struktura",
};

export const UrlToPage = _.reduce(
	PageUrl,
	(res, val, key) => {
		const revObj = {};
		revObj[val] = key;
		return {
			...res,
			...revObj,
		};
	},
	{}
) as { [url: string]: Page };

// recommendations: 60 chars for title, 160 for description.
export const urlToTitle: { [k in Page]: { title: string; description: string } } = {
	HOME: {
		title: "NT agentūra Alytuje | Kadastriniai (Geodeziniai) matavimai",
		description:
			"Nekilnojamo turto agentūra Alytuje, Kadastriniai (Geodeziniai) matavimai, Topografinės nuotraukos, Namo pridavimas, Matininkai, Kaimo plėtros projektai, NT Brokeris",
	},
	NT_SKELBIMAI: {
		title: "NT skelbimai Alytuje, Alytaus raj. ir visoje pietų Lietuvoje",
		description:
			"Nekilnojamas turtas: Butai, Sodai, Namai, Sodybos, Patalpos, Sklypai, Garažai, Alytuje, Alytaus raj., Druskininkų raj., Lazdijų raj., Varėnos raj., Prienų raj.",
	},
	NT_PASLAUGOS: {
		title: "Geodeziniai (Kadastriniai) matavimai, Topografinės nuotraukos, Matininkai",
		description:
			"Geodeziniai (Kadastriniai) matavimai, Topografija, Namo pridavimas, Kaimo plėtros projektai, Inventorizacija, Teritorijų planavimas, Sklypo paskirties keitimas, Sklypo formavimas",
	},
	KAINOS: {
		title: `Geodezinių (kadastrinių) matavimų kaina | NT agentūra Alytuje`,
		description: `Norite sužinoti Geodezinių (kadastrinių) matavimų kaina? ${getYearCount()} Metų patirties atliekant geodezinius (kadastrinius) matavimus. Susisiekite!`,
	},
	APIE_MUS: {
		title: `NT agentūra Alytuje, Matininkai, NT brokeris`,
		description: `NT agentūra Alytuje turinti ${getYearCount()} metų patirtį su NT pardavimu`,
	},
	KONTAKTAI: {
		title: "Norite sužinoti Geodezinių (Kadastrinių) matavimų kainą? Norite parduoti NT? Susisiekite!",
		description:
			"Norite sužinoti Geodezinių (Kadastrinių) matavimų kaina? Ieškote NT brokerio, matininkų ar konsultacijos? Visos NT paslaugos vienoje vietoje. Susisiekite!",
	},
	NT_TURTAS: {
		title: "NT skelbimai Alytuje, Alytaus raj. ir visoje pietų Lietuvoje",
		description:
			"Nekilnojamas turtas Alytaus rajone ir Alytuje. Butai, Namai, Patalpos, Sodai, Sodybos, Garažai, Sklypai - Pirkimas, Pardavimas, Nuoma.",
	}, // this one is not used as its generated from the object title dynamically, unless just as default fallback in case something goes wrong
	SVETAINES_STRUKTURA: {
		title: "Dzūkijos Valdos | Svetainės struktūra",
		description:
			"NT agentūra Alytuje, Kadastriniai (Geodeziniai) matavimai, Topografinės nuotraukos, Namo pridavimas, Matininkai, Kaimo plėtros projektai, NT Brokeris",
	},
};

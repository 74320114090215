import { AllObjProps, ObjType, UrlProps } from "../types/real_estate";
import { cityToWhere } from "@shared/util/citiesWhere";
import wordEnding from "@cUtil/wordEnding";
import latinize from "latinize";
import { PageUrl } from "@shared/types/common";
import { typeWhoseToType } from "./typeWhose";
import transTypeGen, { transTypeToType } from "./objectType";

export function typeWhoseToTypeGen(typeWhose: string): ObjType {
	const type = typeWhoseToType[typeWhose];
	if (!type) throw new Error(`Invalid type whose ${typeWhose}!`);
	return type;
}

export function transTypeToTypeGen(transType: string) {
	const type = transTypeToType[transType];
	if (!type) throw new Error(`Invalid transType ${transType}!`);
	return type;
}

export function cityGen(city: string) {
	const ended = cityToWhere[city] || wordEnding(city);
	const normalized = normalize(ended);
	return normalized;
}

function normalize(s: string): string {
	const latinized = latinize(s);
	return latinized
		.trim()
		.toLowerCase()
		.replace(/ /g, "-")
		.replace("g.", "gatveje")
		.replace("r.", "rajone")
		.replace("k.", "kaime");
}

function microRegionGen(microRegion: NonNullable<AllObjProps["microRegion"]>) {
	const ended = wordEnding(microRegion);
	const normalized = normalize(ended);
	return normalized;
}

function streetGen(street: NonNullable<AllObjProps["street"]>) {
	const numsRemoved = street.replace(/[0-9]/g, "");
	const normalized = normalize(numsRemoved);
	return normalized;
}

export function municipalityGen(munic: NonNullable<AllObjProps["municipality"]>) {
	const normalized = normalize(munic);
	const fullied = normalized.replace("-r-", "rajone");
	return fullied;
}

// function titleGen(title: AllObjProps["title"]) {
// 	return title.toLowerCase().replace(/ /g, "-");
// }

export function getIdFromUrl(objUrl: string): string {
	const publicId = objUrl.split("_").pop()!;
	if (!publicId) throw new Error("Invalid publicId parsed from url!");
	return publicId;
}

function clearDashes(s: string): string {
	if (s.slice(-1) === "-") s = s.slice(0, -1);
	if (s.slice(-1) === "-") return clearDashes(s);
	return s;
}

export default function genUrlFromObject(obj: UrlProps): string {
	let url = "";
	url += `${PageUrl.NT_TURTAS}/`;
	url += transTypeGen(obj.type);
	url += "-";
	if (obj.city) url += cityGen(obj.city) + "-";
	if (!obj.city && obj.municipality) url += municipalityGen(obj.municipality) + "-";
	if (obj.microRegion) url += microRegionGen(obj.microRegion) + "-";
	if (obj.street) url += streetGen(obj.street) + "-";
	url = clearDashes(url);
	url += "_";
	url += obj.publicId;

	return url;
}

import React from "react";
import { observer, useLocalStore } from "mobx-react-lite";

const LoadAfter = observer((p) => {
	const LS = useLocalStore(() => ({
		load: (window as any).wasLoaded || false,
	}));

	window.addEventListener("load", () => {
		LS.load = true;
		(window as any).wasLoaded = true;
	});

	if (!LS.load) return null;

	return <>{p.children}</>;
});
export default LoadAfter;

import React from "react";
import htmlParser from "html-react-parser";

interface P {
	svgText: string;
	title?: string;
	className?: string;
}

const SvgInlined: React.FC<P> = (p) => {
	return (
		<>
			<span className={p.className || ""}>{htmlParser(p.svgText)}</span>
		</>
	);
};
export default SvgInlined;

import { servicesInfo, serviceUrls } from "@shared/constants/re_services";
import React from "react";
import WebLink from "../../common_comps/WebLink";
import List from "./Parts/List";
import ServiceWithCustomSections from "./ServiceWithCustomSections";

const ZemesSklypoPaskirtiesKeitimas: React.FC<{}> = () => {
	return (
		<ServiceWithCustomSections
			title={servicesInfo.ZEMES_SKLYPO_PASKIRTIES_KEITIMAS.title}
			descr={
				"Žemės sklypo paskirties keitimas reikalingas iškilus poreikiui vykdyti veiklą ar atlikti statybos darbus ten, kur žemės sklypas neatitinka reikiamos paskirties. Todėl, jei norite pakeisti pagrindinę žemės sklypo naudojimo paskirtį ir naudojimo būdą neeikvodami savo brangaus laiko, patikėkite šį darbą Dzūkijos Valdos matininkams."
			}
			sections={[
				{
					title: "Kodėl reikalingas žemės sklypo paskirties keitimas?",
					content: (
						<>
							<p className="srvs__p">
								Kiekvienas žemės sklypas turi tam tikrą pagrindinę naudojimo paskirtį ir naudojimo būdą,
								kuriuos reglamentuoja LR įstatymai. Dažniausiai susiduriama su tokiomis žėmės sklypo
								paskirtimis(naudojimo būdai):
							</p>
							<p className="srvs__p">
								<List
									elements={[
										"Žemės ūkio paskirties žemė",
										"Miškų ūkio paskirties žemė",
										"Vandens ūkio paskirties žemė",
										"Konservacinės paskirties žemė",
										"Kitos paskirties žemė (įskaitant gyvenamosios paskirties).",
									]}
								/>
							</p>
							<p className="srvs__p">
								Žemės sklypo paskirtis yra nurodoma nuosavybės dokumentuose, „Registrų centro“
								nekilnojamo turto pažymoje. Paskirtis ir naudojimo būdas apibrėžia ką tame sklype galima
								daryti, ką statyti ir kas yra draudžiama.
							</p>
							<p className="srvs__p">
								Norint atlikti veiksmus, kurie nesuderinami su esama sklypo paskirtimi ir naudojimo
								būdu, reikalingas žemės sklypo paskirties keitimas. Pavyzdžiui, įsigijote žemės ūkio
								paskirties sklypą, o norite pasistatyti namą – tuomet jums reikia pakeisti paskirtį į
								„Kitos paskirties žemė“ bei būdas „Vienbučių ir dvibučių gyvenamųjų pastatų
								teritorijos“.
							</p>
							<p className="srvs__p">
								Sprendžiant galimybes pakeisti naudojimo paskirtį, svarbų vaidmenį vaidina teritorijų
								planavimo dokumentas ar žemės valdos projektas, taigi verta panagrinėti savivaldybės
								bendrąjį planą. Kartais vienam žemės sklypui gali būti nustatyta daugiau nei viena
								naudojimo paskirtis. O kartais gali būti neįmanoma pakeisti sklypo paskirties.
							</p>
						</>
					),
				},
				{
					title: "Ar galima pakeisti žemės sklypo paskirtį?",
					content: (
						<>
							<p className="srvs__p">
								Deja, žemės sklypo paskirtį pakeisti galima ne visada. Todėl pirmiausia galvojant, jog
								reikės keisti paskirtį, reikia išsiaiškinti ar tai yra įmanoma.
							</p>
							<p className="srvs__p">
								Jei sklypo paskirties keitimas inicijuojamas norint statyti namą (o dažniausiai taip ir
								yra), pirmiausia reikia patikrinti ar sklypas patenka į savivaldybių bendruosiuose
								planuose numatytas urbanizuotas teritorijas. Jei savivaldybių bendruosiuose planuose jus
								dominanti teritorija yra pažymėta kaip geltonoji zona, tai reiškia, jog galėsite keisti
								žemės paskirtį ir joje statyti statinius. Tuo tarpu jei pažymėta kaip žalioji zona,
								tokiu atveju statinių šiame sklype pastatyti negalėsite. Suprasti bendruosius planus ir
								galimybes keisti sklypo paskirti padės miesto bei rajono architektas arba „Dzūkijos
								valdos“ matininkai.
							</p>
						</>
					),
				},
				{
					title: "Ko reikia keičiant žemės sklypo paskirtį?",
					content: (
						<>
							<p className="srvs__p">
								Norint pradėti žemės sklypo paskirties keitimo procedūrą, jums reikės pateikti prašymą
								savivaldybei „Dėl žemės sklypo žemės naudojimo paskirties ir būdo keitimo ar nustatymo“
								specialia forma. Tam jums bus reikalingi šie dokumentai:
							</p>
							<p className="srvs__p">
								<List
									elements={[
										"Žemės sklypo ir pastatų (jei yra) nuosavybės dokumentų kopijos",
										<>
											Žemės sklypo plano kopija (reikalingi{" "}
											<WebLink url={`/${serviceUrls.GEODEZINIAI_MATAVIMAI}`}>
												kadastriniai matavimai
											</WebLink>
											)
										</>,
										"Statinių (jei tokie yra) išdėstymo planas.",
									]}
								/>
							</p>
							<p className="srvs__p">
								Priklausomai nuo situacijos gali būti reikalingi ir kiti dokumentai, pavyzdžiui,
								sutikimai, įgaliojimai. Taip pat, jei keičiama paskirtis iš žemės ūkio į gyvenamosios
								paskirties namų valdą, sklypui reikalingas adresas. Jei adresas nėra priskirtas, prašymą
								priskyrimui reikia pateikti kartu su prašymu keisti paskirtį.
							</p>
						</>
					),
				},
				{
					title: "Nuo ko priklauso žemės sklypo paskirties keitimo kaina?",
					content: (
						<>
							<p className="srvs__p">
								Sklypo paskirties keitimo kaina priklauso nuo sklypo vietos, esamų apribojimų, ploto, ar
								yra visi reikiami dokumentai ir kadastriniai matavimai. Kiekvienam objektui kaina yra
								nustatoma individualiai, priklausomai nuo konkretaus sklypo specifikos. Konkretų
								pasiūlymą Dzūkijos valdos pateiks konsultacijos metu.
							</p>
							<p className="srvs__p">
								Konkrečią kainą Dzūkijos Valdos konsultantai pateiks pokalbio metu.{" "}
								<WebLink url="/kontaktai">Susisiekime!</WebLink>
							</p>
						</>
					),
				},
			]}
		/>
	);
};

export default ZemesSklypoPaskirtiesKeitimas;

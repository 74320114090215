import { servicesInfo } from "@shared/constants/re_services";
import React from "react";
import WebLink from "../../common_comps/WebLink";
import List from "./Parts/List";
import ServiceWithCustomSections from "./ServiceWithCustomSections";

const Inventorizacija: React.FC<{}> = () => {
	return (
		<ServiceWithCustomSections
			title={servicesInfo.PASTATU_INVENTORIZACIJA.title}
			descr={
				"Dzūkijos Valdos matininkų profesionalų komanda atlieka statinių kadastrinius matavimus ir inventorizacijos metu tiksliai apskaičiuoja statinių plotus, tūrius, nurodo statybines medžiagas, apskaičiuoja statybos darbų baigtumo procentus ir vertę bei parengia statinio kadastrinių matavimų bylą."
			}
			sections={[
				{
					title: "Kas yra pastato inventorizacija?",
					content: (
						<>
							<p className="srvs__p">
								Pastatų kadastriniai matavimai, dar kitaip tiesiog vadinami inventorizacija, yra
								nekilnojamojo turto kadastro duomenų surinkimas ir kadastrinių matavimų bylos
								parengimas. Kadastriniai matavimai atspindi inventorizuojamo pastato ar statinio dydį,
								fizinę būklę, turto vertę, buvimo vietą (adresą) ir kitus objekto rodiklius, taip pat
								fiksuojamus nekilnojamojo turto pakitimus.
							</p>
							<p className="srvs__p">
								Kadastriniai matavimai (inventorizacija) atliekama gyvenamosios ir negyvenamosios
								paskirties pastatams (namams, sodo nameliams, ūkiniams pastatams, butams, bendrabučiams,
								pramoniniams pastatams) bei inžineriniams tinklams (vandentiekio, nuotekų, elektros,
								dujų) bei susisiekimo komunikacijoms (keliai, takai, aikštelės).
							</p>
							<p className="srvs__p">
								Surinkta bei užfiksuota informacija tampa pagrindu kadastro duomenų bylai, dokumentui,
								kuris reikalingas atliekant tolesnius veiksmus su inventorizuotu pastatu, pavyzdžiui,
								norint priduoti pastatą pabaigus statybos darbus.
							</p>
							<p className="srvs__p">
								Statinių kadastriniai matavimai gali būti atliekami tik sertifikuotų specialistų, tokių,
								kaip Dzūkijos Valdos matininkai.
							</p>
						</>
					),
				},
				{
					title: "Kada reikalingi kadastriniai pastato matavimai?	",
					content: (
						<>
							<p className="srvs__p">
								Visi pastatai turi inventorizacijos bylą, tad kiekvienas statinys bent kartą per savo
								gyvavimo laiką susiduria su kadastriniais matavimais. Dažniausiai inventorizacija
								atliekama susidūrus su viena iš šių situacijų:
							</p>
							<p className="srvs__p">
								<List
									elements={[
										"Kai pastatai, patalpos, statiniai pastatyti, rekonstruoti, kapitališkai suremontuoti ar kitaip pertvarkyti, kuriems LR statybos įstatymo nustatyta tvarka privalomas statybos leidimas ir pripažinimas tinkamais naudoti,",
										"Norint pastatą, statinį ar patalpą įkeisti bankui (fiksuojamos charakteristikos ir statybos baigtumas per kelis kartus),",
										"Norint padalinti, atidalinti esamus objektus, taip suformuojant naujus turto objektus,",
										"Formuojant naujus turto objektus.",
									]}
								/>
							</p>
							<p className="srvs__p">
								Kadastriniai matavimai, imant paskolą iš banko ir pastatą įkeičiant bankui, yra
								atliekami kelis kartus, paprastai tris:
							</p>
							<p className="srvs__p">
								<List
									elements={[
										"kai statybų baigtumas iki 10%, jau yra išlieti pamatai ir yra techniškai įmanoma apskaičiuoti pamatų tūrį,",
										"kai statybų baigtumas yra apie 50% – 80%, namas jau yra po stogu, tačiau dar neįrengtas, ",
										"kai statybų baigtumas 100%, namo statybos pilnai pabaigtos, ruošiamasi pastato pridavimui.",
									]}
								/>
							</p>
						</>
					),
				},
				{
					title: "Kokius dokumentus reikia pateikti, norint atlikti inventorizaciją?",
					content: (
						<>
							<p className="srvs__p">
								Norint atlikti kadastrinius matavimus (inventorizaciją), matininkams reikia pateikti
								šiuos dokumentus:
							</p>
							<p className="srvs__p">
								<List
									elements={[
										"Statinio projektą ir statybos (rekonstrukcijos) leidimą,",
										"Kadastrinių matavimų bylą (jei jau yra registruotų objektų),",
										"Nekilnojamo turto nuosavybės pažymėjimą (jeigu buvo registruota anksčiau),",
										"Žemės sklypo kadastrinių matavimų bylą (sklypo planą)",
										"Įsakymą dėl adreso suteikimo (jei adresas dar nebuvo suteiktas).",
									]}
								/>
							</p>
							<p className="srvs__p">
								Priklausomai nuo konkrečios situacijos, šis sąrašas gali būti papildytas kitais
								dokumentais. Apie jų poreikį informuos su jūsų statinio situacija susipažinęs
								matininkas.
							</p>
						</>
					),
				},
				{
					title: "Pastatų kadastrinių matavimų (inventorizacijos) kaina",
					content: (
						<>
							<p className="srvs__p">
								Pastatų kadastriniai matavimai (inventorizacija) kainuoja priklausomai nuo paties
								objekto, kurį siekiama apibrėžti ir suformuoti kadastro duomenų bylą. Taip pat nuo
								turimų ir trūkstamų dokumentų inventorizacijai atlikti.
							</p>
							<p className="srvs__p">
								Konkrečią kainą Dzūkijos Valdos konsultantai pateiks pokalbio metu.{" "}
								<WebLink url="/kontaktai">Susisiekime!</WebLink>
							</p>
						</>
					),
				},
			]}
		/>
	);
};

export default Inventorizacija;

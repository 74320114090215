import React from "react";
import { useLocalStore, observer } from "mobx-react-lite";
import genericObserver from "@client/common/util/GenericObserver";
import Dotdotdot from "react-dotdotdot";
import Simplebar from "simplebar-react";
import remOrAddToArr from "@shared/util/remOrAddToArr";
import _ from "lodash";

interface OptionP {
	title: string;
	iconClass?: string;
	onClick: () => void;
	checkbox?: boolean;
	checked?: boolean;
}
const Option: React.FC<OptionP> = observer((p) => {
	return (
		<label
			className="srchbox__option"
			onClick={(e) => {
				const target = e.target as HTMLElement;
				if (p.checkbox && target.nodeName !== "INPUT") return; //on label + checkbox combo, when checkbox always triggers double click
				p.onClick();
			}}
			htmlFor={p.title}
		>
			{p.checkbox && <input id={p.title} type="checkbox" defaultChecked={p.checked} />}
			{p.iconClass && <i className={p.iconClass} />}
			{p.title}
		</label>
	);
});

export interface DropdownOption<T> {
	title: string;
	iconClass?: string;
	value: T;
}
export type DropdownOptions<T extends string> = Record<T, DropdownOption<T>>;
interface DropdownP<T extends string> {
	// generics for enums
	options: DropdownOptions<T>;
	emptyOptText?: string;
	onClick: (val: T) => void;
	mod?: "sort";
	multi?: boolean;
	iconClass?: string;
	selectedOpts?: string | string[];
	id?: string;
}
const Dropdown = genericObserver(<T extends string>(p: DropdownP<T>) => {
	const LS = useLocalStore(
		(source) => ({
			outsideVal: p.selectedOpts,
			open: false,
			openModal() {
				if (this.open) return;
				this.open = true;
				document.addEventListener("click", LS.closeModal);
				document.addEventListener("keydown", LS.closeModal);
			},
			closeModal(e: MouseEvent | Event) {
				const target = e.target as HTMLElement;
				if (!this.open) return;
				if (
					(source.multi && target.classList.contains("srchbox__option")) ||
					target.parentElement?.classList.contains("srchbox__option")
				)
					return;
				this.open = false;
				document.removeEventListener("click", this.closeModal);
				document.removeEventListener("keydown", this.closeModal);
			},
			click() {
				this.openModal();
			},
			selectedOpts: [] as string[], // this is used only if selectedOpts is not passed
		}),
		p
	);

	let title = "";
	let pOpts: undefined | string[];
	if (p.selectedOpts) {
		if (Array.isArray(p.selectedOpts)) {
			pOpts = p.selectedOpts;
		} else {
			pOpts = p.selectedOpts ? [p.selectedOpts] : [];
		}
	}

	let selectedOpts = pOpts || LS.selectedOpts;
	if (selectedOpts.length > 0) {
		title = selectedOpts
			.map((val) => {
				if (!p.options[val]) {
					console.error(`Val: ${val} not found in dropdown`);
					console.error(JSON.stringify(p.options));
					return "Nesvarbu";
				}
				return p.options[val].title;
			})
			.join(", ");
	} else {
		title = p.emptyOptText || "Nesvarbu";
	}

	const classes: string[] = [];
	if (p.mod) classes.push(`srchbox__dropdown--${p.mod}`);
	if (LS.open) classes.push("srchbox__dropdown--open");

	const opts = Object.values(p.options);
	if (p.emptyOptText) {
		opts.unshift({
			title: p.emptyOptText,
			value: undefined,
		});
	}

	return (
		<div className={`srchbox__dropdown ${classes.join(" ")}`}>
			<div className="srchbox__title" onClick={LS.click}>
				{p.iconClass && <i className={`srchbox__drp-i ${p.iconClass}`} />}
				<Dotdotdot className="srchbox__dropdown-title-t" clamp={3}>
					{title}
				</Dotdotdot>
				<i className="srchbox__dropdown-arrow icon-chevron-down" />
			</div>
			<div className="srchbox__options" style={LS.open ? { height: "auto" } : { height: 0 }}>
				<div className="srchbox__options-pad">
					<Simplebar style={{ maxHeight: 300 }}>
						{_.map(opts, (opt: DropdownOption<T>) => (
							<Option
								checked={selectedOpts.includes(opt.value)}
								iconClass={opt.iconClass}
								checkbox={p.multi}
								title={opt.title}
								key={opt.title}
								onClick={() => {
									//console.log("selectedOpts: " + p.selectedOpts);
									//console.log("click val: " + opt.value);
									//console.log("selectedOpts", p.selectedOpts);
									(() => {
										if (!LS.outsideVal) {
											if (!opt.value) return (LS.selectedOpts = []);

											if (p.multi) {
												//console.log("adding");
												remOrAddToArr(LS.selectedOpts, opt.value);
											} else {
												//console.log("setting straight");
												LS.selectedOpts = [opt.value];
											}
										}
									})();

									p.onClick(opt.value);
								}}
							/>
						))}
					</Simplebar>
				</div>
			</div>
		</div>
	);
});
export default Dropdown;

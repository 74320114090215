import React from "react";
import SlickSlider, { Settings } from "react-slick";
import { AllObjPropsC } from "@shared/types/real_estate";
import { useLocalStore, observer } from "mobx-react-lite";
import Obj from "../../common_comps/Object";

type Side = "left" | "right";
interface ArrowP {
	side: Side;
	onClick?: () => void;
	toShow: boolean;
}
const Arrow: React.FC<ArrowP> = (p) => {
	return (
		<button
			type="button"
			onClick={p.onClick}
			className={`objl__arrow objl__arrow--${p.side}`}
			style={{
				display: p.toShow ? void 0 : "none",
			}}
		>
			<i className={`icon-chevron-double-${p.side}`} />
		</button>
	);
};

interface ObjectsLineP {
	objects: AllObjPropsC[];
}
const ObjectsLine: React.FC<ObjectsLineP> = observer((p) => {
	const LS = useLocalStore(() => ({
		scrollPos: 0,
		beforeChange(_currSlide, nextSlide) {
			this.scrollPos = nextSlide;
		},
	}));

	const { objects } = p;
	const scrollElems = 4;
	const showLeftArrow = LS.scrollPos !== 0;
	const showRightArrow = LS.scrollPos + scrollElems < objects.length;
	const sliderSettings: Settings = {
		speed: 750,
		slidesToShow: 4,
		slidesToScroll: 4,
		beforeChange: LS.beforeChange,
		draggable: false,
		infinite: false,
		dots: false,
		waitForAnimate: true,
		prevArrow: <Arrow side="left" toShow={showLeftArrow} />,
		nextArrow: <Arrow side="right" toShow={showRightArrow} />,
		responsive: [
			{
				breakpoint: 1219,
				settings: {
					slidesToShow: 3,
					slidesToScroll: 3,
				},
			},
			{
				breakpoint: 961,
				settings: {
					slidesToShow: 2,
					slidesToScroll: 2,
					initialSlide: 2,
				},
			},
			{
				breakpoint: 620,
				settings: {
					slidesToShow: 1,
					slidesToScroll: 1,
				},
			},
		],
	};

	return (
		<div className="objl">
			<SlickSlider {...sliderSettings}>
				{objects.map((object) => (
					<div key={object.title} className="objl__slide-wrap">
						<Obj obj={object} />
					</div>
				))}
			</SlickSlider>
		</div>
	);
});
export default ObjectsLine;

import React from "react";
import { Link } from "react-router-dom";
import Button from "../../common_comps/UI/Button";

interface P {
	url: string;
	title: string;
	descr: string;
}
const ServiceBox: React.FC<P> = (p) => {
	return (
		<Link className="geotop" to={`/${p.url}`}>
			<div className="geotop__box">
				<i className="geotop__icon icon-geodesy" />
				<div className="geotop__wrap">
					<h2 className="geotop__h1">{p.title}</h2>
					<span className="geotop__h3">{p.descr}</span>
					<div className="geotop__but">
						<Button className="geotop__btn">Sužinoti daugiau</Button>
					</div>
				</div>
			</div>
		</Link>
	);
};
export default ServiceBox;

import {
	HouseType,
	BuildingType,
	HeatingType,
	InstallationType,
	BuildingPurpose,
	LandPurpose,
	GarageType,
	GarageFeatures,
	LandFeatures,
	EnumProps,
	ObjType,
	WaterType,
	BuildingFeatures,
} from "@shared/types";

export const transObjAction = {
	RENT: "Nuoma",
	SELL: "Parduodama",
};

export const transObjType: { [k in ObjType]: string } = {
	FLAT: "Butas",
	HOUSE: "Namas",
	LAND: "Sklypas",
	GARAGE: "Garažas",
	LODGING: "Patalpos",
	HOMESTEAD: "Sodyba",
	GARDEN_HOUSE: "Sodas",
};

export const transHouseType: { [k in HouseType]: string } = {
	HOUSE: "Namas (gyvenamasis)",
	PART_OF_HOUSE: "Namo dalis",
	BLOCK_HOUSE: "Sublokuotas namas",
	OTHER: "Kita",
};

export const transBuildingType: { [k in BuildingType]: string } = {
	BRICK: "Mūrinis",
	BLOCK: "Blokinis",
	MONOLITHIC: "Monolitinis",
	WOODEN: "Medinis",
	CARCASS: "Karkasinis",
	WOODEN_LOGS: "Rąstinis",
	OTHER: "Kita",
};

export const transHeatingType: { [k in HeatingType]: string } = {
	CITY_CENTRAL: "Miesto centrinis",
	LOCAL_CENTRAL: "Vietinis centrinis",
	FURNANCE: "Krosnis",
	NONE: "Nėra",
	CENTRAL_COLLECTORING: "Centrinis kolektorinis",
	GAS: "Dujinis",
	ELECTRIC: "Elektrinis",
	AEROTERMIC: "Aeroterminis",
	GEOTERMIC: "Geoterminis",
	LIQUID_FUEL: "Skystu kuru",
	SOLID_FUEL: "Kietu kuru",
	OTHER: "Kita",
};

export const transInstallationType: { [k in InstallationType]: string } = {
	EQUIPPED: "Įrengtas",
	PARTIAL_EQUIPPED: "Dalinai įrengtas",
	PARTIAL_DECORATION: "Dalinė apdaila",
	NOT_EQUIPPED: "Neįrengtas",
	NOT_FINISHED: "Nebaigtas statyti",
	FOUNDATION: "Pamatai",
	OTHER: "Kita",
};

export const transBuildingPurpose: { [k in BuildingPurpose]: string } = {
	ADMINISTRATION: "Administracinė",
	COMMERCIAL: "Komercinė",
	HOTEL: "Viešbučių",
	SERVICE: "Paslaugų",
	WAREHOUSE: "Sandėliavimo",
	INDUSTRIAL: "Gamybos ir pramonės",
	FOOD_SERVICES: "Maitinimo",
	GARDEN_HOUSE: "Sodų",
	OTHER: "Kita",
};

export const transLandPurpose: { [k in LandPurpose]: string } = {
	HOME: "Namų valdos",
	FLAT: "Daugiabučių statybos",
	LAND_FARM: "Žemės ūkio",
	GARDEN: "Sodų bendrijos",
	FOREST_FARM: "Miškų ūkio",
	INDUSTRIAL: "Pramonės",
	WAREHOUSE: "Sandėliavimo",
	COMMERCIAL: "Komercinė",
	RECREATIONAL: "Rekreacinė",
	OTHER: "Kita",
};

export const transGarageType: { [k in GarageType]: string } = {
	BRICK: "Mūrinis",
	METAL: "Metalinis",
	UNDERGROUND: "Požeminis",
	MULTI_FLOOR: "Daugiaaukštis",
	OTHER: "Kita",
};

export const transGarageFeatures: { [k in GarageFeatures]: string } = {
	SECURITY: "Apsauga",
	AUTOMATIC_GATES: "Automatiniai vartai",
	HOLE: "Duobė",
	BASEMENT: "Rūsys",
	WATER: "Vanduo",
	HEATING: "Šildymas",
};

export const transLandFeatures: { [k in LandFeatures]: string } = {
	WATER: "Vanduo",
	GAS: "Dujos",
	ELECTRICITY: "Elektra",
	SEWER: "Kanalizacija",
	WELL: "Šulinys",
};

export const transWaterType: { [k in WaterType]: string } = {
	CITY_WATER: "Miesto vandentiekis",
	LOCAL_WATER: "Vietinis vandentiekis",
	WELL: "Šulinys",
	SEWER: "Kanalizacija",
};

export const transBuildingFeatures: { [k in BuildingFeatures]: string } = {
	ASPHALT_ROAD: "Asfaltuotas privažiavimas",
	ELECTRICITY: "Elektra",
	GAS: "Dujos",
	INTERNET: "Internetas",
	NEAR_FOREST: "Šalia miško",
	PHONE_LINE: "Telefono linija",
	TV: "Kabelinė televizija",
};

export const allTranslations: { [k in EnumProps]: Record<string, string> } = {
	BuildingType: transBuildingType,
	LandFeatures: transLandFeatures,
	GarageFeatures: transGarageFeatures,
	HeatingType: transHeatingType,
	HouseType: transHouseType,
	InstallationType: transInstallationType,
	LandPurpose: transLandPurpose,
	GarageType: transGarageType,
	BuildingPurpose: transBuildingPurpose,
	WaterType: transWaterType,
	BuildingFeatures: transBuildingFeatures,
};
export default allTranslations;

import React from "react";
import ButtonBase from "@material-ui/core/ButtonBase";

interface ButtonP {
	iconClass?: string;
	className?: string;
	onClick?: () => void;
}

const Button: React.FC<ButtonP> = (p) => {
	return (
		<ButtonBase className={`act_btn ` + p.className} onClick={p.onClick}>
			{p.iconClass && <i className={p.iconClass} />}
			{p.children}
		</ButtonBase>
	);
};

export default Button;

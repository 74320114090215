import { servicesInfo } from "@shared/constants/re_services";
import React from "react";
import WebLink from "../../common_comps/WebLink";
import List from "./Parts/List";
import ServiceWithCustomSections from "./ServiceWithCustomSections";

const KadastriniaiMatavimai: React.FC<{}> = () => {
	return (
		<ServiceWithCustomSections
			title={servicesInfo.PASTATU_PRIDAVIMAS.title}
			descr={
				"Po namo statybos rūpesčių dažniausiai norisi, kad viskas būtų kuo greičiau užbaigta. Tam reikia įveikti paskutinį etapą – tai namo pridavimas, tuomet galima teigti, jog statybos 100% užbaigtos. Dzūkijos valdos specialistai tuo pasirūpins!"
			}
			sections={[
				{
					title: "Kas yra pastato pridavimas ir kam to reikia?",
					content: (
						<>
							<p className="srvs__p">
								Pagal LR Statybos užbaigimo ir LR Statybos įstatymus, kiekvienas pastatas turi būti
								patikrintas, įvertintas ir pripažintas tinkamu naudoti. Vertinama ar darbai atlikti
								pagal numatytą projektą: ar pastatai atitinka projekte numatytus sprendimus, ar
								nepažeidžia statybą ir projektavimą reglamentuojančių teisės aktų, ar atitinka sveikatos
								ir higienos normas bei saugumo reikalavimus.
							</p>
							<p className="srvs__p">
								Taip daroma tiek su naujai pastatytais pastatais, tiek su rekonstruotais, tiek su tais,
								kuriems atliktas kapitalinis remontas. Patikrinimus atlieka valstybinės priežiūros
								institucijos, priklausomai nuo pastato tipo tai gali būti kelios skirtingos
								institucijos. Galiausiai pastatas įregistruojamas Registrų centre. Visą šį procesą
								įprasta vadinti pastato ar namo pridavimu.
							</p>
							<p className="srvs__p">
								Pastato pridavimas ir statybos užbaigimas yra būtina procedūra, nes tik sutvarkius visus
								dokumentus yra įteisinamas statinys ir juo galima disponuoti. Tik visiškai užbaigtus ir
								įregistruotus Registrų centre pastatus galima pirkti, parduoti, dovanoti ar paveldėti.
							</p>
						</>
					),
				},
				{
					title: "Dokumentai reikalingi pastato pridavimui",
					content: (
						<>
							<p className="srvs__p">
								Pastato pridavimo procedūra prasideda dar neprasidėjus namo statyboms. Nes tik
								susitvarkius dokumentus ir leidimus atliekamiems statyboms darbams, galima tvarkingai
								užbaigti statybas ir priduoti pastatą.
							</p>
							<p className="srvs__p">Šie dokumentai bus reikalingi pastato pridavimui:</p>
							<p className="srvs__p">
								<List
									elements={[
										"Statybų leidimo kopija,",
										"Priduodamo pastato techninis darbo projektas,",
										"Pažyma apie statinio atitiktį projektui,",
										"Statinio geodezinės nuotraukos,",
										"Statinio kadastro duomenų bylos kopija,",
										"Požeminių inžinerinių tinklų kontrolinių geodezinių nuotraukų kopijos,",
										"Pažymų apie energetikos įrenginių techninės būklės patikrinimą, užbaigus jų montavimo, paleidimo ir derinimo darbus, kopijos,",
										"Žemės sklypo bendraturčių(jei yra) rašytinių sutikimų kopijos,",
										"Pastato energetinio naudingumo sertifikatas.",
									]}
								/>
							</p>
							<p className="srvs__p">
								Ir tai tik pagrindas dokumentų, kurių gali prireikti. Priklausomai nuo pastato
								paskirties, statybos ar renovacijos darbų bei nuosavybės niuansų, gali reikėti ir
								daugiau dokumentų.
							</p>
							<p className="srvs__p">
								Paprastai norint statybas užbaigti tinkamai ir priduoti bei įregistruoti pastatą,
								proceso metu gali tekti susidurti su keliomis skirtingomis institucijomis, tame tarpe
								galimai (nebūtinai) su:
							</p>
							<p className="srvs__p">
								<List
									elements={[
										"Valstybine teritorijų planavimo ir statybos inspekcija,",
										"Valstybinės priešgaisrinės priežiūros inspekcija,",
										"Valstybine energetikos inspekcija,",
										"Aplinkos ministerijos regiono aplinkos apsaugos departamentu,",
										"Visuomenės sveikatos centru,",
										"Miesto / rajono savivaldybės administracijos įgaliotu atstovu.",
									]}
								/>
							</p>
							<p className="srvs__p">
								Būtent dėl to, kad pastato pridavimas netaptų varginančiu ir pernelyg užsitęsusiu
								procesu, geriau patikėti darbus į vienas rankas – Dzūkijos Valdos specialistams.
							</p>
						</>
					),
				},
				{
					title: "Namo pridavimo kaina",
					content: (
						<>
							<p className="srvs__p">
								Pastatų pridavimas gali būti ilgas, žinių bei kantrybės reikalaujantis procesas. O
								paslaugos kaina gali priklausyti nuo įvairių niuansų. Pavyzdžiui, kokį pastatą norite
								priduoti – vienbutį, dvibutį ar daugiabutį? Kokius dokumentus jau turite, o kokius dar
								reikės parengti? Kaip greitai norite įregistruoti nuosavybę?
							</p>
							<p className="srvs__p">
								Pastato pridavimui reikalingų dokumentų parengimas ir statybos užbaigimo patvirtinimas
								Valstybinėje teritorijų planavimo ir statybos inspekcijoje, vienam namui gali kainuoti
								nuo šimto iki pusantro tūkstančio eurų. Kiek konkrečiai kainuos jūsų atveju, atsakyti
								gali Dzūkijos Valdos konsultantai pokalbio metu.{" "}
								<WebLink url="/kontaktai">Susisiekime!</WebLink>
							</p>
						</>
					),
				},
			]}
		/>
	);
};

export default KadastriniaiMatavimai;

export const cityToWhere = {
	Akmenė: "Akmenėje",
	Alytus: "Alytuje",
	Anykščiai: "Anykščiuose",
	Ariogala: "Ariogaloje",
	"Baltoji Vokė": "Baltojoje Vokėje",
	Birštonas: "Birštone",
	Biržai: "Biržuose",
	Daugai: "Dauguose",
	Druskininkai: "Druskininkuose",
	Dūkštas: "Dūkšte",
	Dusetos: "Dusetoje",
	Eišiškės: "Eišiškėse",
	Elektrėnai: "Elektrėnuose",
	Ežerėlis: "Ežerėlyje",
	Gargždai: "Gargžduose",
	Garliava: "Garliavoje",
	Gelgaudiškis: "Gelgaudiškyje",
	Grigiškės: "Grigiškėse",
	Ignalina: "Ignalinoje",
	Jieznas: "Jiezne",
	Jonava: "Jonavoje",
	Joniškėlis: "Joniškėlyje",
	Joniškis: "Joniškyje",
	Jurbarkas: "Jurbarke",
	Kaišiadorys: "Kaišiadoryse",
	Kalvarija: "Kalvarijuose",
	Kaunas: "Kaune",
	Kavarskas: "Kavarske",
	"Kazlų Rūda": "Kazlų Rūdoje",
	Kėdainiai: "Kėdainiuose",
	Kelmė: "Kelmėje",
	Kybartai: "Kybartuose",
	Klaipėda: "Klaipdėdoje",
	Kretinga: "Kretingoje",
	"Kudirkos Naumiestis": "Kudirkos Naumiestyje",
	Kupiškis: "Kupiškyje",
	Kuršėnai: "Kuršėnuose",
	Lazdijai: "Lazdijuose",
	Lentvaris: "Lentvaryje",
	Linkuva: "Linkuvoje",
	Marijampolė: "Marijampolėje",
	Mažeikiai: "Mažeikiuose",
	Molėtai: "Molėtuose",
	"Naujoji Akmenė": "Naujojoje Akmenėje",
	Nemenčinė: "Nemenčinėje",
	Neringa: "Neringoje",
	Nida: "Nidoje",
	Obeliai: "Obeliuose",
	Pabradė: "Pabradėje",
	Pagėgiai: "Pagėgiuose",
	Pakruojis: "Pakruojyje",
	Palanga: "Palangoje",
	Pandėlys: "Pandėlyje",
	Panemunė: "Panemunėje",
	Panevėžys: "Panevėžyje",
	Pasvalys: "Pasvalyje",
	Plungė: "Plungėje",
	Priekulė: "Priekulėje",
	Prienai: "Prienuose",
	Radviliškis: "Radviliškyje",
	Ramygala: "Ramygaloje",
	Raseiniai: "Raseiniuose",
	Rietavas: "Rietave",
	Rokiškis: "Rokiškyje",
	Rūdiškės: "Rūdiškėse",
	Salantai: "Salantuose",
	Seda: "Sedoje",
	Simnas: "Simne",
	Skaudvilė: "Skaudvilėje",
	Skuodas: "Skuode",
	Smalininkai: "Smalininkuose",
	Subačius: "Subačiuje",
	Šakiai: "Šakiuose",
	Šalčininkai: "Šalčininkuose",
	Šeduva: "Šeduvoje",
	Šiauliai: "Šiauliuose",
	Šilalė: "Šilalėje",
	Šilutė: "Šilutėje",
	Širvintos: "Širvintuose",
	Švenčionėliai: "Švenčionėliuose",
	Švenčionys: "Švenčionyse",
	Tauragė: "Tauragėje",
	Telšiai: "Telšiuose",
	Tytuvėnai: "Tytuvėnuose",
	Trakai: "Trakuose",
	Troškūnai: "Troškūnuose",
	Ukmergė: "Ukmergėje",
	Utena: "Utenoje",
	Užventis: "Užventyje",
	Vabalninkas: "Vabalninkuose",
	Varėna: "Varėnoje",
	Varniai: "Varniuose",
	Veisiejai: "Veisiejuose",
	Venta: "Ventoje",
	Viekšniai: "Viekšniuose",
	Vievis: "Vievyje",
	Vilkaviškis: "Vilkaviškyje",
	Vilkija: "Vilkijoje",
	Vilnius: "Vilniuje",
	Virbalis: "Virbaliuose",
	Visaginas: "Visagine",
	Zarasai: "Zarasuose",
	Žagarė: "Žagarėje",
	Žiežmariai: "Žiežmariuose",
	Butrimonys: "Butrimonyje",
	Krokialaukis: "Kriokialaukyje",
	Nemunaitis: "Nemunaityje",
	Leipalingis: "Leipalingyje",
	Viečiūnai: "Viečiūnuose",
	Kapčiamiestis: "Kapčiamiestyje",
	Krosna: "Krosnoje",
	Rudamina: "Rudaminoje",
	Seirijai: "Seirijoje",
	Šeštokai: "Šeštokuose",
	Šventežeris: "Šventežeryje",
	Merkinė: "Merkinėje",
	Valkininkai: "Valkininkuose",
	Panoteriai: "Panoteriuose",
	Rukla: "Rukloje",
	Žeimiai: "Žeimiuose",
	Kruonis: "Kruonyje",
	Rumšiškės: "Rumšiškėse",
	Žasliai: "Žasliuose",
	Akademija: "Akademijoje",
	Babtai: "Babtuose",
	Čekiškė: "Čekiškėse",
	Domeikava: "Domeikavoje",
	Kačerginė: "Kačerginėje",
	Karmėlava: "Karmėlavoje",
	Kulautuva: "Kulautuvoje",
	Lapės: "Lapėse",
	Vandžiogala: "Vandžiogaloje",
	Zapyškis: "Zapyškyje",
	Dotnuva: "Dontnuvoje",
	Gudžiūnai: "Gudžiūnuose",
	Josvainiai: "Josvainiuose",
	Krakės: "Krankėje",
	Pagiriai: "Pagiriuose",
	Pernarava: "Pernaravoje",
	Surviliškis: "Surviliškyje",
	Šėta: "Šėtoje",
	Truskava: "Truskavoje",
	Balbieriškis: "Balbieriškyje",
	Pakuonis: "Pakuonyje",
	Veiveriai: "Veiveriuose",
	Betygala: "Betygaloje",
	Girkalnis: "Girkalnyje",
	Lyduvėnai: "Lyduvėnuose",
	Nemakščiai: "Nemakščiuose",
	Šiluva: "Šiluvoje",
	Viduklė: "Viduklėje",
	Žaiginys: "Žaiginyje",
	Dovilai: "Doviluose",
	Endriejavas: "Endriejavoje",
	Judrėnai: "Judrėnuose",
	Kretingalė: "Kretingalėje",
	Plikiai: "Plikiuose",
	Veiviržėnai: "Veiviržėnuose",
	Vėžaičiai: "Vėžaičiuose",
	Darbėnai: "Darbėnai",
	Kartena: "Kartenoje",
	Barstyčiai: "Barstyčiuose",
	Ylakiai: "Ylakiuose",
	Lenkimai: "Lenkimuose",
	Mosėdis: "Mosėdyje",
	Gardamas: "Gardamoje",
	Katyčiai: "Katyčiuose",
	Kintai: "Kintuose",
	Rusnė: "Rusnėje",
	Švėkšna: "Švėkšnoje",
	Vainutas: "Vainutyje",
	"Žemaičių Naumiestis": "Žemaičių Naumiestyje",
	Jūrė: "Jūrėje",
	Daukšiai: "Daukšiuose",
	Gudeliai: "Gudeliuose",
	Igliškėliai: "Igliškėliuose",
	Liudvinavas: "Liudvinavoje",
	Sasnava: "Sasnavoje",
	Šunskai: "Šunskuose",
	Barzdai: "Barzduose",
	Griškabūdis: "Griškabūdyje",
	Kriūkai: "Kriūkuose",
	Lekėčiai: "Lekėčiuose",
	Lukšiai: "Lukšiuose",
	Sintautai: "Sintautuose",
	"Žemoji Panemunė": "Žemojoje Panemunėje",
	Žvirgždaičiai: "Žvirgždaičiuose",
	Bartininkai: "Bartininkuose",
	Gražiškiai: "Gražiškiuose",
	Keturvalakiai: "Keturvalakiuose",
	Pilviškiai: "Pilviškiuose",
	Vištytis: "Vištytyje",
	Kupreliškis: "Kupreliškyje",
	"Nemunėlio Radviliškis": "Nemunėlio Radviliškyje",
	Pabiržė: "Pabiržėje",
	Papilys: "Papilyje",
	"Alizava Antašava": "Alizavos Antašavoje",
	Palėvenė: "Palėvenėje",
	Salamiestis: "Slamiestyje",
	"Senasis Subačius": "Senajame Subačiuje",
	Skapiškis: "Skapiškyje",
	Šimonys: "Šimonyje",
	Geležiai: "Geležiuose",
	Krekenava: "Kekenavoje",
	Miežiškiai: "Miežiškiuose",
	Naujamiestis: "Naujamiestyje",
	Raguva: "Raguvoje",
	Smilgiai: "Smilgiuose",
	Šilai: "Šiluose",
	Vadokliai: "Vadokliuose",
	Daujėnai: "Daujėnuose",
	Krikliniai: "Krikliniuose",
	Krinčinas: "Krinčine",
	Pumpėnai: "Pumpėnuose",
	Pušalotas: "Pušalote",
	Saločiai: "Saločiuose",
	Vaškai: "Vaškuose",
	Čedasai: "Čedasuose",
	Duokiškis: "Duokiškyje",
	Duodupė: "Duodupėje",
	Jūžintai: "Jūžintuose",
	Kamajai: "Kamajuose",
	Panemunėlis: "Panemunėlyje",
	Panemunis: "Panemunyje",
	Salos: "Salose",
	Suvainiškis: "Suvainiškyje",
	Kruopiai: "Kruopiuose",
	Papilė: "Papilėje",
	Kriukai: "Kriukuose",
	Skaistgirys: "Skaistgiryje",
	Karklėnai: "Karklėnuose",
	Kražiai: "Kražiuose",
	Lioliai: "Lioliuose",
	Pašilė: "Pašilėje",
	Šaukėnai: "Šaukėnuose",
	Žalpiai: "Žalpiuose",
	Klovainiai: "Klovainiuose",
	Lygumai: "Lygumoje",
	Pašvitinys: "Pašvintynyje",
	Rozalimas: "Rozalime",
	Žeimelis: "Žeimelyje",
	Baisogala: "Baisogaloje",
	Grinkiškis: "Grinkiškyje",
	Palonai: "Palonuose",
	Pašušvys: "Pašušviuose",
	Pociūnėliai: "Pociūnėliuose",
	Sidabravas: "Sidabravoje",
	Šaukotas: "Šaukote",
	Šiaulėnai: "Šiaulėnuose",
	Tyruliai: "Tyruliuose",
	Vadaktai: "Vadaktuose",
	Bazilionai: "Bazilionuose",
	Gruzdžiai: "Gruzdžiuose",
	Kairiai: "Kairiuose",
	Kurtuvėnai: "Krutuvėnuose",
	Kužiai: "Kužiuose",
	Meškuičiai: "Meškuičiuose",
	Šakyna: "Šakyne",
	Eržvilkas: "Eržvilke",
	Raudonė: "Raudonėje",
	Seredžius: "Seredžiuje",
	Stakiai: "Stakliuose",
	Šimkaičiai: "Šimkaičiuose",
	Vadžgirys: "Vadžgiriuose",
	Veliuona: "Veliuonuoje",
	Viešvilė: "Viešvilėje",
	Vilkyškiai: "Vikyškiuose",
	Kaltinėnai: "Kaltinėnuose",
	Kvėdarna: "Kvėdarnoje",
	Laukuva: "Laukuvoje",
	Pajūris: "Pajūryje",
	Teneniai: "Teneniuose",
	Upyna: "Upynoje",
	Žvigiai: "Žvigiuose",
	Batakiai: "Batakiuose",
	Gaurė: "Gaurėje",
	Pagramantis: "Pagramantyje",
	Žygaičiai: "Žygaičiuose",
	Laižuva: "Laižuvoje",
	Leckava: "Leckavoje",
	Pikeliai: "Pikeliuose",
	Tirkšliai: "Tirkšliuose",
	Židikai: "Židikuose",
	Alsėdžiai: "Alsėdžiuose",
	Kuliai: "Kuliuose",
	Plateliai: "Plateliuose",
	"Žemaičių Kalvarija": "Žemaičių Kalvarijoje",
	Tverai: "Tveruose",
	Eigirdžiai: "Eigirdžiuose",
	Gadūnavas: "Gadūnavoje",
	Janapolė: "Janapolėje",
	"Lauko Soda": "Lauko Soduose",
	Luokė: "Luokėje",
	Nerimdaičiai: "Nerimdaičiuose",
	Nevarėnai: "Nevarėnuose",
	Pavandenė: "Pavandenėje",
	Tryškiai: "Tryškiuose",
	Ubiškė: "Ubiškėje",
	Žarėnai: "Žarėnuose",
	Andrioniškis: "Andrioniškyje",
	Debeikiai: "Debeikiuose",
	Kurkliai: "Kurkliuose",
	Skiemonys: "Skiemonyje",
	Surdegis: "Surdegyje",
	Svėdasai: "Svėdasuose",
	Traupis: "Traupyje",
	Viešintos: "Viešintoje",
	Mielagėnai: "Mielagėnuose",
	Rimšė: "Rimšėje",
	Tverečius: "Tverečiuje",
	Alanta: "Alantoje",
	Balninkai: "Balninkuose",
	Dubingiai: "Dubingiuose",
	Giedraičiai: "Giedraičiuose",
	Daugailiai: "Daugailiuose",
	Kuktiškės: "Kuktiškėse",
	Leliūnai: "Leliūnuose",
	Saldutiškis: "Saldutiškyje",
	Sudeikiai: "Sudeikiuose",
	Tauragnai: "Tauragnuose",
	Užpaliai: "Užpaliuose",
	Vyžuonos: "Vyžuonoje",
	Antalieptė: "Antalieptėje",
	Salakas: "Slakuose",
	Turmantas: "Turmante",
	Semeliškės: "Semeliškėse",
	Dieveniškės: "Dieveniškėse",
	Jašiūnai: "Jašiūnuose",
	Bagaslaviškis: "Bagaslaviškyje",
	Gelvonai: "Gelvonuose",
	Kernavė: "Kernavėje",
	Musninkai: "Musninkuose",
	Zibalai: "Zibaluose",
	Adutiškis: "Adutiškyje",
	Kaltanėnai: "Kaltanėnuose",
	Labanoras: "Labanore",
	Aukštadvaris: "Auštadvaryje",
	Onuškis: "Onuškyje",
	Deltuva: "Deltuvoje",
	Lyduokiai: "Lyduokiuose",
	Pabaiskas: "Paiske",
	Siesikai: "Siesikuose",
	Šešuoliai: "Šešuoliuose",
	Taujėnai: "Taujėnuose",
	Vepriai: "Vepriuose",
	Vidiškiai: "Vidiškiuose",
	Želva: "Želvoje",
	Žemaitkiemis: "Žemaitkiemyje",
	Bezdonys: "Bezdonyse",
	Maišiagala: "Maišiagaloje",
	Mickūnai: "Mickūnuose",
	Šumskas: "Šumske",
};

export const whereToCity = {
	Akmenėje: "Akmenė",
	Alytuje: "Alytus",
	Anykščiuose: "Anykščiai",
	Ariogaloje: "Ariogala",
	"Baltojoje Vokėje": "Baltoji Vokė",
	Birštone: "Birštonas",
	Biržuose: "Biržai",
	Dauguose: "Daugai",
	Druskininkuose: "Druskininkai",
	Dūkšte: "Dūkštas",
	Dusetoje: "Dusetos",
	Eišiškėse: "Eišiškės",
	Elektrėnuose: "Elektrėnai",
	Ežerėlyje: "Ežerėlis",
	Gargžduose: "Gargždai",
	Garliavoje: "Garliava",
	Gelgaudiškyje: "Gelgaudiškis",
	Grigiškėse: "Grigiškės",
	Ignalinoje: "Ignalina",
	Jiezne: "Jieznas",
	Jonavoje: "Jonava",
	Joniškėlyje: "Joniškėlis",
	Joniškyje: "Joniškis",
	Jurbarke: "Jurbarkas",
	Kaišiadoryse: "Kaišiadorys",
	Kalvarijuose: "Kalvarija",
	Kaune: "Kaunas",
	Kavarske: "Kavarskas",
	"Kazlų Rūdoje": "Kazlų Rūda",
	Kėdainiuose: "Kėdainiai",
	Kelmėje: "Kelmė",
	Kybartuose: "Kybartai",
	Klaipdėdoje: "Klaipėda",
	Kretingoje: "Kretinga",
	"Kudirkos Naumiestyje": "Kudirkos Naumiestis",
	Kupiškyje: "Kupiškis",
	Kuršėnuose: "Kuršėnai",
	Lazdijuose: "Lazdijai",
	Lentvaryje: "Lentvaris",
	Linkuvoje: "Linkuva",
	Marijampolėje: "Marijampolė",
	Mažeikiuose: "Mažeikiai",
	Molėtuose: "Molėtai",
	"Naujojoje Akmenėje": "Naujoji Akmenė",
	Nemenčinėje: "Nemenčinė",
	Neringoje: "Neringa",
	Nidoje: "Nida",
	Obeliuose: "Obeliai",
	Pabradėje: "Pabradė",
	Pagėgiuose: "Pagėgiai",
	Pakruojyje: "Pakruojis",
	Palangoje: "Palanga",
	Pandėlyje: "Pandėlys",
	Panemunėje: "Panemunė",
	Panevėžyje: "Panevėžys",
	Pasvalyje: "Pasvalys",
	Plungėje: "Plungė",
	Priekulėje: "Priekulė",
	Prienuose: "Prienai",
	Radviliškyje: "Radviliškis",
	Ramygaloje: "Ramygala",
	Raseiniuose: "Raseiniai",
	Rietave: "Rietavas",
	Rokiškyje: "Rokiškis",
	Rūdiškėse: "Rūdiškės",
	Salantuose: "Salantai",
	Sedoje: "Seda",
	Simne: "Simnas",
	Skaudvilėje: "Skaudvilė",
	Skuode: "Skuodas",
	Smalininkuose: "Smalininkai",
	Subačiuje: "Subačius",
	Šakiuose: "Šakiai",
	Šalčininkuose: "Šalčininkai",
	Šeduvoje: "Šeduva",
	Šiauliuose: "Šiauliai",
	Šilalėje: "Šilalė",
	Šilutėje: "Šilutė",
	Širvintuose: "Širvintos",
	Švenčionėliuose: "Švenčionėliai",
	Švenčionyse: "Švenčionys",
	Tauragėje: "Tauragė",
	Telšiuose: "Telšiai",
	Tytuvėnuose: "Tytuvėnai",
	Trakuose: "Trakai",
	Troškūnuose: "Troškūnai",
	Ukmergėje: "Ukmergė",
	Utenoje: "Utena",
	Užventyje: "Užventis",
	Vabalninkuose: "Vabalninkas",
	Varėnoje: "Varėna",
	Varniuose: "Varniai",
	Veisiejuose: "Veisiejai",
	Ventoje: "Venta",
	Viekšniuose: "Viekšniai",
	Vievyje: "Vievis",
	Vilkaviškyje: "Vilkaviškis",
	Vilkijoje: "Vilkija",
	Vilniuje: "Vilnius",
	Virbaliuose: "Virbalis",
	Visagine: "Visaginas",
	Zarasuose: "Zarasai",
	Žagarėje: "Žagarė",
	Žiežmariuose: "Žiežmariai",
	Butrimonyje: "Butrimonys",
	Kriokialaukyje: "Krokialaukis",
	Nemunaityje: "Nemunaitis",
	Leipalingyje: "Leipalingis",
	Viečiūnuose: "Viečiūnai",
	Kapčiamiestyje: "Kapčiamiestis",
	Krosnoje: "Krosna",
	Rudaminoje: "Rudamina",
	Seirijoje: "Seirijai",
	Šeštokuose: "Šeštokai",
	Šventežeryje: "Šventežeris",
	Merkinėje: "Merkinė",
	Valkininkuose: "Valkininkai",
	Panoteriuose: "Panoteriai",
	Rukloje: "Rukla",
	Žeimiuose: "Žeimiai",
	Kruonyje: "Kruonis",
	Rumšiškėse: "Rumšiškės",
	Žasliuose: "Žasliai",
	Akademijoje: "Akademija",
	Babtuose: "Babtai",
	Čekiškėse: "Čekiškė",
	Domeikavoje: "Domeikava",
	Kačerginėje: "Kačerginė",
	Karmėlavoje: "Karmėlava",
	Kulautuvoje: "Kulautuva",
	Lapėse: "Lapės",
	Vandžiogaloje: "Vandžiogala",
	Zapyškyje: "Zapyškis",
	Dontnuvoje: "Dotnuva",
	Gudžiūnuose: "Gudžiūnai",
	Josvainiuose: "Josvainiai",
	Krankėje: "Krakės",
	Pagiriuose: "Pagiriai",
	Pernaravoje: "Pernarava",
	Surviliškyje: "Surviliškis",
	Šėtoje: "Šėta",
	Truskavoje: "Truskava",
	Balbieriškyje: "Balbieriškis",
	Pakuonyje: "Pakuonis",
	Veiveriuose: "Veiveriai",
	Betygaloje: "Betygala",
	Girkalnyje: "Girkalnis",
	Lyduvėnuose: "Lyduvėnai",
	Nemakščiuose: "Nemakščiai",
	Šiluvoje: "Šiluva",
	Viduklėje: "Viduklė",
	Žaiginyje: "Žaiginys",
	Doviluose: "Dovilai",
	Endriejavoje: "Endriejavas",
	Judrėnuose: "Judrėnai",
	Kretingalėje: "Kretingalė",
	Plikiuose: "Plikiai",
	Veiviržėnuose: "Veiviržėnai",
	Vėžaičiuose: "Vėžaičiai",
	Darbėnai: "Darbėnai",
	Kartenoje: "Kartena",
	Barstyčiuose: "Barstyčiai",
	Ylakiuose: "Ylakiai",
	Lenkimuose: "Lenkimai",
	Mosėdyje: "Mosėdis",
	Gardamoje: "Gardamas",
	Katyčiuose: "Katyčiai",
	Kintuose: "Kintai",
	Rusnėje: "Rusnė",
	Švėkšnoje: "Švėkšna",
	Vainutyje: "Vainutas",
	"Žemaičių Naumiestyje": "Žemaičių Naumiestis",
	Jūrėje: "Jūrė",
	Daukšiuose: "Daukšiai",
	Gudeliuose: "Gudeliai",
	Igliškėliuose: "Igliškėliai",
	Liudvinavoje: "Liudvinavas",
	Sasnavoje: "Sasnava",
	Šunskuose: "Šunskai",
	Barzduose: "Barzdai",
	Griškabūdyje: "Griškabūdis",
	Kriūkuose: "Kriūkai",
	Lekėčiuose: "Lekėčiai",
	Lukšiuose: "Lukšiai",
	Sintautuose: "Sintautai",
	"Žemojoje Panemunėje": "Žemoji Panemunė",
	Žvirgždaičiuose: "Žvirgždaičiai",
	Bartininkuose: "Bartininkai",
	Gražiškiuose: "Gražiškiai",
	Keturvalakiuose: "Keturvalakiai",
	Pilviškiuose: "Pilviškiai",
	Vištytyje: "Vištytis",
	Kupreliškyje: "Kupreliškis",
	"Nemunėlio Radviliškyje": "Nemunėlio Radviliškis",
	Pabiržėje: "Pabiržė",
	Papilyje: "Papilys",
	"Alizavos Antašavoje": "Alizavos Antašava",
	Palėvenėje: "Palėvenė",
	Slamiestyje: "Salamiestis",
	"Senajame Subačiuje": "Senasis Subačius",
	Skapiškyje: "Skapiškis",
	Šimonyje: "Šimonys",
	Geležiuose: "Geležiai",
	Kekenavoje: "Krekenava",
	Miežiškiuose: "Miežiškiai",
	Naujamiestyje: "Naujamiestis",
	Raguvoje: "Raguva",
	Smilgiuose: "Smilgiai",
	Šiluose: "Šilai",
	Vadokliuose: "Vadokliai",
	Daujėnuose: "Daujėnai",
	Krikliniuose: "Krikliniai",
	Krinčine: "Krinčinas",
	Pumpėnuose: "Pumpėnai",
	Pušalote: "Pušalotas",
	Saločiuose: "Saločiai",
	Vaškuose: "Vaškai",
	Čedasuose: "Čedasai",
	Duokiškyje: "Duokiškis",
	Duodupėje: "Duodupė",
	Jūžintuose: "Jūžintai",
	Kamajuose: "Kamajai",
	Panemunėlyje: "Panemunėlis",
	Panemunyje: "Panemunis",
	Salose: "Salos",
	Suvainiškyje: "Suvainiškis",
	Kruopiuose: "Kruopiai",
	Papilėje: "Papilė",
	Kriukuose: "Kriukai",
	Skaistgiryje: "Skaistgirys",
	Karklėnuose: "Karklėnai",
	Kražiuose: "Kražiai",
	Lioliuose: "Lioliai",
	Pašilėje: "Pašilė",
	Šaukėnuose: "Šaukėnai",
	Žalpiuose: "Žalpiai",
	Klovainiuose: "Klovainiai",
	Lygumoje: "Lygumai",
	Pašvintynyje: "Pašvitinys",
	Rozalime: "Rozalimas",
	Žeimelyje: "Žeimelis",
	Baisogaloje: "Baisogala",
	Grinkiškyje: "Grinkiškis",
	Palonuose: "Palonai",
	Pašušviuose: "Pašušvys",
	Pociūnėliuose: "Pociūnėliai",
	Sidabravoje: "Sidabravas",
	Šaukote: "Šaukotas",
	Šiaulėnuose: "Šiaulėnai",
	Tyruliuose: "Tyruliai",
	Vadaktuose: "Vadaktai",
	Bazilionuose: "Bazilionai",
	Gruzdžiuose: "Gruzdžiai",
	Kairiuose: "Kairiai",
	Krutuvėnuose: "Kurtuvėnai",
	Kužiuose: "Kužiai",
	Meškuičiuose: "Meškuičiai",
	Šakyne: "Šakyna",
	Eržvilke: "Eržvilkas",
	Raudonėje: "Raudonė",
	Seredžiuje: "Seredžius",
	Stakliuose: "Stakiai",
	Šimkaičiuose: "Šimkaičiai",
	Vadžgiriuose: "Vadžgirys",
	Veliuonuoje: "Veliuona",
	Viešvilėje: "Viešvilė",
	Vikyškiuose: "Vilkyškiai",
	Kaltinėnuose: "Kaltinėnai",
	Kvėdarnoje: "Kvėdarna",
	Laukuvoje: "Laukuva",
	Pajūryje: "Pajūris",
	Teneniuose: "Teneniai",
	Upynoje: "Upyna",
	Žvigiuose: "Žvigiai",
	Batakiuose: "Batakiai",
	Gaurėje: "Gaurė",
	Pagramantyje: "Pagramantis",
	Žygaičiuose: "Žygaičiai",
	Laižuvoje: "Laižuva",
	Leckavoje: "Leckava",
	Pikeliuose: "Pikeliai",
	Tirkšliuose: "Tirkšliai",
	Židikuose: "Židikai",
	Alsėdžiuose: "Alsėdžiai",
	Kuliuose: "Kuliai",
	Plateliuose: "Plateliai",
	"Žemaičių Kalvarijoj": "Žemaičių Kalvarija",
	Tveruose: "Tverai",
	Eigirdžiuose: "Eigirdžiai",
	Gadūnavoje: "Gadūnavas",
	Janapolėje: "Janapolė",
	"Lauko Soduos": "Lauko Soda",
	Luokėje: "Luokė",
	Nerimdaičiuose: "Nerimdaičiai",
	Nevarėnuose: "Nevarėnai",
	Pavandenėje: "Pavandenė",
	Tryškiuose: "Tryškiai",
	Ubiškėje: "Ubiškė",
	Žarėnuose: "Žarėnai",
	Andrioniškyje: "Andrioniškis",
	Debeikiuose: "Debeikiai",
	Kurkliuose: "Kurkliai",
	Skiemonyje: "Skiemonys",
	Surdegyje: "Surdegis",
	Svėdasuose: "Svėdasai",
	Traupyje: "Traupis",
	Viešintoje: "Viešintos",
	Mielagėnuose: "Mielagėnai",
	Rimšėje: "Rimšė",
	Tverečiuje: "Tverečius",
	Alantoje: "Alanta",
	Balninkuose: "Balninkai",
	Dubingiuose: "Dubingiai",
	Giedraičiuose: "Giedraičiai",
	Daugailiuose: "Daugailiai",
	Kuktiškėse: "Kuktiškės",
	Leliūnuose: "Leliūnai",
	Saldutiškyje: "Saldutiškis",
	Sudeikiuose: "Sudeikiai",
	Tauragnuose: "Tauragnai",
	Užpaliuose: "Užpaliai",
	Vyžuonoje: "Vyžuonos",
	Antalieptėje: "Antalieptė",
	Slakuose: "Salakas",
	Turmante: "Turmantas",
	Semeliškėse: "Semeliškės",
	Dieveniškėse: "Dieveniškės",
	Jašiūnuose: "Jašiūnai",
	Bagaslaviškyje: "Bagaslaviškis",
	Gelvonuose: "Gelvonai",
	Kernavėje: "Kernavė",
	Musninkuose: "Musninkai",
	Zibaluose: "Zibalai",
	Adutiškyje: "Adutiškis",
	Kaltanėnuose: "Kaltanėnai",
	Labanore: "Labanoras",
	Auštadvaryje: "Aukštadvaris",
	Onuškyje: "Onuškis",
	Deltuvoje: "Deltuva",
	Lyduokiuose: "Lyduokiai",
	Paiske: "Pabaiskas",
	Siesikuose: "Siesikai",
	Šešuoliuose: "Šešuoliai",
	Taujėnuose: "Taujėnai",
	Vepriuose: "Vepriai",
	Vidiškiuose: "Vidiškiai",
	Želvoje: "Želva",
	Žemaitkiemyje: "Žemaitkiemis",
	Bezdonyse: "Bezdonys",
	Maišiagaloje: "Maišiagala",
	Mickūnuose: "Mickūnai",
	Šumske: "Šumskas",
};

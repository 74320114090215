import { servicesInfo } from "@shared/constants/re_services";
import React from "react";
import WebLink from "../../common_comps/WebLink";
import List from "./Parts/List";
import ServiceWithCustomSections from "./ServiceWithCustomSections";

const EnergetinioNaudingumoSertifikatai: React.FC<{}> = () => {
	return (
		<ServiceWithCustomSections
			title={servicesInfo.ENERGETINIO_NAUDINGUMO_SERTIFIKATAI.title}
			descr={
				"Jei yra pastatas, greičiausiai jam reikalingas ir energinio naudingumo sertifikatas. Be jo negalėsite įregistruoti ir disponuoti savo turtu. Šį klausimą jums padės išspręsti Dzūkijos Valdos atestuoti pastatų energinio naudingumo sertifikavimo ekspertai.."
			}
			sections={[
				{
					title: "Kas yra energinio naudingumo sertifikavimas?",
					content: (
						<>
							<p className="srvs__p">
								Pastatų energinio naudingumo sertifikavimas –tai teisės aktų reglamentuotas procesas,
								kurio metu nustatomas pastato šiluminės energijos suvartojimas, pastatas priskiriamas
								energinio naudingumo klasei (nuo aukščiausios A++ iki žemiausios, priskirtos
								neefektyviam pastatui, – G) ir išduodamas pastato energinio naudingumo sertifikatas.
								Sertifikato galiojimo trukmė – iki 10 metų.
							</p>
						</>
					),
				},
				{
					title: "Kada reikalingas energinio naudingumo sertifikatas?",
					content: (
						<>
							<p className="srvs__p">
								Tai privalomas dokumentas praktiškai visiems pastatams, išskyrus išimtinius atvejus.
								Dažniausiai sertifikato prireikia atliekant kokius nors veiksmus su nuosavybe.
							</p>
							<p className="srvs__p">
								<List
									elements={[
										"Energinio naudingumo sertifikavimas atliekamas prieš pripažįstant pastatą ar jo dalį tinkamais naudoti, t.y. pabaigus statybas ir priduodant pastatą.",
										"Sertifikatas reikalingas kai pastatas parduodamas, nuomojamas ar paveldimas ir jo sertifikato galiojimo laikas pasibaigė arba pastatas anksčiau nebuvo sertifikuotas. Energinio naudingumo sertifikatas turi būti pateiktas prieš įforminant pardavimą, nuomos ar paveldėjimo faktą.",
										"Energinio naudingumo sertifikatas reikalingas jei naujai statomo ar kapitališkai renovuojamo pastato dalis pripažįstama tinkama naudoti (net jei kita dalis dar nebaigta ir jai sertifikavimas netaikomas).",
									]}
								/>
							</p>
						</>
					),
				},
				{
					title: "Kaip atliekamas pastato energinio naudingumo sertifikavimas?",
					content: (
						<>
							<p className="srvs__p">
								Norint sertifikuoti pastatą, reikia atlikti skaičiavimus Aplinkos ministerijos
								patvirtinta specialia kompiuterine programa. Skaičiavimams reikalingi duomenys gaunami
								apžiūrint pastatą, atliekant matavimus bei gaunant namo elementų informaciją.
								Pavyzdžiui, reikalingos langų, durų atitikties deklaracijos, šildymo ir vėdinimo
								įrenginių parametrai.
							</p>
							<p className="srvs__p">
								Taip pat reikalingi ir įvairūs dokumentai, tokie kaip kadastrinių matavimų byla bei
								langų sertifikatai. Senesnės statybos namų sertifikavimui reikalingas užpildytas
								klausimynas.
							</p>
							<p className="srvs__p">
								Naujos statybos namams, kuriems numatoma A++, A+, A naudingumo klasė, reikalinga atlikti
								sandarumo testą.
							</p>
							<p className="srvs__p">
								Surinkus visus duomenis, specialistai apskaičiuoja energinio naudingumo klasę ir išduoda
								sertifikatą. Procesas paprastai užtrunka iki 2 savaičių.
							</p>
						</>
					),
				},
				{
					title: "Kokia turi būti naujai statomų ar rekonstruojamų pastatų naudingumo klasė?",
					content: (
						<>
							<p className="srvs__p">
								Statybos techniniame reglamente nurodyta žemiausia leidžiama energinio naudingumo klasė
								tam tikriems pastatams. Siekiant kuo didesnio energinio naudingumo, kas keli metai
								keliami vis didesni reikalavimai naujos statybos ar rekonstruojamiems pastatams.
							</p>
							<p className="srvs__p">
								Žemiausia naudingumo klasė naujais statomiems pastatams pagal leidimo statyti išdavimo
								arba statybos darbų pradžios datą:
							</p>
							<p className="srvs__p">
								<List
									elements={[
										"Nuo 2006 m. sausio 4 d. – C",
										"Nuo 2014 m. sausio 1 d. – B",
										"Nuo 2016 m. lapkričio 1 d. – A",
										"Nuo 2018 m. sausio 1 d. – A+",
										"Nuo 2021 m. sausio 1 d. – A++",
									]}
								/>
							</p>
							<p className="srvs__p">
								Žemiausia naudingumo klasė rekonstruojamiems pastatams pagal leidimo statyti išdavimo
								arba statybos darbų pradžios datą:
							</p>
							<p className="srvs__p">
								<List elements={["Iki 2014 m. sausio 1 d. – D", "Nuo 2014 m. sausio 1 d. – C"]} />
							</p>
						</>
					),
				},
				{
					title: "Energinio naudingumo sertifikato kaina",
					content: (
						<>
							<p className="srvs__p">
								Pastato naudingas plotas, paskirtis, pastato sudėtingumas ir turimi dokumentai lemia
								pastato sertifikavimo kainą. Kiekvienu atveju skaičiuojama individualiai.
							</p>
							<p className="srvs__p">
								Konkrečią kainą Dzūkijos Valdos konsultantai pateiks pokalbio metu.{" "}
								<WebLink url="/kontaktai">Susisiekime!</WebLink>
							</p>
						</>
					),
				},
			]}
		/>
	);
};

export default EnergetinioNaudingumoSertifikatai;

import { SearchFilterOpts, PageUrl, ObjAction, AllObjProps } from "@shared/types";
import { typeToTypeWhose } from "./typeWhose";
import * as urlUtil from "@shared/util/urlUtil";
import qs from "qs";
import transTypeGen from "./objectType";
import _ from "lodash";

const sellUrl = "pardavimas";
const rentUrl = "nuoma";
const sellRentUrl = "pardavimas-nuoma";

function typeToTypeWhoseGen(type: AllObjProps["type"]): string {
	const typeWhose = typeToTypeWhose[type];
	if (!typeWhose) throw new Error(`Invalid type ${type}!`);
	return typeWhose;
}

function typeActionGen(fOpts: SearchFilterOpts) {
	if (fOpts.type) {
		if (fOpts.action === ObjAction.SELL) {
			return `/${transTypeGen(fOpts.type)}`;
		} else if (fOpts.action === ObjAction.RENT) {
			return `/${typeToTypeWhoseGen(fOpts.type)}-${rentUrl}`;
		} else {
			return `/${typeToTypeWhoseGen(fOpts.type)}-${sellRentUrl}`;
		}
	} else {
		if (fOpts.action === ObjAction.SELL) {
			return `/${sellUrl}`;
		} else if (fOpts.action === ObjAction.RENT) {
			return `/${rentUrl}`;
		} else {
			return `/${sellRentUrl}`;
		}
	}
}

function areaGen(fOpts: SearchFilterOpts) {
	if (fOpts.city) {
		return `/${urlUtil.cityGen(fOpts.city)}`;
	} else if (fOpts.municipality) {
		return `/${urlUtil.municipalityGen(fOpts.municipality)}`;
	} else {
		return "";
	}
}

function buildRestQueryParams(fOpts: SearchFilterOpts) {
	let fOptsC = { ...fOpts };

	_.each(fOptsC, (val, key) => {
		if (!val) return delete fOptsC[key];
		if (Array.isArray(val) && val.length === 0) return delete fOptsC[key];
	});

	if (Object.keys(fOptsC).length > 0) {
		const paramsString = qs.stringify(fOptsC);
		return `/?${paramsString}`;
	}
	return "";
}

/**
 *
 * @returns url "/type[-action]/city/?other_params..."
 */
export default function buildSearchUrl(fOpts: SearchFilterOpts) {
	let url = PageUrl.NT_SKELBIMAI;
	if (fOpts.type || fOpts.action) {
		url += typeActionGen(fOpts);
	}
	url += areaGen(fOpts);
	url += buildRestQueryParams(fOpts);
	return url;
}

import { servicesInfo } from "@shared/constants/re_services";
import React from "react";
import WebLink from "../../common_comps/WebLink";
import List from "./Parts/List";
import ServiceWithCustomSections from "./ServiceWithCustomSections";

const KaimoPletrosProjektai: React.FC<{}> = () => {
	return (
		<ServiceWithCustomSections
			title={servicesInfo.KAIMO_PLETROS.title}
			descr={
				"Dzūkijos valdos specialistai turi kvalifikacijos pažymėjimus žemėtvarkos planavimo dokumentams rengti, todėl yra pasirengę savo sukauptas žinias bei patirtį panaudoti jūsų žemėtvarkos projekto rengimui. "
			}
			sections={[
				{
					title: "Kas yra kaimo plėtros žemėtvarkos projektai?",
					content: (
						<>
							<p className="srvs__p">
								Pagal LR Žemės įstatymą, „žemėtvarkos projektas – specialiojo teritorijų planavimo
								dokumentas, kuriame nustatoma kaimo vietovių žemės naudojimo ir apsaugos koncepcija bei
								konkrečios tvarkymo priemonės.“
							</p>
							<p className="srvs__p">
								Kaimo plėtros žemėtvarkos projektai –tai vietovės lygmens specialiojo planavimo
								dokumentai, kuriuos reikia parengti siekiant suplanuoti žemės naudmenų sudėties
								pakeitimą, suplanuoti su žemės naudojimu susijusią veiklą bei suformuoti veiklos
								subjektų žemėvaldas. Kaimo plėtros žemėtvarkos projektai rengiami kaimo gyvenamųjų
								vietovių neurbanizuotose ir neurbanizuojamose teritorijose.
							</p>
							<p className="srvs__p">
								Dažniausiai kaimo plėtros žemėtvarkos projektas reikalingas norint statyti statinį žemės
								ūkio paskirties sklype, nekeičiant sklypo paskirties. Tai įmanoma , jei statoma ūkininko
								sodyba ant ne mažesnio kaip 0,5 ha žemės sklypo. Kaimo plėtros žemėtvarkos projektas
								tampa pagrindiniu dokumentu, kuriuo nustatomos statybos zonos: ūkininko sodybos statybos
								bei žemės ūkio veiklai vykdyti reikalingų pastatų ir inžinerinių statinių statybos
								zonos.
							</p>
						</>
					),
				},
				{
					title: "Situacijos, kai reikalingi kaimo plėtros žemėtvarkos projektai",
					content: (
						<>
							<p className="srvs__p">
								Kaimo plėtros žemėtvarkos projektai būtini planuojant ir atliekant įvairius pokyčius
								neurbanizuotose teritorijose.
							</p>
							<List
								elements={[
									<>
										<b>Kai planuojama įveisti mišką ne miško žemėje.</b> Tokiam planui reikalinga
										nustatyti žemės sklypus, kuriems reikia pakeisti pagrindinę žemės naudojimo
										paskirtį įveisus mišką.
									</>,
									<>
										<b>Kai planuojama pertvarkyti ūkio žemės valdą.</b> Projekte suformuojami
										panašias savybes turintys žemės ūkio naudmenų sklypai bei nustatomas jų
										rekomenduojamas naudojimas atsižvelgiant į ūkio veiklos kryptis.
									</>,
									<>
										<b>Kai planuojama statyti ūkininko sodybą.</b> Norint statyti ūkininko sodybą
										žemės ūkio paskirties žemėje, reikalinga suplanuoti sklypo teritoriją, nustatant
										ūkininko sodybos ribas ir ūkininko sodybos statybos zoną. Į šią zoną taip pat
										įeina ir žemės ūkio veiklai vykdyti reikalingi pastatai ir inžineriniai
										statiniai.
									</>,
									<>
										<b>Kai planuojama žemės ūkio ir kaimo plėtra.</b> Projekte suplanuojami ir
										išdėstomi ūkinės veiklos ir žemės naudojimo pokyčiai.
									</>,
								]}
							/>
						</>
					),
				},
				{
					title: "Kokie dokumentai reikalingi rengiant žemėtvarkos projektą?",
					content: (
						<>
							<p className="srvs__p">
								Pateiktus kaimo plėtros žemėtvarkos projektus tvirtina Nacionalinė žemės tarnyba prie
								Žemės ūkio ministerijos. Prieš tai juos nustatyta tvarka patikrina valstybinė
								žemėtvarkos planavimo dokumentų priežiūrą atliekanti institucija. O pačiam projektų
								rengimui yra reikalingi šie dokumentai:
							</p>
							<List
								elements={[
									"žemės sklypo geodezinio  plano kopija,",
									"nuosavybės dokumentai, ",
									"sklypo įregistravimo Nekilnojamojo turto registre kopija,",
									"ūkininko pažymėjimo kopija,",
									"pokyčių planavimo sąlygų sąvadas.",
								]}
							/>
							<p className="srvs__p">
								Priklausomai nuo konkrečių planavimo tikslų, planuojamos teritorijos geografinės
								padėties bei ypatybių, gali reikėti ir kitų papildomų dokumentų, tokių kaip topografinė
								nuotrauka, bendrasis planas ir t.t.
							</p>
						</>
					),
				},
				{
					title: "Kaimo plėtros žemėtvarkos projektų kainos",
					content: (
						<>
							<p className="srvs__p">
								Žemėtvarkos projekto kaina priklauso nuo to, kokiam tikslui reikalingas projektas. Kaip
								minėta, esama skirtingų tikslų (miško įveisimas, valdos paskirties keitimas, sodybos
								planavimas), tad nuo to, kam reikia, kokios apimties projekto reikia, kokių dokumentų
								trūksta, priklauso ir projekto kaina.
							</p>
							<p className="srvs__p">
								Tikslią žemėtvarkos projektų rengimo paslaugos kainą Dzūkijos Valdos konsultantai
								pateiks pokalbio metu. <WebLink url="/kontaktai">Susisiekime!</WebLink>
							</p>
						</>
					),
				},
			]}
		/>
	);
};

export default KaimoPletrosProjektai;

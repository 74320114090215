import React from "react";

interface P {
	elements: (string | JSX.Element)[];
}
const List: React.FC<P> = (p) => {
	return (
		<ul className="srvs__simple-list">
			{p.elements.map((text, i) => (
				<li key={i + text.toString().slice(0, 10)}>{text}</li>
			))}
		</ul>
	);
};

export default List;

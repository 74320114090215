import React from "react";

interface P {
	mod?: "horizontal";
	className?: string;
}
const Seperator: React.FC<P> = (p) => {
	const classes: string[] = [];
	if (p.mod === "horizontal") {
		classes.push("sep--hor");
	}
	if (p.className) {
		classes.push(p.className);
	}
	return (
		<div className={`sep ${classes.join(" ")}`}>
			<div className={`sep__inner`}>
				<div className="sep__circle" />
			</div>
		</div>
	);
};
export default Seperator;

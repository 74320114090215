import React from "react";
import Carousel, { Modal, ModalGateway } from "react-images";
import { observer } from "mobx-react-lite";

interface P {
	//isOpen: boolean;
	//photoIndex: number;
	modalInfo: {
		modalOpen: boolean;
		photoIndex: number;
	};
	images: { source: string }[];
}
const PhotosModal: React.FC<P> = observer((p) => {
	return (
		<ModalGateway>
			{p.modalInfo.modalOpen ? (
				<Modal onClose={() => (p.modalInfo.modalOpen = false)}>
					<Carousel currentIndex={p.modalInfo.photoIndex} views={p.images} />
				</Modal>
			) : null}
		</ModalGateway>
	);
});
export default PhotosModal;

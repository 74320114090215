import React from "react";
import { geodesy3 } from "@webImgs";

const Prices: React.FC<{}> = () => {
	return (
		<div className="prices side-padding">
			<div className="prices__head">
				<div className="prices__head-text">
					<h1 className="prices__head-text-h1">Geodezinių (kadastrinių) matavimų kaina</h1>
					<span className="prices__head-text-desc">
						Už nekilnojamojo turto objektų geodezinius (kadastrinius) matavimus, planų ir kadastrinių
						matavimų bylos parengimą vykdytojui sumoka matavimus užsakęs fizinis ar juridinis asmuo pagal
						užsakovo ir vykdytojo tarpusavio susitarimą. Kainą už kadastrinių matavimų darbus lemia daugelis
						faktorių. Žemiau pateikta nuo ko priklauso geodezinių (kadastrinių) matavimų kaina.
					</span>
				</div>
				<img
					className="prices__head-img"
					title={
						"Geodeziniai (kadastriniai) matavimai, Namo pridavimas, Topografinės nuotraukos, Teritorijų planavimas"
					}
					alt="Geodeziniai (kadastriniai) matavimai, Namo pridavimas, Topografinės nuotraukos, Teritorijų planavimas"
					src={geodesy3}
				/>
			</div>

			<div className="prices__content">
				<div className="prices__header-wrap">
					<i className="prices__icon icon-geodesy" />
					<div className="prices__contenct-wrap">
						<h2 className="prices__head-text-h2">Darbų atlikimo terminas</h2>
						<div className="prices__content-text">
							Užsakovas pageidauja darbus atlikti skubos tvarka ar tenkintų darbų atlikimas eilės tvarka.
						</div>
					</div>
				</div>

				<div className="prices__header-wrap">
					<i className="prices__icon icon-geodesy" />
					<div className="prices__contenct-wrap">
						<h2 className="prices__head-text-h2">Darbų apimtis</h2>
						<div className="prices__content-text">
							Žemės sklypo, pastato ar patalpų plotas; inžinierinio statinio plotas, inžinierinių tinklų
							ar susisiekimo komunikacijų ilgis.
						</div>
					</div>
				</div>

				<div className="prices__header-wrap">
					<i className="prices__icon icon-geodesy" />
					<div className="prices__contenct-wrap">
						<h2 className="prices__head-text-h2">Kadastrinių matavimų rūšis </h2>
						<div className="prices__content-text">
							Tai pirminiai kadastriniai matavimai ar kadastro duomenų pakitimų fiksavimas.
						</div>
					</div>
				</div>

				<div className="prices__header-wrap">
					<i className="prices__icon icon-geodesy" />
					<div className="prices__contenct-wrap">
						<h2 className="prices__head-text-h2">Pateikti pradiniai duomenys (kadastro duomenų paketas)</h2>
						<div className="prices__content-text">
							Kartografinę medžiagą ir kitus dokumentus, kurių reikia kadastriniams matavimams atlikti,
							pateiks vykdytojui užsakovas ar susirinks vykdytojas pagal jų tarpusavio susitarimą.
							Vykdytojas, sudaręs sutartį su užsakovu dėl nekilnojamojo daikto kadastro duomenų nustatymo,
							turi teisę iš kadastro tvarkytojo gauti pradinius nekilnojamojo daikto kadastro duomenis
							(duomenų paketą), kurių reikia darbui atlikti.
						</div>
					</div>
				</div>

				<div className="prices__header-wrap">
					<i className="prices__icon icon-geodesy" />
					<div className="prices__contenct-wrap">
						<h2 className="prices__head-text-h2">Nekilnojamojo daikto sudėtingumas</h2>
						<div className="prices__content-text">
							Pastatas stačiakampės konfigūracijos, be piliastrų, tipinio aukštų išplanavimo, paprastos
							konstrukcinės schemos ar pastatas sudarytas iš keleto stačiakampių figūrų, su piliastrais,
							sudėtingos laužytos ir (ar) kreivos konfigūracijos (brėžiniuose labai daug matmenų ir
							sutartinių ženklų).
						</div>
					</div>
				</div>

				<div className="prices__header-wrap">
					<i className="prices__icon icon-geodesy" />
					<div className="prices__contenct-wrap">
						<h2 className="prices__head-text-h2">Informacijos surinkimas iš įvairių institucijų</h2>
						<div className="prices__content-text">
							Žemėtvarkos, savivaldybių prieš atliekant žemės sklypų ribų matavimus.
						</div>
					</div>
				</div>

				<div className="prices__header-wrap">
					<i className="prices__icon icon-geodesy" />
					<div className="prices__contenct-wrap">
						<h2 className="prices__head-text-h2">Matomumas tarp geodezinių punktų</h2>
						<div className="prices__content-text">
							Matomumas tarp geodezinių punktų atliekant žemės sklypų ribų matavimus.
						</div>
					</div>
				</div>

				<div className="prices__header-wrap">
					<i className="prices__icon icon-geodesy" />
					<div className="prices__contenct-wrap">
						<h2 className="prices__head-text-h2">Atstumas iki geodezinių punktų</h2>
						<div className="prices__content-text">
							Atstumas iki geodezinių punktų atliekant žemės sklypų ribų matavimus.
						</div>
					</div>
				</div>

				<div className="prices__header-wrap">
					<i className="prices__icon icon-geodesy" />
					<div className="prices__contenct-wrap">
						<h2 className="prices__head-text-h2">Vietovės, kur yra nekilnojamasis daiktas</h2>
						<div className="prices__content-text">
							T.y. privažiavimo galimybės, vykdytojo sugaištas laikas vykstant į objektą; įmonės
							autotransporto panaudojimas vykti šiam darbuotojui į objektą (jeigu užsakovas neskiria savo
							automobilio).
						</div>
					</div>
				</div>

				<div className="prices__header-wrap">
					<i className="prices__icon icon-geodesy" />
					<div className="prices__contenct-wrap">
						<h2 className="prices__head-text-h2">Metų laikas, kada atliekami darbai</h2>
					</div>
				</div>
				<div className="prices__content-text"></div>
				<div className="prices__header-wrap">
					<i className="prices__icon icon-geodesy" />
					<div className="prices__contenct-wrap">
						<h2 className="prices__head-text-h2">Užsakovas pageidauja išankstinės bylos</h2>
						<div className="prices__content-text">
							T.y. vykdytojo parengtos, kadastro duomenų bylos patikros – atlikus išankstinę patikrą,
							klientas būtų garantuotas, jog pateiktų nekilnojamojo daikto ir daiktinių teisių į jį
							įregistravimui dokumentų kadastro duomenys (kadastro duomenų bylos) yra kokybiški.
						</div>
					</div>
				</div>

				<div className="prices__header-wrap">
					<i className="prices__icon icon-geodesy" />
					<div className="prices__contenct-wrap">
						<h2 className="prices__head-text-h2">Kitos aplinkybės</h2>
						<div className="prices__content-text">
							Užsakovas pageidauja, kad vykdytojas parengtą kadastro duomenų bylą suderintų su kitomis
							teisės aktų nustatytomis institucijomis. Šioje sutartyje nurodomas darbų dydis, atlikimo
							terminas ir kaina, aptariamos nekilnojamojo daikto kadastro duomenų bylos ar parengto žemės
							sklypo plano kadastro tvarkytojo išankstinės patikros atlikimo (neatlikimo) sąlygos ir
							pasekmės.
						</div>
					</div>
				</div>
			</div>
		</div>
	);
};

export default Prices;

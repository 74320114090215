import React from "react";

interface P {
	onClick: () => void;
	active?: boolean;
}
const HamburgerBtn: React.FC<P> = (p) => {
	return (
		<button
			onClick={p.onClick}
			className={`hamburger hamburger-pad hamburger--elastic ${p.active ? "is-active" : ""}`}
			type="button"
			aria-label="Menu"
			aria-controls="navigation"
			aria-expanded={p.active}
		>
			<span className="hamburger-box hamburger-color ">
				<span className="hamburger-inner"></span>
			</span>
		</button>
	);
};
export default HamburgerBtn;

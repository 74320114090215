import React from "react";
import { observer, useLocalStore } from "mobx-react-lite";
import useGStore from "../../logic/store";
import {
	CityFilter,
	StreetFilter,
	ObjectTypeFilter,
	ActionFilter,
	SpaceFilter,
	PriceFilter,
	VillageFilter,
	OutsideSpaceFilter,
} from "./Filters";
import OptionGroup from "./OptionGroup";
import Input from "../UI/Input";
import { SpecificFilters, ExtraFilters } from "./FilterGen";
import { ObjType } from "@shared/types";
import Button from "../UI/Button";

const SearchBox: React.FC<{}> = observer((_p) => {
	const GS = useGStore();
	const LS = useLocalStore(() => ({
		expandClick() {
			GS.filterOpts.isExpanded = !GS.filterOpts.isExpanded;
		},
		searchClick() {
			GS.filter();
		},
	}));

	return (
		<div className="srchbox">
			<div className="srchbox__wrap">
				<div className="srchbox__group-wrap srchbox__group-wrap--left">
					<ObjectTypeFilter />
					<ActionFilter />
					<CityFilter />
					{(GS.filterOpts.current.city || GS.filterOpts.current.municipality) && <VillageFilter />}
					{GS.filterOpts.current.city && <StreetFilter />}
				</div>
				<div className="srchbox__group-wrap srchbox__group-wrap--right">
					{GS.filterOpts.current.type === ObjType.LAND ? <OutsideSpaceFilter /> : <SpaceFilter />}
					<PriceFilter />
					<SpecificFilters action={GS.filterOpts.current.action} type={GS.filterOpts.current.type} />
				</div>
				{GS.filterOpts.isExpanded && (
					<div className="srchbox__extra-filters">
						<ExtraFilters action={GS.filterOpts.current.action} type={GS.filterOpts.current.type} />
					</div>
				)}
			</div>

			<div className="srchbox__search-row ">
				<OptionGroup labelFor="for-keywords" title="Raktažodžiai">
					<div className="srchbox__btns-wrap">
						<Input
							onKeyDown={(e) => {
								if (e.keyCode != 13) return;
								LS.searchClick();
							}}
							className="srchbox__keywords"
							iconClass="icon-keyboard"
							defaultValue={GS.filterOpts.current.keywords}
							onChange={(e) => GS.updateFilterOpts({ keywords: e.currentTarget.value })}
							id="for-keywords"
							type="text"
							placeholder="Sklypas, sodyba, gatvė, miestas.."
						/>
						<div className="srchbox__buttons">
							<Button
								className="srchbox__button srchbox__button1"
								onClick={LS.searchClick}
								iconClass="icon-search1"
							>
								Ieškoti
							</Button>
							<Button
								className="srchbox__button srchbox__button2"
								iconClass="icon-search2"
								onClick={LS.expandClick}
							>
								Detali paieška..
							</Button>
							{/* <Button
							onClick={() =>
								(GS.filterOpts.current = {
									action: ObjAction.SELL,
									type: ObjType.FLAT,
								})
							}
							className="srchbox__button srchbox__button3"
						>
							Išvalyti paiešką
						</Button> */}
						</div>
					</div>
				</OptionGroup>
			</div>
		</div>
	);
});

export default SearchBox;

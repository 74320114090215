import { servicesInfo } from "@shared/constants/re_services";
import React from "react";
import WebLink from "../../common_comps/WebLink";
import List from "./Parts/List";
import ServiceWithCustomSections from "./ServiceWithCustomSections";

const PozeminiaiTinklai: React.FC<{}> = () => {
	return (
		<ServiceWithCustomSections
			title={servicesInfo.ZEMES_SKLYPU_FORMAVIMO.title}
			descr={
				"Pagal LR įstatymus, nutiesus požeminius tinklus ir komunikacijas (iki jų užpylimo gruntu), yra būtina atlikti jų geodezines nuotraukas. „Dzūkijos valdų“ geodezininkai turi tinkamą kvalifikaciją ir patirties atlikti reikalingas kontrolines nuotraukas, tad jums nereikės niekuo rūpintis."
			}
			sections={[
				{
					title: "Kas yra geodezinės - kontrolinės nuotraukos?",
					content: (
						<>
							<p className="srvs__p">
								Kontrolinių geodezinių nuotraukų rengimas yra geodeziniai matavimai, kurių metu
								užfiksuojami požeminiai tinklai ir komunikacijos, nustatoma naujų objektų erdvinė
								padėtis valstybinėje koordinačių sistemoje (LKS -94). Šių geodezinių matavimų
								kontrolinės nuotraukos dažnai vadinamos išpildomosiomis nuotraukomis.
							</p>
							<p className="srvs__p">
								Geodeziniai matavimai yra būtini įrengus naujus inžinerinius tinklus numatytoje
								teritorijoje: elektros tinklams, dujotiekiui, vandentiekiui, nuotekų ir lietaus
								kanalizacijai, požeminiams ryšiams ir t.t.
							</p>
						</>
					),
				},
				{
					title: "Kada ir kodėl reikia atlikti geodezinius matavimus?",
					content: (
						<>
							<p className="srvs__p">
								Jei jūsų sklype atliekant statybos darbus ar dėl kitų priežasčių yra atvestos naujos
								komunikacijos ar privesti inžineriniai tinklai, privaloma atlikti geodezinius matavimus
								iki komunikacijų užpylimo gruntu. Taip pat kontrolinės nuotraukos reikalingos ir kai
								keičiami seni inžineriniai tinklai.
							</p>
							<p className="srvs__p">
								Požeminių tinklų ir komunikacijų geodezinių - kontrolinių nuotraukų prireikia:
							</p>
							<List
								elements={[
									"Norint naujai nutiesti ar rekonstruoti požeminius inžinerinius tinklus bei komunikacijas,",
									"Pateikiant NT objektą pridavimui",
								]}
							/>
							<p className="srvs__p">
								Pagrindinis šių matavimų tikslas, yra dokumentuoti ir susisteminti požeminių tinklų ir
								komunikacijų koordinates, kad būtų galima patikrinti ar faktiškai pastatyta požeminė
								komunikacija arba pastatas (statinys) atitinka suprojektuotus sprendinius projekte, t.y.
								ar nėra nukrypimo nuo techninio projekto.
							</p>
							<p className="srvs__p">
								Taip pat šių geodezinių nuotraukų prireikia norint vėliau toje pačioje teritorijoje
								vykdyti statybos darbus. Pagal turimus duomenis ir geodezinę nuotrauką yra pažymimi
								požeminiai inžineriniai tinklai, kad statybos metu būtų galima išvengti jų pažeidimų.
								Neturint šių duomenų lengva „nukasti“ kabelį tiekiantį elektrą visam kvartalui.
							</p>
							<p className="srvs__p">
								Geodezinių matavimų taip pat reikia ir inžinerinius tinklus prižiūrinčioms įmonėms, kad
								jos tiksliai žinotų jų aptarnaujamų tinklų koordinates.
							</p>
							<p className="srvs__p">Taigi, apibendrinant, geodezinės nuotraukos:</p>
							<List
								elements={[
									"Pagerina bei palengvina požeminių inžinerinių tinklų ir komunikacijų tiesimo kokybę,",
									"Sudaro sąlygas komunikacijų saugiam ir racionaliam naudojimui,",
									"Prisideda prie patikimų topografinių duomenų rinkimo teritorijų planams rengti,",
									"Sutrumpina ateities darbus, išvengiant pakartotinių ieškojimų kur pravestos komunikacijos.",
								]}
							/>
							<p className="srvs__p">
								Geodezinės – kontrolinės nuotraukos daromos tik vieną kartą ir jos neturi galiojimo
								laiko pabaigos. Kitą kartą geodezinius matavimus reikia atlikti tik jei yra atliekami
								esminiai statybos ar rekonstrukcijos darbai, kurie daro įtaką inžinerinių tinklų bei
								komunikacijų pozicijai.
							</p>
							<p className="srvs__p">
								Be geodezinių matavimų nevalia pradėti eksploatuoti inžinerinių tinklų ir komunikacijų.
							</p>
						</>
					),
				},
				{
					title: "Kokių dokumentų reikės atliekant kontrolines nuotraukas?",
					content: (
						<>
							<p className="srvs__p">
								Geodezininkams, atliekantiems požeminių tinklų ir komunikacijų geodezines - kontrolines
								nuotraukas bus reikalingi šie dokumentai:
							</p>
							<List
								elements={[
									"nekilnojamojo turto registro išrašo kopija,",
									"žemės sklypo plano kopija,",
									"skaitmeninė topografinė nuotrauka (jeigu buvo daryta),",
									"požeminių tinklų ir komunikacijų tiesimo ar rekonstrukcijos sklype projektas.",
								]}
							/>
						</>
					),
				},
				{
					title: "Požeminių tinklų ir komunikacijų geodezinių matavimų kainos",
					content: (
						<>
							<p className="srvs__p">
								Paslaugos kaina priklauso nuo įvairių niuansų, komunikacijos rūšies, jos ilgio,
								sudėtingumo ir t.t., todėl geriausia teirautis konkrečiu atveju.{" "}
								<WebLink url="/kontaktai">Susisiekime</WebLink> ir „Dzūkijos valdų“ konsultantai pateiks
								orientacinę paslaugos kainą pokalbio metu.
							</p>
						</>
					),
				},
			]}
		/>
	);
};

export default PozeminiaiTinklai;

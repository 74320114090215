import React from "react";
import Dropdown, { DropdownOptions } from "../../common_comps/UI/Dropdown";
import SearchObj from "./SearchObj";
import Paginator from "react-paginate";
import scrollToTarget from "@client/common/util/scrollToElement";
import $ from "jquery";
import { observer, useLocalStore } from "mobx-react-lite";
import useGStore from "../../logic/store";
import _ from "lodash";
import { Link } from "react-router-dom";
import urlGen from "@shared/util/urlUtil";
import { ObjType, AllObjPropsC, Order, SearchFilterOpts } from "@shared/types/real_estate";
import qs from "qs";
import Headline from "../../common_comps/Headline";
import SearchBox from "../../common_comps/SearchBox/SearchBox";

const OrderOpts: DropdownOptions<Order> = {
	BEST_MATCH: {
		title: "Geriausias atitikmuo",
		value: Order.BEST_MATCH,
	},
	CHEAPEST_FIRST: {
		title: "Pigiausi viršuje",
		value: Order.CHEAPEST_FIRST,
	},
	EXPENSIVE_FIRST: {
		title: "Brangiausi viršuje",
		value: Order.EXPENSIVE_FIRST,
	},
	CHEAPEST_BY_UNIT: {
		title: "Pigiausi pagal arą/m²",
		value: Order.CHEAPEST_BY_UNIT,
	},
	EXPENSIVE_BY_UNIT: {
		title: "Brangiausi pagal arą/m²",
		value: Order.EXPENSIVE_BY_UNIT,
	},
	NEWEST_FIRST: {
		title: "Naujausi viršuje",
		value: Order.NEWEST_FIRST,
	},
	OLDEST_FIRST: {
		title: "Seniausi viršuje",
		value: Order.OLDEST_FIRST,
	},
};

const Titles: Record<ObjType, string> = {
	FLAT: "Butai",
	GARAGE: "Garažai",
	HOUSE: "Namai",
	LAND: "Sklypai",
	LODGING: "Patalpos",
	HOMESTEAD: "Sodybos",
	GARDEN_HOUSE: "Sodai",
};

const Search: React.FC<{}> = observer((_p) => {
	const elPerPage = 10;
	const GS = useGStore();
	const LS = useLocalStore(() => ({
		activePage: 1,
		elPerPage: 10,
		pageClick(clickInfo) {
			const scrollEl = $("#obj-type");
			scrollToTarget(scrollEl);
			this.activePage = clickInfo.selected + 1;
		},
		get page() {
			return {
				count: Math.ceil(LS.objs.length / elPerPage),
				startEl: (this.activePage - 1) * elPerPage,
				endEl: this.activePage * elPerPage - 1,
			};
		},
		get objs() {
			let objs = GS.filterOpts.active ? GS.objs.filtered : GS.objsArr.all;
			const orderOpt = GS.filterOpts.orderOpt;
			if (!orderOpt) return objs;

			if (orderOpt === Order.CHEAPEST_FIRST) {
				objs = _.orderBy(objs, "price", "asc");
			} else if (orderOpt === Order.EXPENSIVE_FIRST) {
				objs = _.orderBy(objs, "price", "desc");
			} else if (orderOpt === Order.NEWEST_FIRST) {
				objs = _.orderBy(objs, "updateDate", "desc");
			} else if (orderOpt === Order.OLDEST_FIRST) {
				objs = _.orderBy(objs, "updateDate", "asc");
			} else if (orderOpt === Order.CHEAPEST_BY_UNIT) {
				objs = _.orderBy(
					objs,
					(o: AllObjPropsC) => {
						let space = (o.insideSpace || o.outsideSpace) as number;
						return o.price / space;
					},
					"asc"
				);
			} else if (orderOpt === Order.EXPENSIVE_BY_UNIT) {
				objs = _.orderBy(
					objs,
					(o: AllObjPropsC) => {
						let space = (o.insideSpace || o.outsideSpace) as number;
						return o.price / space;
					},
					"desc"
				);
			}
			console.log("got objs:");
			console.dir(objs);
			return objs;
		},
	}));

	React.useEffect(() => {
		LS.activePage = 1;
	}, [LS.objs]);

	React.useEffect(() => {
		const params = window.location.search.slice(1);
		const searchParams: Partial<SearchFilterOpts> = qs.parse(params);

		if (Object.keys(searchParams).length === 0) return;
		GS.filterOpts.current = searchParams;
		GS.filter(true);
	}, []);

	const activeFilter = GS.filterOpts.active;
	const title = activeFilter?.type ? Titles[activeFilter.type] : "Visas nekilnojamas turtas";
	const count = GS.filterOpts.active ? GS.objs.filtered.length : GS.objsArr.all.length;

	return (
		<div className="srchp side-padding">
			<Headline hType={"h1"} type={"left"} text="Nekilnojamo turto skelbimai" iconClass="icon-search" />
			<SearchBox />
			<Headline id={"obj-type"} hType={"h2"} type={"left"} iconClass="icon-house">
				{title}
				<span className="headline__tag">({count})</span>
			</Headline>
			<div className="srchp__els-wrap">
				<div className="srchp__filters-wrap">
					<Dropdown
						selectedOpts={GS.filterOpts.orderOpt}
						onClick={(val) => {
							GS.filterOpts.orderOpt = val;
						}}
						mod="sort"
						options={OrderOpts}
						iconClass="icon-sort-amount-down"
					/>
				</div>
				<div className="srchp__objs-wrap">
					{LS.objs.slice(LS.page.startEl, LS.page.endEl).map((obj) => (
						<Link key={obj.publicId} to={urlGen(obj)}>
							<SearchObj key={obj.publicId} obj={obj} />
						</Link>
					))}
				</div>
				<div className="srchp__pgn-wrap">
					<Paginator
						forcePage={LS.activePage - 1}
						previousLabel={"Praeitas"}
						nextLabel={"Kitas"}
						breakLabel={"..."}
						breakClassName={"break-me"}
						pageCount={LS.page.count}
						marginPagesDisplayed={2}
						pageRangeDisplayed={5}
						onPageChange={LS.pageClick}
						containerClassName={"pgn"}
						activeLinkClassName={"pgn__page--active"}
						pageLinkClassName={"pgn__page"}
						//pageClassName={"pgn__page"}
						previousLinkClassName={"pgn__page"}
						nextLinkClassName={"pgn__page"}
					/>
				</div>
			</div>
		</div>
	);
});
export default Search;

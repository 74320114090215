import React from "react";
import { ObjType, AllObjPropsC } from "@shared/types";
import _ from "lodash";
// import { getUploadedBefore } from "@client/common/util/calcAgoTime";
import { transObjAction, transObjType } from "@shared/util/translate";
import { enumsTranslate } from "@client/common/util/valueTranslate";
import perPriceString from "@client/common/util/perPriceString";

function iconsLabelsGen(obj: AllObjPropsC): Partial<Record<keyof AllObjPropsC, { label: string; icon: string }>> {
	switch (obj.type) {
		case ObjType.FLAT: {
			return {
				roomCount: {
					label: "Kambarių sk.",
					icon: "icon-bed1",
				},
				insideSpace: {
					label: "Bendras plotas m²",
					icon: "icon-chart-area1",
				},
				floor: {
					label: obj.toFloor ? "Aukštai" : "Aukštas",
					icon: "icon-building",
				},
			};
		}
		case ObjType.HOUSE: {
			return {
				insideSpace: {
					label: "Bendras plotas m²",
					icon: "icon-chart-area1",
				},
				outsideSpace: {
					label: "Sklypo plotas (a)",
					icon: "icon-border-style-alt1",
				},
				installationType: {
					label: "Įrengimas",
					icon: "icon-tools",
				},
			};
		}
		case ObjType.HOMESTEAD: {
			return {
				insideSpace: {
					label: "Bendras plotas, m²",
					icon: "icon-chart-area1",
				},
				outsideSpace: {
					label: "Sklypo plotas (a)",
					icon: "icon-border-style-alt1",
				},
				installationType: {
					label: "Įrengimas",
					icon: "icon-tools",
				},
			};
		}
		case ObjType.GARDEN_HOUSE: {
			return {
				insideSpace: {
					label: "Bendras plotas, m²",
					icon: "icon-chart-area1",
				},
				outsideSpace: {
					label: "Sklypo plotas (a)",
					icon: "icon-border-style-alt1",
				},
				installationType: {
					label: "Įrengimas",
					icon: "icon-tools",
				},
			};
		}
		case ObjType.LAND: {
			return {
				outsideSpace: {
					label: "Sklypo plotas (a)",
					icon: "icon-border-style-alt1",
				},
				landPurpose: {
					label: "Sklypo paskirtis",
					icon: "icon-tag",
				},
			};
		}
		case ObjType.GARAGE: {
			return {
				insideSpace: {
					label: "Įrengimas",
					icon: "icon-tools",
				},
			};
		}
		case ObjType.LODGING: {
			return {
				insideSpace: {
					label: "Bendras plotas, m²",
					icon: "icon-chart-area1",
				},
				buildingPurpose: {
					label: "Patalpų paskirtis",
					icon: "icon-tag",
				},
				floor: {
					label: obj.toFloor ? "Aukštai" : "Aukštas",
					icon: "icon-building1",
				},
			};
		}
		default: {
			throw new Error("Invalid object type");
		}
	}
}

interface P {
	obj: AllObjPropsC;
}
const SearchObj: React.FC<P> = (p) => {
	const obj = p.obj;
	const iconsLabels = iconsLabelsGen(obj);
	// const uploadedString = getUploadedBefore(obj.updateDate);
	const objImg = obj.imgUrls && obj.imgUrls.length > 0 ? obj.imgUrls[0] : "#";
	const sepPrice = obj.price.toLocaleString("fr-FR");
	const pricePerSt = perPriceString(obj);
	return (
		<div className="sobj">
			<div className="sobj__img-wrap">
				<img alt={obj.title} title={obj.title} className="sobj__img" src={objImg} />
				{/* <div className="obj__price-box">
					<span className="obj__price">{obj.price} €</span>
				</div> */}
			</div>
			<div className="sobj__info-wrap">
				<div className="sobj__labels-wrap">
					<div className="sobj__title">
						<div className="sobj__title-text">{obj.title}</div>
						<div className="sobj__btn-wrap">
							<div className="sobj__info-btn sobj__info-btn--black">{transObjType[obj.type]}</div>
							<div className="sobj__info-btn sobj__info-btn--green">{transObjAction[obj.action]}</div>
							{!obj.enabled && <div className="sobj__info-btn sobj__info-btn--grey">Paslėptas</div>}
						</div>
					</div>
					<div className="sobj__stats-wrap">
						{_.map(iconsLabels, (labelIcon, stat) => {
							const val = obj[stat];

							return (Array.isArray(val) && val.length > 0) || (!Array.isArray(val) && val) ? (
								<div key={labelIcon!.label + obj.street} className="sobj__stat">
									<i className={`sobj__stat-icon ${labelIcon!.icon}`} />
									{labelIcon!.label + ": "}
									{Array.isArray(val)
										? val.map((v) => enumsTranslate(stat, v)).join(", ")
										: enumsTranslate(stat, val)}
								</div>
							) : null;
						})}
					</div>
				</div>

				<div className="sobj__btn-wrap-bottom">
					{/* <div className="sobj__stat sobj__stat--date">
						<i className="sobj__stat-icon" />
						<div>{uploadedString}</div>
					</div> */}
					<div className="sobj__info-btn-price">
						Kaina: {sepPrice} €{pricePerSt && <span className="sobj__price-sm"> ({pricePerSt})</span>}
					</div>
					<button type="button" className="act_btn act_btn--b act_btn--square act_btn--black sobj__btn-more">
						Sužinoti daugiau
					</button>
				</div>
			</div>
		</div>
	);
};

export default SearchObj;

import React from "react";
import OptionGroup from "./OptionGroup";
import Input from "../UI/Input";
import Dropdown from "../UI/Dropdown";
import useGStore from "../../logic/store";
import {
	installationTypes,
	heatingTypes,
	houseTypes,
	reTypes,
	actionTypes,
	buildingPurposeTypes,
	garageTypes,
	landPurposeTypes,
	buildingTypes,
	landFeatureTypes,
	mapForDropdown,
	garageFeatures,
} from "./FilterOptions";
import _ from "lodash";
import { ObjAction } from "@shared/types";
import { observer } from "mobx-react-lite";
import isVillage from "@shared/util/isVillage";

const numMask = [/\d/, /\d/, /\d/, /\d/, /\d/, /\d/, /\d/];

export const RoomFilter: React.FC<{}> = observer(() => {
	const GS = useGStore();
	return (
		<OptionGroup labelFor="for-kambariai" title="Kambariai">
			<Input
				defaultValue={GS.filterOpts.current.fromRoom}
				id="for-kambariai"
				onChange={(e) => GS.updateFilterOpts({ fromRoom: +e.currentTarget.value })}
				iconClass={"icon-bed2"}
				type="text"
				mask={numMask}
				guide={false}
				placeholder="Nuo"
			/>
			<Input
				defaultValue={GS.filterOpts.current.toRoom}
				onChange={(e) => GS.updateFilterOpts({ toRoom: +e.currentTarget.value })}
				type="text"
				mask={numMask}
				guide={false}
				placeholder="Iki"
			/>
		</OptionGroup>
	);
});

export const SpaceFilter: React.FC<{}> = observer(() => {
	const GS = useGStore();
	return (
		<OptionGroup labelFor="for-inspace" title="Plotas, m²">
			<Input
				id="for-inspace"
				defaultValue={GS.filterOpts.current.fromInsideSpace}
				onChange={(e) => GS.updateFilterOpts({ fromInsideSpace: +e.currentTarget.value })}
				iconClass={"icon-width"}
				type="number"
				mask={numMask}
				guide={false}
				placeholder="Nuo"
			/>
			<Input
				defaultValue={GS.filterOpts.current.toInsideSpace}
				onChange={(e) => GS.updateFilterOpts({ toInsideSpace: +e.currentTarget.value })}
				type="number"
				mask={numMask}
				guide={false}
				placeholder="Iki"
			/>
		</OptionGroup>
	);
});

export const OutsideSpaceFilter: React.FC<{}> = observer(() => {
	const GS = useGStore();
	return (
		<OptionGroup labelFor="for-outspace" title="Sklypo plotas, a">
			<Input
				id="for-outspace"
				defaultValue={GS.filterOpts.current.fromOutsideSpace}
				onChange={(e) => GS.updateFilterOpts({ fromOutsideSpace: +e.currentTarget.value })}
				iconClass={"icon-width"}
				type="number"
				mask={numMask}
				guide={false}
				placeholder="Nuo"
			/>
			<Input
				defaultValue={GS.filterOpts.current.toOutsideSpace}
				onChange={(e) => GS.updateFilterOpts({ toOutsideSpace: +e.currentTarget.value })}
				type="number"
				mask={numMask}
				guide={false}
				placeholder="Iki"
			/>
		</OptionGroup>
	);
});

export const YearFilter: React.FC<{}> = observer(() => {
	const GS = useGStore();
	return (
		<OptionGroup title="Metai" labelFor="for-year">
			<Input
				id="for-year"
				defaultValue={GS.filterOpts.current.fromYear}
				onChange={(e) => GS.updateFilterOpts({ fromYear: +e.currentTarget.value })}
				iconClass={"icon-calendar-check"}
				type="number"
				mask={numMask}
				guide={false}
				placeholder="Nuo"
			/>
			<Input
				defaultValue={GS.filterOpts.current.toYear}
				onChange={(e) => GS.updateFilterOpts({ toYear: +e.currentTarget.value })}
				type="number"
				mask={numMask}
				guide={false}
				placeholder="Iki"
			/>
		</OptionGroup>
	);
});

export const PriceFilter: React.FC<{}> = observer(() => {
	const GS = useGStore();
	return (
		<OptionGroup title="Kaina, €" labelFor="for-price">
			<Input
				id="for-price"
				defaultValue={GS.filterOpts.current.fromPrice}
				onChange={(e) => GS.updateFilterOpts({ fromPrice: +e.currentTarget.value })}
				iconClass={"icon-euro-sign2"}
				type="number"
				mask={numMask}
				guide={false}
				placeholder="Nuo"
			/>
			<Input
				defaultValue={GS.filterOpts.current.toPrice}
				onChange={(e) => GS.updateFilterOpts({ toPrice: +e.currentTarget.value })}
				type="number"
				mask={numMask}
				guide={false}
				placeholder="Iki"
			/>
		</OptionGroup>
	);
});

export const PricePerMFilter: React.FC<{}> = observer(() => {
	const GS = useGStore();
	return (
		<OptionGroup labelFor="for-mprice" title="Kaina už m², €">
			<Input
				id="for-mprice"
				defaultValue={GS.filterOpts.current.fromMPrice}
				onChange={(e) => GS.updateFilterOpts({ fromMPrice: +e.currentTarget.value })}
				iconClass={"icon-euro-sign2"}
				type="number"
				mask={numMask}
				guide={false}
				placeholder="Nuo"
			/>
			<Input
				defaultValue={GS.filterOpts.current.toMPrice}
				onChange={(e) => GS.updateFilterOpts({ toMPrice: +e.currentTarget.value })}
				type="number"
				mask={numMask}
				guide={false}
				placeholder="Iki"
			/>
		</OptionGroup>
	);
});

export const PricePerAreFilter: React.FC<{}> = observer(() => {
	const GS = useGStore();
	return (
		<OptionGroup labelFor="for-areprice" title="Kaina už arą, €">
			<Input
				id="for-areprice"
				defaultValue={GS.filterOpts.current.fromPricePerAre}
				onChange={(e) => GS.updateFilterOpts({ fromPricePerAre: +e.currentTarget.value })}
				iconClass={"icon-width"}
				type="number"
				mask={numMask}
				guide={false}
				placeholder="Nuo"
			/>
			<Input
				defaultValue={GS.filterOpts.current.toPricePerAre}
				onChange={(e) => GS.updateFilterOpts({ toPricePerAre: +e.currentTarget.value })}
				type="number"
				mask={numMask}
				guide={false}
				placeholder="Iki"
			/>
		</OptionGroup>
	);
});

export const FloorFilter: React.FC<{}> = observer(() => {
	const GS = useGStore();
	return (
		<OptionGroup title="Aukštas" labelFor="for-floor">
			<Input
				id="for-floor"
				defaultValue={GS.filterOpts.current.fromFloor}
				onChange={(e) => GS.updateFilterOpts({ fromFloor: +e.currentTarget.value })}
				iconClass={"icon-building1"}
				type="number"
				mask={numMask}
				guide={false}
				placeholder="Nuo"
			/>
			<Input
				defaultValue={GS.filterOpts.current.toFloor}
				onChange={(e) => GS.updateFilterOpts({ toFloor: +e.currentTarget.value })}
				type="number"
				mask={numMask}
				guide={false}
				placeholder="Iki"
			/>
		</OptionGroup>
	);
});

export const LandFeaturesFilter: React.FC<{}> = observer(() => {
	const GS = useGStore();
	return (
		<OptionGroup title="Sklype yra">
			<Dropdown
				selectedOpts={GS.filterOpts.current.landFeatures}
				multi
				onClick={(val) => GS.updateFilterOpts({ landFeatures: [val] })}
				options={landFeatureTypes}
			/>
		</OptionGroup>
	);
});

export const InstallationFilter: React.FC<{}> = observer(() => {
	const GS = useGStore();
	return (
		<OptionGroup title="Įrengimas">
			<Dropdown
				iconClass="icon-tools"
				multi
				selectedOpts={GS.filterOpts.current.installationType}
				onClick={(val) => GS.updateFilterOpts({ installationType: [val] })}
				options={installationTypes}
			/>
		</OptionGroup>
	);
});

export const HeatingFilter: React.FC<{}> = observer(() => {
	const GS = useGStore();
	return (
		<OptionGroup title="Šildymas">
			<Dropdown
				iconClass="icon-fireplace1"
				selectedOpts={GS.filterOpts.current.heatingType}
				multi
				onClick={(val) => GS.updateFilterOpts({ heatingType: [val] })}
				options={heatingTypes}
			/>
		</OptionGroup>
	);
});

export const HouseTypeFilter: React.FC<{}> = observer(() => {
	const GS = useGStore();
	return (
		<OptionGroup title="Namo tipas">
			<Dropdown
				selectedOpts={GS.filterOpts.current.houseType}
				multi
				onClick={(val) => GS.updateFilterOpts({ houseType: [val] })}
				options={houseTypes}
			/>
		</OptionGroup>
	);
});

export const BuildingTypeFilter: React.FC<{}> = observer(() => {
	const GS = useGStore();
	return (
		<OptionGroup title="Pastato tipas">
			<Dropdown
				iconClass="icon-chimney"
				multi
				selectedOpts={GS.filterOpts.current.buildingType}
				onClick={(val) => GS.updateFilterOpts({ buildingType: [val] })}
				options={buildingTypes}
			/>
		</OptionGroup>
	);
});

export const ObjectTypeFilter: React.FC<{}> = observer(() => {
	const GS = useGStore();
	return (
		<OptionGroup title="Objektų tipas">
			<Dropdown
				emptyOptText="Nesvarbu"
				selectedOpts={GS.filterOpts.current.type}
				onClick={(val) =>
					GS.updateFilterOpts({
						type: val,
						landPurpose: undefined,
						buildingPurpose: undefined,
						buildingType: undefined,
						buildingFeatures: undefined,
						landFeatures: undefined,
					})
				}
				options={reTypes}
				iconClass="icon-house1"
			/>
		</OptionGroup>
	);
});
export const ActionFilter: React.FC<{}> = observer(() => {
	const GS = useGStore();
	return (
		<OptionGroup title="Veiksmas">
			<Dropdown
				emptyOptText="Nesvarbu"
				selectedOpts={GS.filterOpts.current.action}
				onClick={(val) => GS.updateFilterOpts({ action: val as ObjAction })}
				options={actionTypes}
				iconClass="icon-buysell"
			/>
		</OptionGroup>
	);
});
export const CityFilter: React.FC<{}> = observer(() => {
	const GS = useGStore();

	return (
		<OptionGroup title="Miestas/Savivaldybė">
			<Dropdown
				id="city"
				selectedOpts={GS.filterOpts.current.city || GS.filterOpts.current.municipality}
				emptyOptText={"Nesvarbu"}
				onClick={(val) => {
					if (!val) {
						GS.updateFilterOpts({ city: undefined, municipality: undefined });
					} else if (GS.citiesMunicips.allCities.includes(val)) {
						GS.updateFilterOpts({ city: val, municipality: undefined });
					} else if (GS.citiesMunicips.allMunicips.includes(val)) {
						GS.updateFilterOpts({ municipality: val, city: undefined });
					} else {
						console.error("Invalid Miestas/Savivaldybe: " + val);
					}
				}}
				iconClass="icon-loc_mark"
				options={GS.citiesMunicips.mappedAll}
			/>
		</OptionGroup>
	);
});
export const StreetFilter: React.FC<{}> = observer(() => {
	const GS = useGStore();
	const streets = _(GS.objsArr.all)
		.filter((o) => o.street && o.city === GS.filterOpts.current.city)
		.uniqBy("street")
		.map("street")
		.sortBy()
		.value();

	if (streets.length === 0) return null;
	const mappedStreets = mapForDropdown(streets);

	return (
		<OptionGroup title="Gatvė">
			<Dropdown
				iconClass="icon-road2"
				emptyOptText={"Nesvarbu"}
				selectedOpts={GS.filterOpts.current.street}
				onClick={(val) => {
					console.log("received on click: " + val);
					GS.updateFilterOpts({ street: val });
				}}
				options={mappedStreets}
			/>
		</OptionGroup>
	);
});

export const VillageFilter: React.FC<{}> = observer(() => {
	const GS = useGStore();

	const villages = _(GS.objsArr.all)
		.filter((o) => o.city && isVillage(o.city) && o.municipality === GS.filterOpts.current.municipality)
		.uniqBy("city")
		.map("city")
		.sortBy()
		.value();

	if (villages.length === 0) return null;
	const mappedVillages = mapForDropdown(villages);

	return (
		<OptionGroup title="Gyvenvietė">
			<Dropdown
				iconClass="icon-loc_mark"
				emptyOptText={"Nesvarbu"}
				selectedOpts={GS.filterOpts.current.city}
				onClick={(val) => {
					GS.updateFilterOpts({ city: val });
				}}
				options={mappedVillages}
			/>
		</OptionGroup>
	);
});

export const LodgingTypesFilter: React.FC<{}> = observer(() => {
	const GS = useGStore();

	return (
		<OptionGroup title="Patalpų paskirtis">
			<Dropdown
				iconClass="icon-tag"
				selectedOpts={GS.filterOpts.current.buildingPurpose}
				multi
				onClick={(val) => GS.updateFilterOpts({ buildingPurpose: [val] })}
				options={buildingPurposeTypes}
			/>
		</OptionGroup>
	);
});

export const LandPurposeFilter: React.FC<{}> = observer(() => {
	const GS = useGStore();
	return (
		<OptionGroup title="Sklypo paskirtis">
			<Dropdown
				iconClass="icon-tag"
				selectedOpts={GS.filterOpts.current.landPurpose}
				multi
				onClick={(val) => GS.updateFilterOpts({ landPurpose: [val] })}
				options={landPurposeTypes}
			/>
		</OptionGroup>
	);
});

export const GarageTypeFilter: React.FC<{}> = observer(() => {
	const GS = useGStore();
	return (
		<OptionGroup title="Garažo tipas">
			<Dropdown
				iconClass="icon-cog"
				selectedOpts={GS.filterOpts.current.garageType}
				multi
				onClick={(val) => GS.updateFilterOpts({ garageType: [val] })}
				options={garageTypes}
			/>
		</OptionGroup>
	);
});

export const GarageFeaturesFilter: React.FC<{}> = observer(() => {
	const GS = useGStore();
	return (
		<OptionGroup title="Garažo ypatybės">
			<Dropdown
				iconClass="icon-clipboard-check"
				selectedOpts={GS.filterOpts.current.garageFeatures}
				multi
				onClick={(val) => GS.updateFilterOpts({ garageFeatures: [val] })}
				options={garageFeatures}
			/>
		</OptionGroup>
	);
});

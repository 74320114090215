import React from "react";
import _ from "lodash";
import useGStore from "../../logic/store";
import genUrlFromObject from "@shared/util/urlUtil";
import { PageUrl, ObjType } from "@shared/types";
import { typeToTransType } from "@shared/util/objectType";
import { typeToTypeWhose } from "@shared/util/typeWhose";
import { servicesInfo } from "@shared/constants/re_services";

const SiteMap: React.FC<{}> = (_p) => {
	const GS = useGStore();

	return (
		<div className="sitemap">
			<ul>
				<li>
					<a href={PageUrl.HOME}>Pagrindinis</a>
				</li>
				<li>
					<a href={PageUrl.APIE_MUS}>Apie mus</a>
				</li>
				<li>
					<a href={PageUrl.KAINOS}>Kainos</a>
				</li>
				<li>
					<a href={PageUrl.KONTAKTAI}>Kontaktai</a>
				</li>
				<li>
					<a href={PageUrl.NT_PASLAUGOS}>
						Kadastriniai (Geodeziniai) matavimai, Inventorizacija, Topografija ir Kiti matavimai
					</a>
					<ul>
						{_.map(servicesInfo, (s) => (
							<li>
								<a href={"/" + s.url}>{s.title}</a>
							</li>
						))}
					</ul>
				</li>
				<li>
					<div>Visas Nekilnojamas Turtas</div>
					<ul>
						{GS.objsArr.all.map((o) => (
							<li key={o.publicId}>
								<a href={genUrlFromObject(o)}>{o.title}</a>
							</li>
						))}
					</ul>
				</li>
				<li>
					<a href={PageUrl.NT_SKELBIMAI}>Nekilnojamo Turto Skelbimai</a>
					<ul>
						<li>
							<a
								href={
									PageUrl.NT_SKELBIMAI +
									`/${typeToTransType[ObjType.FLAT]}/alytuje/?action=SELL&type=FLAT&city=Alytus`
								}
							>
								Butai Alytuje
							</a>
						</li>
						<li>
							<a
								href={
									PageUrl.NT_SKELBIMAI +
									`/${typeToTransType[ObjType.HOUSE]}/alytuje/?action=SELL&type=HOUSE&city=Alytus`
								}
							>
								Namai Alytuje
							</a>
						</li>
						<li>
							<a
								href={
									PageUrl.NT_SKELBIMAI +
									`/${typeToTransType[ObjType.LODGING]}/alytuje/?action=SELL&type=LODGING&city=Alytus`
								}
							>
								Patalpos Alytuje
							</a>
						</li>
						<li>
							<a
								href={
									PageUrl.NT_SKELBIMAI +
									`/${typeToTransType[ObjType.LAND]}/alytuje/?action=SELL&type=LAND&city=Alytus`
								}
							>
								Sklypai, Zemes Alytuje
							</a>
						</li>
						<li>
							<a
								href={
									PageUrl.NT_SKELBIMAI +
									`/${
										typeToTransType[ObjType.HOMESTEAD]
									}/alytuje/?action=SELL&type=HOMESTEAD&city=Alytus`
								}
							>
								Sodybos Alytuje
							</a>
						</li>
						<li>
							<a
								href={
									PageUrl.NT_SKELBIMAI +
									`/${
										typeToTransType[ObjType.GARDEN_HOUSE]
									}/alytuje?action=SELL&type=GARDEN_HOUSE&city=Alytus`
								}
							>
								Sodai Alytuje
							</a>
						</li>
						<li>
							<a
								href={
									PageUrl.NT_SKELBIMAI +
									`/${typeToTransType[ObjType.GARAGE]}/alytuje/?action=SELL&type=GARAGE&city=Alytus`
								}
							>
								Garazai Alytuje
							</a>
						</li>
						<li>
							<a
								href={
									PageUrl.NT_SKELBIMAI +
									`/${
										typeToTransType[ObjType.FLAT]
									}/alytaus-rajone/?action=SELL&type=GARAGE&city=Alytus`
								}
							>
								Butai Alytaus Rajone
							</a>
						</li>
						<li>
							<a
								href={
									PageUrl.NT_SKELBIMAI +
									`/${
										typeToTransType[ObjType.HOUSE]
									}/alytaus-rajone/?action=SELL&type=HOUSE&municipality=Alytaus%20r.`
								}
							>
								Namai Alytaus Rajone
							</a>
						</li>
						<li>
							<a
								href={
									PageUrl.NT_SKELBIMAI +
									`/${
										typeToTransType[ObjType.LODGING]
									}/alytaus-rajone/?action=SELL&type=LODGING&municipality=Alytaus%20r.`
								}
							>
								Patalpos Alytaus Rajone
							</a>
						</li>
						<li>
							<a
								href={
									PageUrl.NT_SKELBIMAI +
									`/${
										typeToTransType[ObjType.LAND]
									}/alytaus-rajone/?action=SELL&type=LAND&municipality=Alytaus%20r.`
								}
							>
								Sklypai, Zemes Alytaus Rajone
							</a>
						</li>
						<li>
							<a
								href={
									PageUrl.NT_SKELBIMAI +
									`/${
										typeToTransType[ObjType.HOMESTEAD]
									}/alytaus-rajone/?action=SELL&type=HOMESTEAD&municipality=Alytaus%20r.`
								}
							>
								Sodybos Alytaus Rajone
							</a>
						</li>
						<li>
							<a
								href={
									PageUrl.NT_SKELBIMAI +
									`/${
										typeToTransType[ObjType.GARDEN_HOUSE]
									}/alytaus-rajone/?action=SELL&type=GARDEN_HOUSE&municipality=Alytaus%20r.`
								}
							>
								Sodai Alytaus Rajone
							</a>
						</li>
						<li>
							<a
								href={
									PageUrl.NT_SKELBIMAI +
									`/${
										typeToTransType[ObjType.GARAGE]
									}/alytaus-rajone/?action=SELL&type=GARAGE&municipality=Alytaus%20r.`
								}
							>
								Garazai Alytaus Rajone
							</a>
						</li>
						<li>
							<a
								href={
									PageUrl.NT_SKELBIMAI +
									`/${typeToTypeWhose[ObjType.FLAT]}-nuoma/alytuje/?action=RENT&type=FLAT&city=Alytus`
								}
							>
								Butu Nuoma Alytuje
							</a>
						</li>
						<li>
							<a
								href={
									PageUrl.NT_SKELBIMAI +
									`/${
										typeToTypeWhose[ObjType.LODGING]
									}-nuoma/alytuje/?action=RENT&type=LODGING&city=Alytus`
								}
							>
								Patalpu Nuoma Alytuje
							</a>
						</li>
						<li>
							<a
								href={
									PageUrl.NT_SKELBIMAI +
									`/${
										typeToTypeWhose[ObjType.FLAT]
									}-nuoma/alytaus-rajone/?action=RENT&type=FLAT&municipality=Alytaus%20r.`
								}
							>
								Butu Nuoma Alytaus Rajone
							</a>
						</li>
						<li>
							<a
								href={
									PageUrl.NT_SKELBIMAI +
									`/${
										typeToTypeWhose[ObjType.LODGING]
									}-nuoma/alytaus-rajone/?action=RENT&type=LODGING&municipality=Alytaus%20r.`
								}
							>
								Patalpu Nuoma Alytaus Rajone
							</a>
						</li>
						<li>
							<a
								href={
									PageUrl.NT_SKELBIMAI +
									`/${
										typeToTypeWhose[ObjType.GARAGE]
									}-nuoma/alytaus-rajone/?action=RENT&type=GARAGE&municipality=Alytaus%20r.`
								}
							>
								Garazu Nuoma Alytaus Rajone
							</a>
						</li>
					</ul>
				</li>
			</ul>
		</div>
	);
};
export default SiteMap;

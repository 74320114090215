import { servicesInfo } from "@shared/constants/re_services";
import React from "react";
import WebLink from "../../common_comps/WebLink";
import List from "./Parts/List";
import ServiceWithCustomSections from "./ServiceWithCustomSections";

const KadastriniaiMatavimai: React.FC<{}> = () => {
	return (
		<ServiceWithCustomSections
			title={servicesInfo.GEODEZINIAI_MATAVIMAI.title}
			descr={
				"Aukštos kvalifikacijos specialistai, turintys Nacionalinės žemės tarnybos prie Žemės ūkio ministerijos išduotus matininko ir geodezininko pažymėjimus bei turintys ilgametės patirties dirbant su įvairiais objektais, pasirūpins Jūsų turimų ar būsimų žemės sklypų kadastriniais matavimais. "
			}
			sections={[
				{
					title: "Kas yra kadastriniai matavimai?",
					content: (
						<>
							<p className="srvs__p">
								Žemės sklypo kadastriniai matavimai, dar vadinami geodeziniais matavimais, yra tikslus
								žemės ploto nustatymas ir plano parengimas. Naudojantis šiuolaikiškomis ir išmaniomis
								technologijomis matininkas užfiksuoja žemės sklypo ribų posūkio taškus, sklype esančius
								statinius bei jų ribų koordinates LKS-94 valstybinėje koordinačių sistemoje ir kitus
								nekilnojamo turto matmenis bei kadastro duomenis.
							</p>
							<p className="srvs__p">
								Kadastro duomenų nustatymo metu parengti planai, užpildytos kadastro formos bei
								papildomų dokumentų rinkinys yra vadinamas kadastrinių matavimų byla. Gavus kadastrinių
								matavimų bylą nekilnojamajas turtas yra registruojamas Registrų centre. Tai yra antras
								svarbiausias dokumentas po turto nuosavybės dokumento.
							</p>
						</>
					),
				},
				{
					title: "Kas atliekama kadastrinių (geodezinių) matavimų metu?",
					content: (
						<>
							<p className="srvs__p">
								Kadastrinio matavimo procesas susideda iš kelių dalių: matavimo, fiksavimo ir
								kartografijos bei atliekamų žymėjimo darbų:
							</p>
							<p className="srvs__p">
								<List
									elements={[
										"Nustatomos žemės sklypo ribos ir koordinatės bei apskaičiuojami plotai.",
										"Pažymimi žemės naudojimo apribojimai, servitutai, žemės naudmenų kontūrai.",
										"Parengiamas žemės sklypo planas bei sudaroma objekto kadastrinių matavimų byla.",
										"Atliekami statinių kampų žymėjimo darbai.",
										"Parengiamas pastato pririšimo žemės sklype planas.",
									]}
								/>
							</p>
							<p className="srvs__p">
								Žemės sklypo ribos vietovėje paženklinamos dalyvaujant žemės sklypo savininkui (esamam
								arba būsimam) arba jo įgaliotam asmeniui, taip pat suinteresuotiems asmenims, t.y.
								gretimų sklypų savininkams arba jų įgaliotiems asmenims.
							</p>
							<p className="srvs__p">
								Dzūkijos Valdų specialistai geodezinius matavimus atlieka naudodami šiuolaikinius,
								modernius prietaisus, kurių tikslumą garantuoja GPS imtuvai bei lazeriniai tacheometrai.
							</p>
						</>
					),
				},
				{
					title: "Kada reikalingi sklypo kadastriniai matavimai?",
					content: (
						<>
							<p className="srvs__p">
								Kadastrinių (geodezinių) matavimų pagrindinis tikslas – padėti savininkams sužinoti
								tikslias žemės sklypo ribas bei plotą. Tai būtina, norint atlikti bet kokius veiksmus su
								savo nuosavybe:
							</p>
							<p className="srvs__p">
								<List
									elements={[
										"Pirkti ar parduoti žemės sklypą.",
										"Žemės sklype pastatyti pastatą bei jį priduoti.",
										"Keisti žemės sklypo paskirtį.",
										"Nustatyti ir riboženkliais paženklinti žemės sklypo ribas.",
										"Keičiantis sklypo savininkui.",
										"Padalijant ar sujungiant sklypą.",
									]}
								/>
							</p>
							<p className="srvs__p">
								Jei abejojate, nežinote ar jūsų situacijoje reikalingi kadastriniai matavimai,
								kreipkitės, patarsime, suteiksime konsultaciją. Mes esame tam, kad jums būtų lengviau!
							</p>
						</>
					),
				},
				{
					title: "Žemės sklypo kadastrinių matavimų kaina",
					content: (
						<>
							<p className="srvs__p">
								Darbų kaina priklauso nuo daugybės faktorių susijusių su dokumentų buvimų ar jų
								nebuvimu, nuo objekto dydžio ir užsakytų darbų apimties ir sudėtingumo.
							</p>
							<p className="srvs__p">
								Konkrečią kainą Dzūkijos Valdos konsultantai pateiks pokalbio metu.{" "}
								<WebLink url="/kontaktai">Susisiekime!</WebLink>
							</p>
						</>
					),
				},
			]}
		/>
	);
};

export default KadastriniaiMatavimai;

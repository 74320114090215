import { servicesInfo } from "@shared/constants/re_services";
import React from "react";
import WebLink from "../../common_comps/WebLink";
import List from "./Parts/List";
import ServiceWithCustomSections from "./ServiceWithCustomSections";

const NotarinisPlanas: React.FC<{}> = () => {
	return (
		<ServiceWithCustomSections
			title={servicesInfo.NOTARINIS_PLANAS.title}
			descr={
				"Naudojimosi tvarkos tarp bendraturčių nustatymas yra būtinas norint ateityje išvengti nesutarimų ar neaiškumų dėl turto valdymo. Šį darbą, naudojimosi tvarkos nustatymo planą, parengs Dzūkijos Valdos specialistai ir jums nereikės dėl nieko rūpintis. "
			}
			sections={[
				{
					title: "Kas yra notarinis planas?",
					content: (
						<>
							<p className="srvs__p">
								Notarinis planas arba naudojimosi žemės sklypu tvarkos nustatymo planas yra dokumentas
								su brėžiniu, kuriame nustatoma žemės sklypo naudojimo tvarka tarp bendraturčių, kai
								žemės sklype yra keli bendraturčiai ir jie nežino, kur kieno ribos arba žino ir yra
								sutarę ribas, bet nori tai oficialiai įforminti.
							</p>
							<p className="srvs__p">
								Norint tarp bendrasavininkių nustatyti tikslų naudojamojo žemės sklypo ar statinių kiekį
								ir vietą sklype yra sudaromas žemės sklypo ar statinio notariniu būdu tvirtinamas
								planas, kuriuo išsprendžiami paminėti klausimai.
							</p>
							<p className="srvs__p">
								Kitaip nei sklypo dalinimo atveju, žemės sklypo kadastrinis numeris išlieka tas pats, o
								naudojimosi plane yra nurodomos tik atskiros žemės sklypo naudojimosi dalys tarp
								bendrasavininkių. Toks planas privalo būti patvirtintas notaro ir pasirašytas visų
								bendrasavininkų.
							</p>
							<p className="srvs__p">
								Naudojimosi tvarkos nustatymo planai gali būti parengti tik kvalifikuotų geodezininkų.
								Paprastai planas parengiamas per 1-2 savaites, tuomet tvirtinamas notariškai.
							</p>
						</>
					),
				},
				{
					title: "Kada reikalingi naudojimosi žemės sklypu tvarkos nustatymo planai?",
					content: (
						<>
							<p className="srvs__p">
								Dažniausiai naudojimosi tvarkos planas sudaromas, kai norima sutarti su
								bendrasavininkais dėl sklypo naudojimo tvarkos. Tačiau situacijų gali būti pačių
								įvairiausių. Toks planas reikalingas:
							</p>
							<List
								elements={[
									"kai žemės sklypo dalis yra parduodama ir norima būsimam bendrasavininkui nurodyti naudojimosi ribas, ",
									"kai žemės sklypo bendrasavininkai nėra aptarę naudojimosi ribų ir nori jas nustatyti, ",
									"kai žemės sklypo bendrasavininkai nori patvirtinti notariškai sutartas naudojimo ribas.",
								]}
							/>
							<p className="srvs__p">
								Naudojimosi tvarkos planas leidžia pasiekti tikslų, kuriuos išsikelia savininkai ir
								kuriuos kelia prižiūrinčios institucijos:
							</p>
							<List
								elements={[
									"kiekvienas bendrasavininkas turi turėti priėjimą (privažiavimą) prie savo sklypo dalies,",
									"žemės plotas turi būti optimaliai ir racionaliai išnaudojamas, išsaugant ir gerinant gamtinę aplinką,",
									"planas turi padėti išvengti ginčų ir nesutarimų dėl ribų ateityje, pvz., paveldėjimo atveju.",
								]}
							/>
						</>
					),
				},
				{
					title: "Kokie dokumentai reikalingi notariniam planui parengti?",
					content: (
						<>
							<p className="srvs__p">Naudojimosi tvarkos planui parengti reikalingi šie dokumentai:</p>
							<List
								elements={[
									"žemės sklypo teisinė registracija (Nekilnojamojo turto registro išrašo-pažymėjimo kopija), ",
									"žemės sklypo plano kopija.",
								]}
							/>
						</>
					),
				},
				{
					title: "Kuo notarinis planas skiriasi nuo plano teismui?",
					content: (
						<>
							<p className="srvs__p">
								Naudojimosi žemės sklypu tvarkos nustatymo planai, dar kitaip notariniai planai arba
								planai teismui yra reikalingi siekiant nustatyti sklypų ribas, nurodyti sklypo dalis
								bendraturčiams, nustatyti servitutus ir pan.
							</p>
							<p className="srvs__p">
								Tiek notariniai planai, tiek planai teismui rengiami iš principo panašiai, tačiau
								kadangi teikiami skirtingoms institucijoms (notarui arba teismui), jie adresuojami ir
								vadinami skirtingai.
							</p>
							<p className="srvs__p">
								Žinoma, skiriasi ir pati situacija. Teismo keliu paprastai sprendžiami ginčai, kurių
								nepavyksta spręsti taikiu keliu. O į notarus kreipiamasi tik kai visos suinteresuotos
								šalys sutaria dėl sklypo naudojimosi tvarkos ir plano ir nori tik jį patvirtinti.
							</p>
						</>
					),
				},
				{
					title: "Notarinio plano kaina",
					content: (
						<>
							<p className="srvs__p">
								Kiekvienas situacija, kuomet reikalingas notarinis planas yra individuali, todėl nėra
								universalios formulės kaip vyksta procesas ir kokia notarinio plano kaina.{" "}
								<WebLink url="/kontaktai">Susisiekime</WebLink>
								ir „Dzūkijos valdų“ konsultantai pateiks visą jums reikalingą informaciją apie
								paslaugas.
							</p>
						</>
					),
				},
			]}
		/>
	);
};

export default NotarinisPlanas;
